export const getPhaseColor = (phaseCode) => {

    switch (phaseCode) {
        case('quotation'):
            return '#fff29c'
        case('reservation'):
            return '#fff29c'
        case('booking'):
            return '#fff29c'
        case('note'):
            return '#fff29c'
        case('completed'):
            return '#ff8d8d'
        case('draft'):
            return '#ff8d8d'
        case('cancelled'):
            return '#ff8d8d'
        case('note_pending'):
            return '#d2ff8f'
        case('registration'):
            return '#d2ff8f'
    }

}