import {SettingsOpeningTime} from "./SettingsOpeningTime";
import {addOpeningTime, submitOpeningTimes} from "../settingsPageSlice";
import {useDispatch, useSelector} from "react-redux";
import translate from "../../../../common/helpers/utils/translate";

export const SettingsOpeningTimes = () => {

    const dispatch = useDispatch()
    const {openingTimeSettings} = useSelector((state) => state.settingsPage)

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
                dispatch(submitOpeningTimes())
            }}
            className="c-info-blocks__row"
            style={{border: '1px solid #eee', padding: '1rem 2rem 2rem'}}
        >
            <div className="c-info-blocks__single">
                <header className="c-subject-header c-subject-header--closed" style={{
                    display: 'flex',
                    padding: 0,
                    border: 0,
                    background: '#fff',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <h2>{translate('openingTimes')}</h2>
                </header>
                {openingTimeSettings.openingTimes?.map((openingTime, i) => {
                    return <SettingsOpeningTime key={'opening-time-' + i} i={i} openingTime={openingTime}/>
                })}

                <footer style={{display: 'flex', alignItems: 'center'}}>
                    <button
                        style={{marginRight: '1rem'}}
                        type={'button'}
                        className="btn btn--secondary"
                        onClick={() => dispatch(addOpeningTime())}>
                        <span>+ {translate('add')}</span>
                    </button>

                    {openingTimeSettings.changed &&
                    <button
                        type={'submit'}
                        className="btn btn--primary"
                    >
                        <span>{translate('save')}</span>
                    </button>
                    }

                </footer>

            </div>
        </form>
    )

}

