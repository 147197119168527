import translate from "../../createSlice/common/helpers/utils/translate";

const DashboardDeliveryFilterOptions = () => {
    return [
        {
            type: 'all',
            label: translate('showAll')
        },
        {
            type: 'pickup',
            label: translate('showPickup')
        },
        {
            type: 'delivery',
            label: translate('showDelivery')
        }
    ]
}

export default DashboardDeliveryFilterOptions