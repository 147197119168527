import InsurancesAction from "./InsurancesAction";

export default class InsurancesReducer {

    static initialState = {
        data: false,
        selected: null,
        loading: false
    };

    static reducer(state = InsurancesReducer.initialState, action) {

        switch (action.type) {

            case InsurancesAction.REQUEST_LIST:
                return {
                    ...state,
                    loading: true
                };

            case InsurancesAction.REQUEST_LIST + '_FINISHED':

                return {
                    ...state,
                    loading: false,
                    ...action.payload,
                };

            case InsurancesAction.SET_INSURANCE:
                return {
                    ...state
                };

            case InsurancesAction.SET_INSURANCE + '_FINISHED':

                return {
                    ...state,
                    selected: action.payload,
                    ...action.payload,
                };

            default:
                return state;
        }


    }
}
