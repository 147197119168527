import moment from 'moment'

const CalculateDaysBetween = (startDate, endDate, rentalNotationCode) => {

    // Adding 1 day because 1 selected day counts as 0
    if (moment(endDate).diff(moment(startDate), "days") === 0) {
        return moment(endDate).diff(moment(startDate), "days") + 1
    } else {
        return moment(endDate).diff(moment(startDate), "days")
    }


}

export default CalculateDaysBetween
