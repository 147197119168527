
export const formatRentalContainerToRentalItems = (rentalContainer) => {

    const items = []

    rentalContainer?.rentalItems.forEach((orderLine) => {
        orderLine.items?.forEach((item) => {
            items.push(item)
        })
    })

    return items

}
