import React from "react";
import {connect} from "react-redux";
import RentalDispatcher from "../../../common/dispatchers/RentalDispatcher";
import RentalIssueItemsAction from "../../../stores/rental/issueItems/RentalIssueItemsAction";
import RentalTotalsAction from "../../../stores/rental/totals/RentalTotalsAction";
import FormatDate from "../../../common/helpers/FormatDate";
import TinyLoader from "../../../common/components/General/TinyLoader";
import ItemAction from "../../../stores/item/ItemAction";
import InsurancesAction from "../../../stores/insurance/list/InsurancesAction";
import translate from "../../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    ...state.rentalIssue,
    environment: state.environment
});

class RentalIssueScannedItems extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showUnlinkPopup: false,
            unlinkItem: null,
            isUnlinking: false,
            showServicePopup: false,
            serviceItem: null,
            isRemovingService: false,
        }
    }


    render() {

        let title = translate('scannedItems')

        if (this.props.title) {
            title = this.props.title
        }

        if (this.props.items.length) {

            const unlinkedItems = this.props.items.filter((item) => item && item.rental_status && item.rental_status.code !== 'service' && (item.rental === null || item.valid.status.code === 'locked'));
            const linkedItems = this.props.items.filter((item) => item && item.rental !== null && item.valid.status.code !== 'locked');
            const servicedItems = this.props.items.filter((item) => item && item.rental_status && item.rental_status.code === 'service');

            return (
                <div className="c-order-handling__scanned">


                    {!!unlinkedItems.length &&
                    <>
                        <header>
                            <h4>{title}</h4>
                        </header>

                        <div className={'c-info-blocks__single__body c-info-blocks__single__body--scroll'}>
                            <table className={'c-table-standard'}>
                                <tbody>
                                <tr>
                                    <th/>
                                    <th>{translate('productCodeNew')}</th>
                                    <th>{translate('productCodeOld')}</th>
                                    <th>{translate('category')}</th>
                                    <th/>
                                </tr>

                                {unlinkedItems.map((item) => {

                                    let className = ''
                                    let removalButton = true

                                    if (item.valid.status.code === 'locked') {
                                        className += 'is-locked'
                                        removalButton = false
                                    }

                                    return (
                                        <tr className={className} key={'rental-issue-item-' + item.id}
                                            style={{background: ScannedBackground(item.valid)}}>
                                            <td>{removalButton && <i className="icon-cross c-button-icon"
                                                                     onClick={() => this._removeItem(item)}/>}</td>
                                            <td>{item.product_code}</td>
                                            <td>{item.product_code_prev}</td>
                                            <td>{item.category.name}</td>
                                            <td><ScannedStatusIcon valid={item.valid}/></td>
                                        </tr>
                                    )

                                })}
                                </tbody>
                            </table>

                            {!this.props.items.length &&
                            <p className={'c-no-scanned-items'}>Nog geen items gekoppeld aan deze boeking, scan items om
                                compleet te
                                maken</p>
                            }

                        </div>

                    </>
                    }


                    {!!linkedItems.length &&
                    <>
                        <header style={{marginTop: '2rem'}}>
                            <h4>Reeds gekoppeld aan bestaande boeking</h4>
                        </header>
                        <div className={'c-info-blocks__single__body c-info-blocks__single__body--scroll'}>
                            <table className={'c-table-standard'}>
                                <tbody>
                                <tr>
                                    <th/>
                                    <th>Product code (nieuw)</th>
                                    <th>Product code (oud)</th>
                                    <th>Boekingsnummer</th>
                                    <th>Einddatum</th>
                                    <th>Status boeking</th>
                                    <th/>
                                    <th/>
                                </tr>

                                {linkedItems.map((item) => {

                                    let className = ''
                                    let removalButton = true

                                    if (item.valid.status.code === 'locked') {
                                        className += 'is-locked'
                                        removalButton = false
                                    }

                                    return (
                                        <tr className={className} style={{background: ScannedBackground(item.valid)}}>
                                            <td>{removalButton && <i className="icon-cross c-button-icon"
                                                                     onClick={() => this._removeItem(item)}/>}</td>
                                            <td>{item.product_code}</td>

                                            <td>{item.product_code_prev}</td>

                                            <td>{item.rental.id}</td>

                                            <td>{FormatDate(item.rental.date_rent_end)}</td>

                                            <td></td>

                                            <td>
                                                <button onClick={() => this._showUnlinkPopup(item)}>Toevoegen</button>
                                            </td>

                                            <td><ScannedStatusIcon valid={item.valid}/></td>

                                        </tr>
                                    )

                                })}
                                </tbody>
                            </table>

                        </div>
                    </>
                    }

                    {!!servicedItems.length &&
                    <>
                        <header style={{marginTop: '2rem'}}>
                            <h4>Artikelen die in onderhoud zijn</h4>
                        </header>
                        <div className={'c-info-blocks__single__body c-info-blocks__single__body--scroll'}>
                            <table className={'c-table-standard'}>
                                <tbody>
                                <tr>
                                    <th/>
                                    <th>{translate('productCodeNew')}</th>
                                    <th>{translate('productCodeNew')}</th>
                                    <th/>
                                    <th/>
                                </tr>

                                {servicedItems.map((item) => {

                                    let className = ''
                                    let removalButton = true

                                    if (item.valid.status.code === 'locked') {
                                        className += 'is-locked'
                                        removalButton = false
                                    }

                                    return (
                                        <tr className={className} style={{background: ScannedBackground(item.valid)}}>
                                            <td>{removalButton && <i className="icon-cross c-button-icon"
                                                                     onClick={() => this._removeItem(item)}/>}</td>
                                            <td>{item.product_code}</td>

                                            <td>{item.product_code_prev}</td>


                                            <td/>

                                            <td>
                                                <button onClick={() => this._showServicePopup(item)}>Uit service halen
                                                    en
                                                    toevoegen
                                                </button>
                                            </td>

                                            <td><ScannedStatusIcon valid={item.valid}/></td>

                                        </tr>
                                    )

                                })}
                                </tbody>
                            </table>

                        </div>
                    </>
                    }

                    {this.state.showUnlinkPopup &&
                    <WarningPopup
                        {...this.state}
                        hide={() => this._hideUnlinkPopup()}
                        title={'Fiets ontkoppelen'}
                        text={'met het ontkoppelen van dit verhuuritem kunnen mogelijk fouten ontstaan, controleer of dit kan. Wil je doorgaan?'}
                        submit={() => this._submitUnlink()}
                    />
                    }

                    {this.state.showServicePopup &&
                    <WarningPopup
                        {...this.state}
                        hide={() => this._hideServicePopup()}
                        title={'Fiets uit service halen'}
                        text={'met het ontkoppelen van dit verhuuritem kunnen mogelijk fouten ontstaan, controleer of dit kan. Wil je doorgaan?'}
                        submit={() => this._submitService()}/>
                    }

                    <h4 style={{textAlign: 'center', margin: '2rem 0'}}>{translate('scanGiveItems')}</h4>

                </div>


            )
        } else return <h4 style={{textAlign: 'center'}}>{translate('scanGiveItems')}</h4>
    }

    _removeItem = (item) => {
        this.props.dispatch(RentalIssueItemsAction.scanCode(item.product_code ? item.product_code : item.product_code_prev)).then(() => {
            this.props.dispatch(RentalIssueItemsAction.validateAndSetItem(true)).then(() => {
                this.props.dispatch(RentalIssueItemsAction.loadStatusFromItems())
                this.props.dispatch(RentalTotalsAction.requestTotals(!!this.props.create, !!this.props.create, !!this.props.create))
                this.props.dispatch(InsurancesAction.list())
            })
        })
    }

    _showUnlinkPopup = (item) => {
        this.setState({
            showUnlinkPopup: true,
            unlinkItem: item
        })
    }

    _hideUnlinkPopup = () => {
        this.setState({
            showUnlinkPopup: false,
            unlinkItem: null
        })
    }

    _submitUnlink = () => {

        const item = this.state.unlinkItem

        this.setState({
            isUnlinking: true
        })

        this.props.dispatch(RentalIssueItemsAction.unlinkItem(item.id, item.rental.uuid)).then(() => {
            this.props.dispatch(RentalIssueItemsAction.scanCode(item.product_code ? item.product_code : item.product_code_prev)).then(() => {
                this.props.dispatch(RentalIssueItemsAction.validateAndSetItem(true, false)).then(() => {
                    this.props.dispatch(RentalIssueItemsAction.loadStatusFromItems()).then(() => {

                        this.setState({
                            isUnlinking: false
                        })

                        this._hideUnlinkPopup()
                    })
                })
            })
        })
    }

    _showServicePopup = (item) => {
        this.setState({
            showServicePopup: true,
            serviceItem: item
        })
    }

    _hideServicePopup = () => {
        this.setState({
            showServicePopup: false,
            serviceItem: null
        })
    }

    _submitService = () => {

        const item = this.state.serviceItem

        this.setState({
            isRemovingService: true
        })

        this.props.dispatch(ItemAction.updateStatus(this.state.serviceItem.id, 'available')).then(() => {
            this.props.dispatch(RentalIssueItemsAction.scanCode(item.product_code ? item.product_code : item.product_code_prev)).then(() => {
                this.props.dispatch(RentalIssueItemsAction.validateAndSetItem(true, false)).then(() => {
                    this.props.dispatch(RentalIssueItemsAction.loadStatusFromItems()).then(() => {

                        this.setState({
                            isRemovingService: false
                        })

                        this._hideServicePopup()
                    })
                })
            })
        })
    }
}

const ScannedStatusIcon = (props) => {

    switch (props.valid.status.code) {
        case(400):
            return <i className="icon icon-info"/>
        case('locked'):
            return <i className="icon icon-lock"/>
        default:
            return <i className="icon icon-check"/>
    }
}

const ScannedBackground = (valid) => {

    switch (valid.status.code) {
        case(400):
            return '#ffe3af'
        case('locked'):
            return 'none'
        default:
            return '#e2ffe2'
    }
}

// TODO: Create component Unlink popup

const WarningPopup = (props) => {

    return (
        <div className="c-modal">
            <div className="c-modal__bg"/>
            <div className="c-modal__content c-modal__content--text">
                <button className="c-modal__close" onClick={props.hideUnlinkPopup}><i className="icon icon-cross"/>
                </button>
                <div className="c-modal__header"><h2>{props.title}</h2></div>

                <div className="c-modal__body"><p>{props.text}</p>
                </div>

                {(!props.isUnlinking && !props.isRemovingService) &&
                <div className="c-modal__action">


                    <button className="btn btn--small btn--outline" onClick={props.hide}><span>Terug</span>
                    </button>

                    <button className="btn btn--small btn--primary" onClick={props.submit}><span>Bevestigen</span>
                    </button>

                </div>
                }

                {(!!props.isUnlinking || !!props.isRemovingService) && <TinyLoader/>}

            </div>
        </div>
    )

}

export default connect(mapStateToProps, RentalDispatcher)(RentalIssueScannedItems);
