import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {apiPartnerItemUnlinkRental} from "../../../common/api/partner/item/apiPartnerItemUnlinkRental";
import {apiPartnerItemUpdateStatus} from "../../../common/api/partner/item/apiPartnerItemUpdateStatus";

export const submitStolen = createAsyncThunk(
  'stolenModal/submitStolen',
  async (_, {getState}) => {

    const {stolenModal} = getState();

    if (stolenModal.item) {

      if (stolenModal.item.rental) {
        await apiPartnerItemUnlinkRental(stolenModal.item)
      }

      await apiPartnerItemUpdateStatus(stolenModal.item, 'stolen')

      return true

    } else return false


  }
)

const initialState = {
  active: false,
  submitting: false,
  submitted: false,
}

export const stolenModalSlice = createSlice({
  name: 'stolenModal',
  initialState,
  reducers: {
    openStolenModal: (state, action) => {
      state.item = action.payload
      state.active = true
    },
    closeStolenModal: (state) => {
      state.active = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitStolen.pending, (state) => {
      state.submitting = true
      state.submitted = false
    })
    builder.addCase(submitStolen.fulfilled, (state) => {
      state.submitting = false
      state.submitted = true
      state.item = false
      state.active = false
    })
  },
})

export const {closeStolenModal, openStolenModal} = stolenModalSlice.actions

export const stolenModalReducer = stolenModalSlice.reducer