// Dependencies
import React from 'react';
import {connect} from "react-redux";

// Actions
import RentalDeliveryAction from "../../stores/rental/delivery/RentalDeliveryAction";
import RentalTotalsAction from "../../stores/rental/totals/RentalTotalsAction";

// Components
import TinyLoader from "../../common/components/General/TinyLoader";
import RentalTotals from "../../common/components/Rental/RentalTotals";
import RentalDelivery from "../../common/components/Rental/RentalAccommodation";
import RentalOptions from "../../common/components/Rental/RentalOptions";
import ToDetailButton from "../../common/components/General/ToDetailButton";

// Helpers
import ToTransactionCheck from "../../common/helpers/ToTransactionCheck";
import IsCurrentPartnerLocation from "../../common/helpers/IsCurrentPartnerLocation";

import RentalDispatcher from "../../common/dispatchers/RentalDispatcher";
import Scanner from "../../common/components/General/Scanner";
import translate from "../../createSlice/common/helpers/utils/translate";
import {PinValidationWrapper} from "../../common/wrappers/PinValidationWrapper";


const mapStateToProps = (state) => ({
    ...state.rentalDelivery,
    rentalDetail: state.rentalDetail.data,
    environment: state.environment,
    rentalTotals: state.rentalTotals
});


class RentalMutationDelivery extends React.Component {

    componentDidMount() {
        this._init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.rentalUuid !== this.props.match.params.rentalUuid) {
            this._init()
        }
    }

    _init = () => {
        this.props.resetAll()
        let rentalUuid = this.props.match.params.rentalUuid
        this.props.loadMin(rentalUuid)
    }


    render() {

        if (this.props.rentalDetail) {


            const isCurrentPartnerLocation = IsCurrentPartnerLocation(this.props)

            if (isCurrentPartnerLocation) {
                return (
                    <main>
                        <PinValidationWrapper>

                            <Scanner type={'rental'}/>

                            <div className="o-container o-container--primary">
                                <header className="c-subject-header c-subject-header--split">
                                    <h1>{translate('accommodatie')} & {translate('options')}</h1>
                                    <div className="c-subject-header__buttons">
                                        <ToDetailButton history={this.props.history} slug={this.props.environment.slug}
                                                        uuid={this.props.rentalDetail.data.rental.uuid}/>
                                    </div>
                                </header>
                                <div className="s-order-detail">
                                    <div className="c-info-blocks c-form">

                                        {!this.props.response &&
                                        <div className="c-info-blocks__row">
                                            <div className="c-info-blocks__text"><h2>
                                                <u>#{this.props.rentalDetail.data.rental.id}</u></h2>

                                                <div className="c-info-blocks__row">

                                                    <div className={'c-current-settings'}>

                                                        <h4>{translate('currentSettings')}:</h4>

                                                        <ul>

                                                            {this.props.rentalDetail.holidayAddress &&
                                                            <li>{translate('accommodation')}: {this.props.rentalDetail.holidayAddress}</li>
                                                            }

                                                            <li>{translate('delivery')}: {!!this.props.rentalDetail.data.rental.delivery &&
                                                            <span>{translate('yes')}</span>} {!this.props.rentalDetail.data.rental.delivery &&
                                                            <span>{translate('no')}</span>}</li>


                                                        </ul>

                                                    </div>

                                                    <RentalDelivery/>

                                                    {this._showOptions() && <RentalOptions/>}


                                                </div>


                                            </div>

                                            {this.props.rentalTotals.totals && this.props.rentalDetail.RentalItems &&
                                            <RentalTotals data={this.props.rentalTotals.totals}
                                                          overrideItems={this.props.rentalDetail.RentalItems}
                                                          hideInput={false}/>
                                            }
                                        </div>
                                        }

                                        {!this.props.response && this._showOptions() &&

                                        <>

                                            <div className="c-info-blocks__row">
                                                <div className="c-info-blocks__single">
                                                    <button className="btn btn--primary"
                                                            onClick={() => this._onSubmit()}>
                                                        <span>{translate('edit')}</span></button>
                                                </div>
                                            </div>

                                        </>
                                        }


                                    </div>
                                </div>
                            </div>
                        </PinValidationWrapper>
                    </main>
                )
            } else return null
        } else {
            return <TinyLoader/>
        }

    }

    _showOptions = () => {
        return this.props.delivery.completed
    }

    _onSubmit = () => {
        this.props.dispatch(RentalDeliveryAction.submitMutation()).then(() => {
            if (this.props.response) {
                this.props.dispatch(RentalTotalsAction.submitDeposit(this.props.rentalDetail.data.rental.uuid)).then(() => {
                    ToTransactionCheck(this.props)
                })
            }
        })
    }

}

export default connect(mapStateToProps, RentalDispatcher)(RentalMutationDelivery);
