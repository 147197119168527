import React from "react";
import {connect} from "react-redux";
import ItemAction from "../../../stores/item/ItemAction";
import translate from "../../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    ...state.item
});

class RentalIssueScannedItems extends React.Component {
    render() {
        return (
            <div className="c-order-handling__scanned">
                <header>
                    <h4>{translate('scannedItems')}</h4>
                </header>
                <div className={'c-info-blocks__single__body c-info-blocks__single__body--scroll'}>
                    <table className={'c-table-standard'}>
                        <tbody>
                        <tr>
                            <th/>
                            <th>{translate('productCodeNew')}</th>
                            <th>{translate('productCodeOld')}</th>
                            <th>{translate('category')}</th>
                            <th/>
                        </tr>

                        {!!this.props.scannedItems.length && this.props.scannedItems.map((item) => {

                            let className = ''
                            let removalButton = true

                            return (
                                <tr className={className} key={'item-' + item.id}>
                                    <td>{removalButton && <i className="icon-cross c-button-icon"
                                                             onClick={() => this._removeItem(item)}/>}</td>
                                    <td>{item.product_code}</td>
                                    <td>{item.product_code_prev}</td>
                                    <td>{item.category.name}</td>

                                </tr>
                            )
                        })

                        }
                        </tbody>
                    </table>


                    {!this.props.scannedItems.length &&
                    <p className={'c-no-scanned-items'}>{translate('noItemsScanned')}</p>
                    }

                    {!!this.props.scannedItems.length && !this.props.submitting &&
                    <button
                        onClick={() => this._submit()}
                        style={{marginTop: '2rem', float: 'right'}} className="btn btn--orange"><span>{translate('processScannedItems')}</span>
                    </button>
                    }

                </div>
            </div>
        )
    }

    _removeItem = (item) => {
        this.props.dispatch(ItemAction.scanCode(item.product_code ? item.product_code : item.product_code_prev))
    }

    _submit = () => {
        this.props.dispatch(ItemAction.submit()).then(() => {
            this.props.dispatch(ItemAction.emptyScannedItems())
            this.props.dispatch(ItemAction.requestAvailableCategorylist())
        })
    }
}

export default connect(mapStateToProps)(RentalIssueScannedItems);
