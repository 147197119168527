import CustomerAction from "./CustomerAction";

export default class CustomerReducer {

    static initialState = {
        list: false,
        detail: false,
        isLoading: false
    };

    static reducer(state = CustomerReducer.initialState, action) {


        switch (action.type) {


            case CustomerAction.REQUEST_LIST:
                return {
                    ...state,
                    list: false,
                    isLoading: true
                };

            case CustomerAction.REQUEST_LIST + '_FINISHED':

                return {
                    ...state,
                    list: action.payload,
                    detail: false,
                    isLoading: false
                };

            case CustomerAction.REQUEST_DETAIL:
                return {
                    ...state,
                    detail: false,
                    isLoading: true
                };

            case CustomerAction.REQUEST_DETAIL + '_FINISHED':

                return {
                    ...state,
                    detail: action.payload,
                    list: false,
                    isLoading: false

                };

            default:
                return state;
        }



    }
}