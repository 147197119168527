import ActionUtility from "../../common/helpers/ActionUtility";
import EnvironmentEffect from "./EnvironmentEffect";
import ApiEnum from "../../common/constants/ApiEnum";

export default class EnvironmentAction {

    static DETAIL = 'EnvironmentAction.DETAIL';
    static SET_LOADER = 'EnvironmentAction.SET_LOADER';
    static ENABLE_RENTAL_SCANNER = 'EnvironmentAction.ENABLE_RENTAL_SCANNER';

    static detail(slug) {

        return async (dispatch, getState) => {

            localStorage.setItem('slug', slug);

            const values = {
                fetchUrl: ApiEnum.Api + '/environment/detail-by-url?url=' + window.location.origin + '/' + slug,
                method: 'get',
                slug: slug,
                accessToken: getState().authentication.accessToken
            }


            await ActionUtility.createThunkEffect(dispatch, EnvironmentAction.DETAIL, EnvironmentEffect.detail, values);
        }

    }

    static enableRentalScanner(bool) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, EnvironmentAction.ENABLE_RENTAL_SCANNER, EnvironmentEffect.enableRentalScanner, bool);
        }

    }

    static setLoader(bool) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, EnvironmentAction.SET_LOADER, EnvironmentEffect.setLoader, bool);
        }

    }

}
