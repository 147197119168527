import {apiPartnerItemLinkValid} from "../../api/partner/item/apiPartnerItemLinkValid";

export const checkItemNotifications = async (giveItems, rentalOrderLines) => {

    const itemNotifications = []

    for (const giveItem of giveItems) {

        // Check if category is inside rental, otherwise push notification
        const rentalOrderLine = rentalOrderLines?.filter((orderLine) => orderLine.type === 'category').find((orderLine) => orderLine.categoryId === giveItem.availabilityCategory.id)
        const code = giveItem.productCode ? giveItem.productCode : giveItem.productCodePrev

        if (!rentalOrderLine && rentalOrderLines.filter((orderLine) => orderLine.type === 'category').length) {
            // itemNotifications.push({
            //     text: code + ': Categorie niet in huidige boeking',
            //     type: 'notInBooking',
            //     id: giveItem.id
            // })
        }

    }

    return {
        itemNotifications: itemNotifications
    }

}
