import React from 'react'
import DisplayName from "../../helpers/DisplayName";
import IsCurrentPartnerLocation from "../../helpers/IsCurrentPartnerLocation";
import translate from "../../../createSlice/common/helpers/utils/translate";


class RentalCompany extends React.Component {

    render() {

        const {rentalDetail} = this.props
        const {holidayAddress, data} = rentalDetail
        const {customer_company, customer_addresses, customer_company_contacts} = data

        if (customer_company) {

            const {name, primary_phone, primary_email, address} = customer_company
            const isCurrentPartnerLocation = IsCurrentPartnerLocation(this.props)

            return (

                <div className="c-info-blocks__single">
                    <header className="c-subject-header c-subject-header--closed">
                        <h2>{translate('customerData')}</h2>
                    </header>
                    <div className="c-info-blocks__single__body">
                        {!!isCurrentPartnerLocation &&
                        <div className="c-info-blocks__single__body__row">
                            <span>{translate('customerType')}</span>
                            <span>{translate('company')}</span>
                        </div>
                        }
                        {!!name &&
                        <div className="c-info-blocks__single__body__row">
                            <span>{translate('name')}</span>
                            <span>{name}</span>
                        </div>
                        }
                        {!!primary_phone &&
                        <div className="c-info-blocks__single__body__row">
                            <span>{translate('phoneNumber')}</span>
                            <span>{primary_phone}</span>
                        </div>
                        }
                        {!!primary_email &&
                        <div className="c-info-blocks__single__body__row">
                            <span>{translate('emailAddress')}</span>
                            <span>{primary_email}</span>
                        </div>
                        }


                        {(!!address && isCurrentPartnerLocation) &&
                        <div className="c-info-blocks__single__body__row">
                            <span>{translate('address')}</span>
                            <span>{address}</span>
                        </div>
                        }


                        {(customer_company_contacts && isCurrentPartnerLocation) && customer_company_contacts.map((contactPerson) => {

                            return (
                                <div className="c-address-item" key={'contact-person-' + contactPerson.id}>

                                    <h3>{translate('contactPerson')}</h3>

                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('name')}</span>
                                        <span>{DisplayName(contactPerson)}</span>
                                    </div>
                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('emailAddress')}</span>
                                        <span>{contactPerson.email}</span>
                                    </div>
                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('phoneNumber')}</span>
                                        <span>{contactPerson.phone}</span>
                                    </div>
                                </div>
                            )

                        })}

                        {(customer_addresses && isCurrentPartnerLocation) && Object.values(customer_addresses).map((address) => {

                            return (
                                <div className="c-address-item" key={'address-' + address.id}>
                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('addressType')}</span>
                                        <span>{address.type.name}</span>
                                    </div>
                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('city')}</span>
                                        <span>{address.city}</span>
                                    </div>
                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('zipcode')}</span>
                                        <span>{address.zip_code}</span>
                                    </div>
                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('streetHouseNumber')}</span>
                                        <span>{address.street} {address.house_number}{address.house_number_addition}</span>
                                    </div>
                                </div>
                            )

                        })}


                        {(!!holidayAddress && isCurrentPartnerLocation) &&
                        <div className="c-info-blocks__single__body__row">
                            <span>{translate('holidayAddress')}</span>
                            <span>{holidayAddress}</span>
                        </div>
                        }


                    </div>
                </div>
            )
        } else return null
    }
}

export default RentalCompany;
