import {submitSold, closeSoldModal} from "./soldModalSlice";
import {useDispatch, useSelector} from "react-redux";

const SoldModalContainer = () => {

    const {item, active} = useSelector((state) => state.soldModal)
    const dispatch = useDispatch()

    if (item && active) {

        const content = formatContent(item)

        return (
            <div className="c-modal">
                <div className="c-modal__bg"/>
                <div className="c-modal__content c-modal__content--text">

                    <button className="c-modal__close" onClick={() => dispatch(closeSoldModal())}>
                        <i className="icon icon-cross"/>
                    </button>

                    <div className="c-modal__header">
                        <h2>{content.title}</h2>
                    </div>

                    <div className="c-modal__body">
                        <p>{content.text}</p>
                    </div>

                    <div className="c-modal__action">

                        <button className="btn btn--small btn--primary" onClick={() => dispatch(submitSold())}>
                            <span>{content.buttonText}</span>
                        </button>

                        <button
                            className="btn btn--small btn--outline"
                            onClick={() => dispatch(closeSoldModal())}
                        >
                            <span>Terug</span>
                        </button>

                    </div>
                </div>
            </div>
        )
    } else return null

}

const formatContent = (item) => {

    let title = 'Item ' + item.product_code + ' verkopen'
    let text = 'Weet u zeker dat u dit item wilt verkopen?';
    let buttonText = 'Bevestigen'

    return {
        title: title,
        text: text,
        buttonText: buttonText
    }

}

export default SoldModalContainer