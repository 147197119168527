import {apiPartnerAvailabilityPerCategory} from "../api/partner/availability/apiPartnerAvailabilityPerCategory";
import moment from "moment";
import {filterUnavailableItems} from "../helpers/filters/filterUnavailableItems";
import {checkItemNotifications} from "../helpers/utils/checkItemNotifications";
import {checkCategoryGiveExceptions} from "../helpers/utils/checkCategoryGiveExceptions";


export const actionValidateGiveItemAction = async (args) => {

    const {
        rentalGiveItems,
        rentalGiveItemNotifications,
    } = args

    if (rentalGiveItems.length) {

        return validate(args)

    } else return {

        // If there arent any items at all there is no need to do any validation. Just reset
        rentalItemNotifications: rentalGiveItemNotifications,
        rentalGiveItems: [],
        giveItemValidation: {
            completed: false,
            tooManyCategoriesGiven: false,
            notAllCategoriesGiven: false,
            processing: false
        },
    }

}


const validate = async (args) => {

    const {
        rentalGiveItems,
        rentalContainer,
        rentalTakeItems,
        rentalItems,
        categoryMutations,
        submitOption,
        categories,
    } = args

    const {
        filterItemNotifications,
        filteredItems,
        removedItems,
    } = filterUnavailableItems(categories, rentalGiveItems, rentalItems, rentalContainer?.rentalItems, rentalTakeItems)

    const {itemNotifications} = await checkItemNotifications(filteredItems, rentalContainer?.rentalItems)

    // Get exceptions
    const exceptions = checkCategoryGiveExceptions({
        currentRentalItems: rentalItems,
        giveItems: rentalGiveItems,
        takeItems: rentalTakeItems,
        categoryMutations: categoryMutations,
        rentalContainer: rentalContainer
    })

    const completed = !itemNotifications.find((notification) => notification.type === 'error')  // Only completed if there aren't any errors
        && !!filteredItems.length // Only completed if there are items
        && ((!exceptions.notAllCategoriesGiven && !exceptions.tooManyCategoriesGiven) || submitOption !== 'default') // If there aren't any exceptions or the submit option isn't default

    return {
        rentalItemNotifications: [...itemNotifications, ...filterItemNotifications],
        rentalGiveItems: filteredItems,
        removedItems: removedItems,
        giveItemValidation: {
            completed: completed,
            tooManyCategoriesGiven: exceptions.tooManyCategoriesGiven,
            notAllCategoriesGiven: exceptions.notAllCategoriesGiven,
            processing: false
        }

    }
}
