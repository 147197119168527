import React from 'react'
import {connect} from "react-redux";
import translate from "../../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    ...state.error
});

class ErrorDisplay extends React.Component {

render() {

        let className = 'c-error-popup';

        if(this.props.displayError){
            className += ' is-active'
        }

        return (
            <article className={className}>

                <header>{translate('errorText')}</header>

                <p>{this.props.displayError}</p>

            </article>
        )

    }

}

export default connect(mapStateToProps)(ErrorDisplay);