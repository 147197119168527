export const filterUnavailableItems = (categories, giveItems, rentalItems, orderLines, takeItems) => {


    const itemNotifications = []

    if (takeItems) {

        // Add the taken items to the availability count
        takeItems.forEach((takeItem) => {

            categories = categories.map((category) => {
                if (category.id === takeItem?.availabilityCategory.id) {
                    return {
                        ...category,
                        available: category.available + 1
                    }
                } else return {
                    ...category
                }
            })
        })
    }

    if (orderLines) {

        // Add the orderLine items to the availability count
        orderLines.filter((orderLine) => orderLine.type === 'category').forEach((orderLine) => {

            // get amount of items in the rental so it can go minus the order line quantity since its already in the rental
            const rentalItemLength = rentalItems.filter((rentalItem) => rentalItem.availabilityCategory.id === orderLine.categoryId).length

            categories = categories.map((category) => {
                if (category.id === orderLine.categoryId) {

                    let availablePlaceholder = category.available

                    // Reserved items shouldn't have an availability lower than 0. Needs to be forced
                    if (category.available < 0) {
                        availablePlaceholder = 0
                    }

                    return {
                        ...category,
                        available: availablePlaceholder + (orderLine.quantity - rentalItemLength)
                    }
                } else return {
                    ...category
                }
            })
        })

    }

    const removedItems = []

    const filteredItems = giveItems.filter((giveItem) => {

        const itemCategory = categories.find((category) => category.id === giveItem.availabilityCategory.id)

        categories = categories.map((category) => {
            if (category.id === itemCategory?.id) {
                return {
                    ...category,
                    available: category.available - 1
                }

            } else return {
                ...category
            }

        })


        if ((itemCategory?.available || itemCategory?.available === 0) && itemCategory.available <= 0) {
            // If there is no availability

            // Push notification
            const code = giveItem.productCode ? giveItem.productCode : giveItem.productCodePrev

            itemNotifications.push({
                id: giveItem.id,
                type: 'unavailable',
                text: code + ': Geen beschikbaarheid voor dit item'
            })

            removedItems.push(giveItem)

            return false

        } else return true

    })

    return {
        filteredItems: filteredItems,
        removedItems: removedItems,
        filterItemNotifications: itemNotifications
    }

}
