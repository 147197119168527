import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {apiPartnerEventCheckin} from "../../../common/api/partner/event/apiPartnerEventCheckin";
import {formatMobianCheckinResponseToCode} from "../../../common/helpers/format/formatMobianCheckinResponseToCode";
import {apiPartnerEventAvailability} from "../../../common/api/partner/event/apiPartnerEventAvailability";

const initialState = {
    display: 'default',
    scanning: false,
    loading: false,
    code: ''
}

export const eventScanPageSubmit = createAsyncThunk(
    'eventScanPage/submit',
    async (_, {getState, dispatch}) => {

        try {

            const {eventScanPage} = getState()

            const response = await apiPartnerEventCheckin({
                code: eventScanPage.code
            })

            // Update availability
            dispatch(getEventAvailability())

            return {
                rentalContainer: response.data.items?.rental,
                code: formatMobianCheckinResponseToCode(response.data)
            }

        } catch (e) {
            console.log(e)
        }
    }
)

export const getEventAvailability = createAsyncThunk(
    'eventScanPage/getEventAvailability',
    async (_) => {
        try {
            const response = await apiPartnerEventAvailability()
            return response.data.items
        } catch (e) {
            console.log(e)
        }
    }
)

const eventScanPageSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        reset: () => initialState,
        setDisplay: (state, action) => {
            state.display = action.payload
            state.code = ''
        },
        setCode: (state, action) => {
            state.code = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(eventScanPageSubmit.pending, (state) => {
            state.scanning = true
        })
        builder.addCase(eventScanPageSubmit.fulfilled, (state, action) => {
            state.scanning = false
            state.rentalContainer = action.payload?.rentalContainer
            state.resultCode = action.payload?.code
        })
        builder.addCase(getEventAvailability.fulfilled, (state, action) => {
            state.availability = action.payload
        })
    },
})

export const {setDisplay, setCode, reset} = eventScanPageSlice.actions
export const eventScanPageReducer = eventScanPageSlice.reducer
