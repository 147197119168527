import RentalModel from "../../../common/models/RentalModel";
import fetchHelper from "../../../common/helpers/fetchHelper";

export default class RentalDetailEffect {

    static async detail(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return {
                data: new RentalModel(response.items)
            }
        } else return {
            data: null
        }

    }


    static async requestMutations(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response.items.mutations
        } else return null

    }

    static async requestTransactions(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response.items.data
        } else return null

    }

    static async sendMail(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response
        } else return null

    }

    static setLoading(value) {
        return value;
    }

    static reset() {
        return true;
    }

}

