import RentalReturnItemsAction from "./RentalReturnItemsAction";

export default class RentalReturnItemsReducer {

    static initialState = {
        scannedItem: null,
        items: [],
        status: {
            correctCount: 0,
            errors: [],
            completed: false
        },
        remarks: {
            show: false,
            value: ''
        },
        popup: false,
        response: null
    };

    static reducer(state = RentalReturnItemsReducer.initialState, action) {

        switch (action.type) {

            case RentalReturnItemsAction.SCAN_CODE :

                return {
                    ...state,
                };

            case RentalReturnItemsAction.SCAN_CODE + '_FINISHED':

                // If is scanned and item is already in items
                if (action.payload.scanned && !!state.items.find((item) => item.id === action.payload.item.id)) {
                    return {
                        ...state,
                        scannedItem: null
                    }
                }

                return {
                    ...state,
                    scannedItem: action.payload.item
                };

            case RentalReturnItemsAction.VALIDATE_AND_SET_ITEM :

                return {
                    ...state,
                    scannedItem: null,
                };

            case RentalReturnItemsAction.VALIDATE_AND_SET_ITEM + '_FINISHED':

                if (action.payload) {

                    if (!state.items.some(item => item.id === action.payload.id)) {

                        return {
                            ...state,
                            scannedItem: null,
                            items: [
                                ...state.items,
                                action.payload
                            ]
                        };

                    } else {

                        const removalItem = state.items.find((item) => {
                            return item.id === action.payload.id;
                        })

                        if (removalItem.valid.status.code !== 'locked') {

                            state.items.slice(0, removalItem)

                            return {
                                ...state,
                                scannedItem: null,
                                items: state.items.filter(item => item.id !== action.payload.id)
                            };
                        } else {
                            return state
                        }


                    }
                } else return state

            case RentalReturnItemsAction.LOAD_STATUS_FROM_ITEMS + '_FINISHED':


                return {
                    ...state,
                    status: action.payload
                };


            case RentalReturnItemsAction.SUBMIT :

                return {
                    ...state,
                };

            case RentalReturnItemsAction.SUBMIT + '_FINISHED':
                return {
                    ...state,
                    response: action.payload
                };

            case RentalReturnItemsAction.LOAD_FROM_RENTAL :

                return {
                    ...state,
                };

            case RentalReturnItemsAction.LOAD_FROM_RENTAL + '_FINISHED':
                return {
                    ...state,
                    items: action.payload
                };

            case RentalReturnItemsAction.CHANGE_REMARKS + '_FINISHED':
                return {
                    ...state,
                    remarks: {
                        ...state.remarks,
                        value: action.payload
                    }
                };

            case RentalReturnItemsAction.TOGGLE_REMARKS + '_FINISHED':
                return {
                    ...state,
                    remarks: {
                        ...state.remarks,
                        show: action.payload.show,
                        value: action.payload.defaultRemarks
                    }
                };

            case RentalReturnItemsAction.TOGGLE_POPUP + '_FINISHED':
                return {
                    ...state,
                    popup: !state.popup
                };

            case RentalReturnItemsAction.RESET + '_FINISHED':
                return {
                    ...RentalReturnItemsReducer.initialState,
                };

            default:
                return state
        }

    }

}