import React from 'react'
import {connect, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import RouteEnum from "../../../common/constants/RouteEnum";
import translate from "../../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    ...state.rentalIssue,
    environment: state.environment

});

class RentalItems extends React.Component {

    render() {

        if (this.props.items && this.props.items.length) {


            return (
                <div className="c-info-blocks__row">
                    <div className="c-info-blocks__single">
                        <header className="c-subject-header c-subject-header--closed">
                            <h2>{translate('bookingArticles')}</h2>
                        </header>
                        <div className="c-info-blocks__single__body c-info-blocks__single__body--scroll">
                            <table className="c-table-standard">
                                <tbody>
                                <tr>
                                    <th>{translate('productCodeNew')}</th>
                                    <th>{translate('productCodeOld')}</th>
                                    <th>{translate('category')}</th>

                                </tr>
                                {this.props.items.map((item) => (
                                    <tr key={'rental-item-' + item.id}>
                                        <td><Link
                                            to={'/' + this.props.environment.slug + RouteEnum.PRODUCT_INFO + '/' + item.id}>{item.product_code}</Link>
                                        </td>
                                        <td><Link
                                            to={'/' + this.props.environment.slug + RouteEnum.PRODUCT_INFO + '/' + item.id}>{item.product_code_prev}</Link>
                                        </td>
                                        <td>{item.category.name}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )

        } else {
            return null
        }
    }


}

export default connect(mapStateToProps)(RentalItems);
