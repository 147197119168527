import CityAction from "./CityAction";

export default class CityReducer {

    static initialState = {
        list: []
    };

    static reducer(state = CityReducer.initialState, action) {
        switch (action.type) {

            case CityAction.REQUEST_CITY_LIST:
                return {
                    ...state,
                    list: []
                };
            case CityAction.REQUEST_CITY_LIST + '_FINISHED':

                return {
                    ...state,
                    list: action.payload
                };

            default:
                return state;
        }
    }
}