import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
    apiPartnerRentalTotals,
} from "../../../common/api/partner/rental/apiPartnerRentalTotals";
import {apiPartnerRentalRemoveDeposit} from "../../../common/api/partner/rental/apiPartnerRentalRemoveDeposit";
import {apiPartnerRentalAddDeposit} from "../../../common/api/partner/rental/apiPartnerRentalAddDeposit";
import {apiPartnerMutationCreateDiscount} from "../../../common/api/partner/mutation/apiPartnerMutationDiscount";


export const loadRentalTotalsModule = createAsyncThunk(
    'rentalTotals/load',
    async (_, {getState}) => {

        const {rentalTotalsModule} = getState();

        const values = {
            startDate: rentalTotalsModule.overrideData?.startDate,
            endDate: rentalTotalsModule.overrideData?.endDate,
            chosenUnit: rentalTotalsModule.overrideData?.timeUnitId,
            insuranceId: rentalTotalsModule.overrideData?.insuranceId,
            categories: rentalTotalsModule.overrideData?.categories
        }

        if (rentalTotalsModule.rentalUuid) {
            values.uuid = rentalTotalsModule.rentalUuid
        } else {
            // set discounts from current page. Since not loaded from page
            values.discountPercentage = rentalTotalsModule.discount.percentage
            values.discountPrice = rentalTotalsModule.discount.price
            values.deposit = rentalTotalsModule.deposit ? 1 : 0
        }
        const rentalTotalsResponse = await apiPartnerRentalTotals(values)


        return {
            rentalTotals: rentalTotalsResponse?.data?.items,
        }

    }
)

export const toggleDeposit = createAsyncThunk(
    'rentalTotals/toggleDeposit', async (_, {getState, dispatch}) => {

        const {rentalTotalsModule} = getState()

        if (rentalTotalsModule.rentalUuid) {

            if (rentalTotalsModule.deposit) {
                await apiPartnerRentalRemoveDeposit(rentalTotalsModule.rentalUuid)
            } else {
                await apiPartnerRentalAddDeposit(rentalTotalsModule.rentalUuid)
            }

        }

        // reload totals
        dispatch(loadRentalTotalsModule())

        return !rentalTotalsModule.deposit
    }
)

export const submitDiscountPercentage = createAsyncThunk(
    'rentalTotals/submitDiscountPercentage',
    async (_, {getState, dispatch}) => {
        const {rentalTotalsModule} = getState();

        if (rentalTotalsModule.rentalUuid) {
            await apiPartnerMutationCreateDiscount({
                rentalUuid: rentalTotalsModule.rentalUuid,
                discountPercentage: rentalTotalsModule.discount.percentage
            })
        }

        dispatch(loadRentalTotalsModule())
    }
)

export const submitDiscountPrice = createAsyncThunk(
    'rentalTotals/submitDiscountPrice',
    async (_, {getState, dispatch}) => {
        const {rentalTotalsModule} = getState();

        if (rentalTotalsModule.rentalUuid) {
            await apiPartnerMutationCreateDiscount({
                rentalUuid: rentalTotalsModule.rentalUuid,
                discountPrice: rentalTotalsModule.discount.price
            })
        }

        dispatch(loadRentalTotalsModule())
    }
)


const initialState = {
    loading: false,
    discount: {
        type: 'percentage',
        percentage: 0,
        price: 0,
    },
    deposit: false
}

export const rentalTotalsModuleSlice = createSlice({
    name: 'rentalTotals',
    initialState,
    reducers: {
        setDiscountType: (state, action) => {
            state.discount.type = action.payload
        },
        setDiscountPrice: (state, action) => {
            delete state.discount.percentage
            state.discount.price = action.payload
        },
        setDiscountPercentage: (state, action) => {
            delete state.discount.price
            state.discount.percentage = action.payload
        },
        setData: (state, action) => {
            state.rentalUuid = action.payload.rentalUuid
            state.overrideData = action.payload.overrideData
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadRentalTotalsModule.fulfilled, (state, action) => {
            const {rentalTotals} = action.payload
            state.rentalTotals = rentalTotals
            state.deposit = !!rentalTotals.depositEnabled

            if (!!rentalTotals.discountPercentage) {
                state.discount.percentage = rentalTotals.discountPercentage
                state.discount.type = 'percentage'
            } else {
                if (!!rentalTotals.discountPrice) {
                    state.discount.price = rentalTotals.discountPrice
                    state.discount.type = 'price'
                } else {
                    state.discount.type = 'percentage'
                }
            }
        })
        builder.addCase(toggleDeposit.fulfilled, (state, action) => {
            state.deposit = action.payload
        })
    }
})

export const {setDiscountPrice, setDiscountPercentage, setDiscountType, setData} = rentalTotalsModuleSlice.actions

export const rentalTotalsModuleReducer = rentalTotalsModuleSlice.reducer