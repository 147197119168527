import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {submitStolen, closeStolenModal} from "./stolenModalSlice";

const StolenModalContainer = () => {

  const {item, active} = useAppSelector((state) => state.stolenModal)
  const dispatch = useAppDispatch()

  if (item && active) {
    const content = formatContent(item)
    return (
      <div className="c-modal">
        <div className="c-modal__bg"/>
        <div className="c-modal__content c-modal__content--text">

          <button className="c-modal__close" onClick={() => dispatch(closeStolenModal())}>
            <i className="icon icon-cross"/>
          </button>

          <div className="c-modal__header">
            <h2>{content.title}</h2>
          </div>

          <div className="c-modal__body">
            <p>{content.text}</p>
          </div>

          <div className="c-modal__action">

            <button className="btn btn--small btn--primary" onClick={() => dispatch(submitStolen())}>
              <span>{content.buttonText}</span>
            </button>

            <button
              className="btn btn--small btn--outline"
              onClick={() => dispatch(closeStolenModal())}
            >
              <span>Terug</span>
            </button>

          </div>
        </div>
      </div>
    )
  } else return null

}

const formatContent = (item) => {

  let buttonText = 'Bevestigen'

  let title = 'Item ' + item.product_code + ' naar gestolen zetten'
  let text = 'Weet u zeker dat u dit item naar gestolen wilt zetten?';

  if (item.rental) {
    text = 'Dit item is gekoppeld aan een boeking. Weet u zeker dat u dit item naar status verkocht wilt zetten? Het item wordt dan losgekoppeld van de boeking.';
    buttonText = 'Ontkoppelen en bevestigen'
  }

  return {
    title: title,
    text: text,
    buttonText: buttonText
  }

}

export default StolenModalContainer