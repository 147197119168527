import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {submitUnlink, closeUnlinkItemModal} from "./unlinkItemModalSlice";
import translate from "../../../common/helpers/utils/translate";

const UnlinkItemModalContainer = () => {

    const {item, active} = useAppSelector((state) => state.unlinkItemModal)
    const dispatch = useAppDispatch()

    if (item && active) {
        const content = formatContent(item)
        return (
            <div className="c-modal">
                <div className="c-modal__bg"/>
                <div className="c-modal__content c-modal__content--text">

                    <button className="c-modal__close" onClick={() => dispatch(closeUnlinkItemModal())}>
                        <i className="icon icon-cross"/>
                    </button>

                    <div className="c-modal__header">
                        <h2>{content.title}</h2>
                    </div>

                    <div className="c-modal__body">
                        <p>{content.text}</p>
                    </div>

                    <div className="c-modal__action">

                        <button className="btn btn--small btn--primary" onClick={() => dispatch(submitUnlink())}>
                            <span>{content.buttonText}</span>
                        </button>

                        <button
                            className="btn btn--small btn--outline"
                            onClick={() => dispatch(closeUnlinkItemModal())}
                        >
                            <span>{translate('back')}</span>
                        </button>

                    </div>
                </div>
            </div>
        )
    } else return null

}

const formatContent = (item) => {

    let buttonText = translate('confirm')

    let title = translate('item') + ' ' + item.productCode + ' ' + translate('ontkoppelen')
    let text = translate('disconnectAlert');

    return {
        title: title,
        text: text,
        buttonText: buttonText
    }

}

export default UnlinkItemModalContainer