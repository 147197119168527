export const RentalItemNotifications = (props) => {

    const {notifications} = props

    if (!!notifications.length) {
        return (
            <div className="c-order-handling__notifications">
                {notifications.map((notification) => {
                    return (
                        <div
                            className="c-info-blocks__text__highlight c-info-blocks__text__highlight--orange c-info-blocks__text__highlight--icon">
                            <i className="icon icon-info"/>
                            <div><h4>{notification.text}</h4></div>
                        </div>
                    )
                })}

            </div>
        )
    } else return null
}