import React from "react";

const NotificationError = (props) => (
    <div className="c-notification c-notification--error">
        <i className="icon icon-info"/>
        <span>{props.text}</span>
    </div>
);


export default NotificationError;