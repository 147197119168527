const FormatDetailToCategoryRequest = (items, rentalData) => {

    let categories = []

    if (items.length) {


        items.forEach((item) => {

            const rentalItem = rentalData.rentalItems.find(rentalItem => rentalItem.category_id === item.availability_category.id);
            let mutationPrice = 0

            if (rentalItem) {
                mutationPrice = rentalItem.mutation_price
            }

            if (categories.some(category => category.category_id === item.availability_category.id)) {

                categories = categories.map(category => {

                    if (category.category_id === item.availability_category.id) {

                        return {
                            ...category,
                            mutation_price: mutationPrice,
                            quantity: category.quantity + 1
                        }
                    }
                })

            } else {
                categories.push({
                    category_id: item.availability_category.id,
                    mutation_price: mutationPrice,
                    quantity: 1
                })
            }

        })
    }

    return categories

}


export default FormatDetailToCategoryRequest