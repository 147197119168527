import RentalTotalsAction from "../totals/RentalTotalsAction";

export default class RentalTotalsReducer {

    static initialState = {
        totals: null,
        discountType: 'percentage',
        deposit: true,
        depositResponse: null
    };

    static reducer(state = RentalTotalsReducer.initialState, action) {

        switch (action.type) {

            case RentalTotalsAction.REQUEST_TOTALS:
                return {
                    ...state,
                };

            case RentalTotalsAction.REQUEST_TOTALS + '_FINISHED':
                return {
                    ...state,
                    totals: action.payload
                };

            case RentalTotalsAction.RESET + '_FINISHED':
                return {
                    ...RentalTotalsReducer.initialState,
                };

            case RentalTotalsAction.SET_DISCOUNT_PERCENTAGE:
                return {
                    ...state,
                };

            case RentalTotalsAction.SET_DISCOUNT_PERCENTAGE + '_FINISHED':

                return {
                    ...state,
                    discountPercentage: action.payload
                };

            case RentalTotalsAction.SET_DISCOUNT_PRICE:
                return {
                    ...state,
                };

            case RentalTotalsAction.SET_DISCOUNT_PRICE + '_FINISHED':

                return {
                    ...state,
                    discountPrice: action.payload
                };

            case RentalTotalsAction.SET_DISCOUNT_TYPE:
                return {
                    ...state,
                };

            case RentalTotalsAction.SET_DISCOUNT_TYPE + '_FINISHED':

                return {
                    ...state,
                    discountType: action.payload
                };

            case RentalTotalsAction.SET_DEPOSIT:
                return {
                    ...state,
                };

            case RentalTotalsAction.SET_DEPOSIT + '_FINISHED':

                return {
                    ...state,
                    deposit: action.payload
                };

            case RentalTotalsAction.SUBMIT_DEPOSIT:
                return {
                    ...state,
                };

            case RentalTotalsAction.SUBMIT_DEPOSIT + '_FINISHED':
                return {
                    ...state,
                    depositResponse: action.payload
                };

            case RentalTotalsAction.SUBMIT_DISCOUNT:
                return {
                    ...state,
                };

            case RentalTotalsAction.SUBMIT_DISCOUNT + '_FINISHED':
                return {
                    ...state,
                    discountResponse: action.payload
                };

            case RentalTotalsAction.LOAD_DISCOUNT_FROM_INFO:

                return {
                    ...state,
                };

            case RentalTotalsAction.LOAD_DISCOUNT_FROM_INFO + '_FINISHED':


                return {
                    ...state,
                    ...action.payload
                };

            case RentalTotalsAction.LOAD_DEPOSIT_FROM_INFO:
                return {
                    ...state,
                };

            case RentalTotalsAction.LOAD_DEPOSIT_FROM_INFO + '_FINISHED':
                return {
                    ...state,
                    deposit: action.payload
                };


            default:
                return state
        }

    }

}
