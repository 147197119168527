import {EventHeader} from "../../../common/components/EventHeader";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {EventWrapper} from "../../../common/wrappers/EventWrapper";
import {getEventAvailability, reset, setQuantity} from "./eventDemandPageSlice";
import React, {useEffect} from "react";
import {eventDemandPageSubmit} from "./eventDemandPageSlice";
import {EventDemandDefaultDisplay} from "./displays/EventDemandDefaultDisplay";
import {EventResult} from "../../../common/components/EventResult";
import {EventScanning} from "../../../common/components/EventScanning";

export const EventDemandPageContainer = () => {

    const {partnerLocation} = useAppSelector((state) => state)

    const {
        availability,
        quantity,
        scanning,
        resultCode,
        rentalContainer,
    } = useAppSelector((state) => state.eventDemandPage)
    const dispatch = useAppDispatch()


    useEffect(() => {
        dispatch(getEventAvailability())
    }, [resultCode])


    if (!!availability?.available) {
        return (
            <EventWrapper>

                <EventHeader
                    availability={availability}
                    partnerLocation={partnerLocation?.detail}
                />

                {(!resultCode) && <>
                    {!scanning && <EventDemandDefaultDisplay/>}
                    {scanning && <EventScanning text={'submitting'}/>}
                </>
                }

                {resultCode &&
                <>
                    <EventResult
                        resultCode={resultCode}
                        availability={availability}
                        rentalContainer={rentalContainer}
                    />

                    <a className="back-btn" onClick={() => dispatch(reset())}>
                        <h2><u>Back</u></h2>
                    </a>

                </>}


            </EventWrapper>
        )
    } else return null

}