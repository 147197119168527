import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {loadProductRentalsPage} from "./productRentalsPageSlice";
import {Link} from 'react-router-dom'
import RouteEnum from "../../common/constants/RouteEnum";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {formatCustomerString} from "../../common/helpers/formatCustomerString";
import {getPhaseColor} from "../../common/helpers/getPhaseColor";
import {useHistory} from "react-router";
import Scanner from "../../common/components/General/Scanner";
import translate from "../../createSlice/common/helpers/utils/translate";

export const ProductRentalsPageContainer = () => {

    const dispatch = useDispatch()
    const {item, rentals} = useSelector((root) => root.productRentalsPage)
    const serviceModal = useSelector((root) => root.serviceModal)
    const {slug} = useSelector((state) => state.environment)
    const history = useHistory()
    let {productId} = useParams();


    useEffect(() => {
        dispatch(loadProductRentalsPage(Number(productId)))
    }, [serviceModal])


    if (item) {
        return (
            <>

                <Scanner type={'rental'}/>

                <div className="o-container o-container--primary">
                    <header className="c-subject-header c-subject-header--split c-subject-header--actions">
                        <h1>{translate('scanTask')}: {item.product_code}</h1>
                        <div className="c-subject-header__buttons">
                            <Link to={'/' + slug + RouteEnum.PRODUCT_INFO + '/' + item.id}
                                  className="btn btn--small btn--outline"
                                  style={{background: 'rgb(2, 45, 107)'}}>
                                <span style={{color: 'white'}}>{translate('bicycleProductInformation')}</span>
                            </Link>
                        </div>
                    </header>
                </div>

                <div className="o-container o-container--primary">

                    <div className="c-scan-products-overview">
                        <div
                            className="c-info-blocks__single__body c-info-blocks__single__body--scroll list-loading-undefined">
                            <div className="c-table c-table-standard c-table--dashboard c-Rental-overview"
                                 style={{position: 'relative'}}>

                                <p>{translate('mostRecentRentalsForThisBike')}</p>

                                <table className="c-table-standard c-table-standard--prices">
                                    <tbody>
                                    <tr>
                                        <td>{translate('bookingsNumber')}</td>
                                        <td>{translate('van')}</td>
                                        <td>{translate('tot')}</td>
                                        <td>{translate('renter')}</td>
                                        <td>{translate('amount')}</td>
                                        <td>{translate('stay')}</td>
                                        <td>{translate('status')}</td>
                                    </tr>

                                    {!!rentals?.data?.length && Object.values(rentals?.data)
                                        .slice()
                                        .sort((a, b) => a.order > b.order ? 1 : -1)
                                        .map((rentalContainer) => {

                                            return (
                                                <tr onClick={() => history.push('/' + slug + '/dashboard/rental/detail/' + rentalContainer.rental.uuid)}>
                                                    <td>#{rentalContainer.rental.id}</td>
                                                    <td>{moment(rentalContainer.rental.date_rent_start).format('DD-MM-YYYY')}</td>
                                                    <td>{moment(rentalContainer.rental.date_rent_end).format('DD-MM-YYYY')}</td>
                                                    <td>{formatCustomerString(rentalContainer)}</td>
                                                    <td>{rentalContainer.rentalItems?.length}</td>
                                                    <td>{rentalContainer.delivery_address?.name}</td>
                                                    <td style={{
                                                        backgroundColor: getPhaseColor(rentalContainer.bookingPhase.code),
                                                        textAlign: 'center'
                                                    }}>{rentalContainer.bookingPhase.name}</td>
                                                </tr>
                                            )
                                        })
                                    }

                                    </tbody>
                                </table>

                                <div className="resize-triggers">
                                    <div className="expand-trigger">
                                        <div style={{width: '1199px', height: '1px'}}/>
                                    </div>
                                    <div className="contract-trigger"/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else return null
}

