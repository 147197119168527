
export const formatItemLabel = (item) => {

    let label = item.brandModelLabel

    if (item.productCode && item.productCodePrev) {
        label += ' [' + item.productCode + ' / ' + item.productCodePrev + ']';
    } else if (item.productCodePrev) {
        label += ' [' + item.productCodePrev + ']';
    } else if (item.productCode) {
        label += ' [' + item.productCode + ']';
    }

    return label

}