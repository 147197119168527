import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {apiPartnerItemUnlinkRental} from "../../../common/api/partner/item/apiPartnerItemUnlinkRental";
import {apiPartnerItemDetailById} from "../../../common/api/partner/item/apiPartnerItemDetailById";

export const submitUnlink = createAsyncThunk(
    'unlinkItemModal/submitUnlink',
    async (_, {getState}) => {

        const {unlinkItemModal} = getState();

        if (unlinkItemModal.item) {

            if (unlinkItemModal.item) {
                await apiPartnerItemUnlinkRental(unlinkItemModal.item)
                const itemResponse = await apiPartnerItemDetailById(unlinkItemModal.item.id)
                return itemResponse.data?.items?.item
            }

            return false

        } else return false


    }
)

const initialState = {
    active: false,
    submitting: false,
    submitted: false,
    item: undefined
}

export const unlinkItemModalSlice = createSlice({
    name: 'unlinkItemModal',
    initialState,
    reducers: {
        openUnlinkItemModal: (state, action) => {
            state.item = action.payload
            state.active = true
        },
        closeUnlinkItemModal: (state) => {
            state.active = false
        },
        resetUnlinkItemModal: (state) => {
            Object.assign(state, initialState)
        }
    },
    extraReducers: (builder) => {
        builder.addCase(submitUnlink.pending, (state) => {
            state.submitting = true
            state.submitted = false
        }) 
        builder.addCase(submitUnlink.fulfilled, (state, action) => {
            state.submitting = false
            state.submitted = true
            state.active = false
            state.item = action.payload
        })
    },
})

export const {closeUnlinkItemModal, openUnlinkItemModal, resetUnlinkItemModal} = unlinkItemModalSlice.actions

export const unlinkItemModalReducerCs = unlinkItemModalSlice.reducer