import React from 'react'
import {connect} from "react-redux";

const mapStateToProps = (state) => ({
    environment: state.environment.data
});

class Loader extends React.Component {


    render() {

        let className = 'c-loader'

        if (this.props.loading) {
            className += ' is-loading'
        }

        if (this.props.environment) {

            return (
                <div className={className}>

                    <style dangerouslySetInnerHTML={{
                        __html: `
                  .c-loader:before { background:#EEEEEE; }
                 `
                    }}/>

                    <style dangerouslySetInnerHTML={{
                        __html: `
                path { fill:` + this.props.environment.info.primary_color_dark + ` }
                 `
                    }}/>


                    <div className="box">
                        <img src={this.props.environment.logo} alt=""/>
                        <svg version="1.1" id="L9" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0">
                            <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                              <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform>
                            </path>
                        </svg>
                    </div>


                </div>
            )
        } else {
            return null
        }
    }

}

export default connect(mapStateToProps)(Loader);
