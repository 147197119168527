import ErrorAction from "./ErrorAction";

export default class ErrorReducer {

    static initialState = {
        displayError: null,
        forceLogout: false,
        errorLog: [],
    };

    static reducer(state = ErrorReducer.initialState, action) {


        switch (action.type) {

            case ErrorAction.SET_ERROR:

                return {
                    ...state,
                };

            case ErrorAction.CATCH_ERROR + '_FINISHED':

                return {
                    ...state,
                    ...action.payload
                };

            case ErrorAction.CATCH_ERROR:

                return {
                    ...state,
                };

            case ErrorAction.SET_ERROR + '_FINISHED':

                return {
                    ...state,
                    ...action.payload
                };


            case ErrorAction.UNSET_ERROR:

                return {
                    ...state,
                };

            case ErrorAction.UNSET_ERROR + '_FINISHED':

                return {
                    ...state,
                    forceLogout: false,
                    displayError: null
                };


            default:
                return state;
        }
    }
}