import fetchHelper from "../../common/helpers/fetchHelper";

export default class LocationEffect {

    static async requestDetail(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        return response ? response.items : null

    }
    static async submitOpeningTimes(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        return response ? response.items : null

    }

    static async requestList(values, dispatch) {
        const response =  await fetchHelper(values, dispatch)
        return response?.items?.locations
    }
}

