import {SvgInvalidCode} from "../svg/SvgInvalidCode";
import {SvgScanSuccess} from "../svg/SvgScanSuccess";
import {SvgScanFailed} from "../svg/SvgScanFailed";
import React from "react";
import moment from "moment";
import translate from "../helpers/utils/translate";

export const EventResult = (props) => {

    const {resultCode, rentalContainer, availability} = props

    switch (resultCode) {
        case('success'):
            return <Success availability={availability} rentalContainer={rentalContainer}/>
        case('invalidCode'):
            return <InvalidCode rentalContainer={rentalContainer}/>
        case('wrongLocation'):
            return <WrongSpace rentalContainer={rentalContainer}/>
        case('alreadyUsed'):
            return <AlreadyBooked rentalContainer={rentalContainer}/>
        case('unauthorized'):
            return <Unauthorized rentalContainer={rentalContainer}/>
        case('wrongDate'):
            return <WrongDate rentalContainer={rentalContainer}/>
        default:
            return <Unknown/>

    }
}


const Success = (props) => {

    return (
        <>
            <div className="c-scan__qr c-scan__qr--success">
                <SvgScanSuccess/>
                <h2>#{props.rentalContainer?.rental.id}</h2>
                <p>{translate('eventSuccess')}</p>

            </div>

            <div className={'c-scan-text'}>
                <small>{props.availability?.available} {translate('eventSpacesRemaining')}</small>
            </div>

        </>
    )

}

const WrongSpace = (props) => {


    return (
        <>
            <div className="c-scan__qr c-scan__qr--success">
                <SvgScanFailed/>
                <h2>#{props.rentalContainer?.rental.id}</h2>
                <p>{translate('eventWrongPlace')}</p>
            </div>

            <div className={'c-scan-text'}>
                <small>{translate('eventBookingBelongTo')}</small>
                <h2>{props.rentalContainer?.partnerLocation.name}</h2>
                <small>{props.rentalContainer?.partnerLocation.address} {props.rentalContainer?.partnerLocation.number}, {props.rentalContainer?.partnerLocation.zipCode}</small>
            </div>

        </>
    )

}

const Unknown = (props) => {

    return (
        <>

            <div className="c-scan__qr c-scan__qr--success">
                <SvgScanFailed/>
                <p>{translate('eventUnknownCode')}</p>
            </div>

        </>
    )

}

const Unauthorized = (props) => {

    return (
        <>

            <div className="c-scan__qr c-scan__qr--success">
                <SvgScanFailed/>
                <p>{translate('eventUnauthorized')}</p>
            </div>


        </>
    )

}

const WrongDate = (props) => {

    return (
        <>

            <div className="c-scan__qr c-scan__qr--success">
                <SvgScanFailed/>
                <h2>#{props.rentalContainer?.rental.id}</h2>
                <p>{translate('eventWrongDayTitle')}</p>
            </div>

            <div className="c-scan__on-demand">
                <small>{translate('eventWrongDaySubtitle')}</small>
                <h2>{moment(props.rentalContainer?.rental.dateRentStart).format('dddd MMMM Do')}</h2>
                <small>{translate('eventWrongDayContent')}</small>
            </div>

        </>
    )

}

const InvalidCode = (props) => {

    return (
        <>
            <div className="c-scan__qr c-scan__qr--success">
                <SvgInvalidCode/>
                <h2>{translate('eventInvalidCodeTitle')}</h2>
            </div>

            <div className={'c-scan-text'}>
                <small>{translate('eventInvalidCodeContent')}</small>
            </div>
        </>
    )

}


const AlreadyBooked = (props) => {

    return (
        <>

            <div className="c-scan__qr c-scan__qr--small">
                <SvgInvalidCode/>
                <h2>{props.rentalContainer?.rental.id}</h2>
                <p>{translate('eventAlreadyBookedTitle')}</p>
            </div>

            <div className={'c-scan__on-demand'}>
                <small>{translate('eventAlreadyBookedContent')}</small>
            </div>

        </>
    )

}