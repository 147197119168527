import DashboardAction from "../../stores/dashboard/DashboardAction";


const DashboardDispatcher = (dispatch) => ({

    dispatch: dispatch,
    load: () => {
        dispatch(DashboardAction.requestRentalsInPeriod())
    },


})


export default DashboardDispatcher;
