import React from "react";
import {connect} from "react-redux";
import translate from "../../../createSlice/common/helpers/utils/translate";
import "react-datepicker/dist/react-datepicker.css";
import DisplayName from "../../../common/helpers/DisplayName";
import RentalCustomerDataAction from "../../../stores/rental/customerData/RentalCustomerDataAction";

const mapStateToProps = (state) => ({
    data: state.rentalCustomerData,
});


class searchUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            invalidEmail: false,
            email: ''
        }
    }

    render() {

        return (
            <>
                <div className="c-form__row">
                    <div className="c-form__group">
                        <div className="s-search">

                            <input data-hj-allow type="text" placeholder="Zoek naar klant op emailadres"
                                   onKeyDown={this._handleKeyDown}
                                   onChange={(e) => this.handleChange(e)} value={this.props.data.form.newCustomer}/>

                            <button className="btn btn--secondary btn--icon" type={'button'}
                                    onClick={() => this.handleSubmit()}>
                                <i className="icon icon-search"/>
                                <span>{translate('zoeken')}</span>
                            </button>
                        </div>

                        {this.state.invalidEmail &&
                        <p>Vul a.u.b. een correct e-mailadres in</p>
                        }


                    </div>
                </div>

                < div
                    className="c-form__row">
                    < div
                        className="c-form__group">
                        {
                            this.props.data.customers &&
                            <div className={'c-form__select'}>
                                <i className={'icon icon-arrow-down'} />
                                <select className={'c-select-customers'} value={this.props.data.activeCustomer?.id} onChange={(e) => this.setSearchedCustomer(e)}>

                                    <option  value={'default'}>Selecteer klant</option>


                                    {this.props.data.customers.map((customer) => {

                                        if (customer.type === 'private') {
                                            return (
                                                <option value={customer.id} key={'customer-' + customer.id}>
                                                    {DisplayName(customer.privatePerson)} [{customer.privatePerson.email}]
                                                </option>
                                            )
                                        } else {
                                            return (
                                                <option value={customer.id} key={'customer-' + customer.id}>
                                                    {customer.company.name} [{customer.company.primary_email}]
                                                </option>
                                            )
                                        }


                                    })}
                                </select>
                            </div>
                        }

                    </div>
                </div>
            </>
        )

    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit(e)
            e.preventDefault();
        }
    }


    handleChange = (e) => {

        this.setState({
            email: e.target.value
        })

        e.persist();
    }

    handleSubmit = () => {
        this.props.dispatch(RentalCustomerDataAction.searchCustomerByEmail(this.state.email))
    }

    setSearchedCustomer = (e) => {
        const customer = this.props.data.customers.filter((customer) => customer.id === parseInt(e.target.value))[0]
        this.props.dispatch(RentalCustomerDataAction.setSearchedCustomerData(customer))
    }


}

export default connect(mapStateToProps)(searchUser);
