export const countRentalItems = (orderLines) => {

    if (orderLines && orderLines.length) {

        let count = 0;

        orderLines.filter((orderLine) => orderLine.type === 'category').forEach(orderLine => {
            count += orderLine.quantity
        })

        return count

    } else {
        return 0
    }

}