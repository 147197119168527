// Dependencies
import React from 'react'
import {Link} from "react-router-dom";

// Constants
import RouteEnum from "../../common/constants/RouteEnum";

export default class PinForgot extends React.Component {

    render() {
        return (
            <div className="c-auth__form c-form">
                <small>Toegangscode vergeten</small>
                <div className="c-form__row">
                    <div className="c-form__group">
                        <label htmlFor="email">E-mailadres</label>
                        <input data-hj-allow type="email" id="email"/>
                    </div>
                </div>
                <div className="c-form__row">
                    <div className="c-form__group">
                        <button className="btn btn--primary"><span>Wachtwoord herstellen</span></button>
                    </div>
                </div>
                <Link to={RouteEnum.Pin}>Inloggen</Link>
            </div>
        )
    }

}
