// Dependencies
import React from 'react';
import {connect} from "react-redux";

// Actions
import RentalCustomerDataAction from "../../stores/rental/customerData/RentalCustomerDataAction";
import RentalTotalsAction from "../../stores/rental/totals/RentalTotalsAction";

// Components
import TinyLoader from "../../common/components/General/TinyLoader";
import RentalTotals from "../../common/components/Rental/RentalTotals";
import RentalForm from "../../common/components/Rental/RentalForm";
import ToDetailButton from "../../common/components/General/ToDetailButton";

// Helpers
import ToTransactionCheck from "../../common/helpers/ToTransactionCheck";
import IsCurrentPartnerLocation from "../../common/helpers/IsCurrentPartnerLocation";

// Dispatchers
import RentalDispatcher from "../../common/dispatchers/RentalDispatcher";
import Scanner from "../../common/components/General/Scanner";
import translate from "../../createSlice/common/helpers/utils/translate";
import {PinValidationWrapper} from "../../common/wrappers/PinValidationWrapper";

const mapStateToProps = (state) => ({
    ...state.rentalCustomerData,
    rentalDetail: state.rentalDetail.data,
    environment: state.environment,
    rentalTotals: state.rentalTotals
});

class RentalMutationCustomerData extends React.Component {

    componentDidMount() {
        this._init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.rentalUuid !== this.props.match.params.rentalUuid) {
            this._init()
        }
    }

    _init = () => {
        const {rentalUuid} = this.props.match.params
        this.props.resetAll()
        this.props.loadMin(rentalUuid)
    }


    render() {

        if (this.props.rentalDetail) {

            const isCurrentPartnerLocation = IsCurrentPartnerLocation(this.props)

            if (isCurrentPartnerLocation) {
                return (
                    <main>

                        <PinValidationWrapper>

                            <Scanner type={'rental'}/>

                            <div className="o-container o-container--primary">
                                <header className="c-subject-header c-subject-header--split">
                                    <h1>{translate('changeCustomerData')}</h1>
                                    <div className="c-subject-header__buttons">
                                        <ToDetailButton history={this.props.history} slug={this.props.environment.slug}
                                                        uuid={this.props.rentalDetail.data.rental.uuid}/>
                                    </div>
                                </header>
                                <div className="s-order-detail">
                                    <div className="c-info-blocks c-form">


                                        <div className="c-info-blocks__row">
                                            <div className="c-info-blocks__text"><h2>
                                                <u>#{this.props.rentalDetail.data.rental.id}</u></h2>

                                                <div className="c-info-blocks__row">

                                                    <RentalForm
                                                        data={this.props.form}
                                                        action={RentalCustomerDataAction}
                                                        store={'RentalCustomerData'}
                                                        create={false}/>

                                                </div>

                                            </div>

                                        </div>

                                        {this.props.rentalTotals.totals && this.props.rentalDetail.RentalItems &&
                                        <RentalTotals data={this.props.rentalTotals.totals}
                                                      overrideItems={this.props.rentalDetail.RentalItems}
                                                      hideInput={false}/>
                                        }

                                        {!this.props.form.data.completed &&

                                        <div className="c-info-blocks__row">
                                            <div className="c-info-blocks__single">
                                                <button className="btn btn--primary" onClick={() => this._onSubmit()}>
                                                    <span>{translate('edit')}</span></button>
                                            </div>
                                        </div>

                                        }

                                    </div>
                                </div>


                            </div>

                        </PinValidationWrapper>

                    </main>
                )

            } else return null

        } else {
            return <TinyLoader/>
        }

    }

    _onSubmit = () => {
        this.props.dispatch(RentalCustomerDataAction.submitMutation()).then(() => {
            if (this.props.response) {
                this.props.dispatch(RentalTotalsAction.submitDeposit(this.props.rentalDetail.data.rental.uuid)).then(() => {
                    ToTransactionCheck(this.props)
                })
            }
        })
    }

}

export default connect(mapStateToProps, RentalDispatcher)(RentalMutationCustomerData);
