import React from 'react'
import {connect} from "react-redux";
import RentalDispatcher from "../../../common/dispatchers/RentalDispatcher";

const mapStateToProps = (state) => ({
    ...state.rentalReturn
});

class RentalReturnErrors extends React.Component {

render() {
        if (this.props.response && this.props.response.items && this.props.response.items.errors) {
            return (

                <div className="c-error-display">
                    <h3>Waarschuwing(en):</h3>

                    <ul>
                        {this.props.response.items.errors.map((error) => {
                            return (
                                <li>{error}</li>
                            )
                        })}
                    </ul>

                </div>

            )

        } else {
            return null
        }
    }


}

export default connect(mapStateToProps, RentalDispatcher)(RentalReturnErrors);
