import {RentalHeader} from "../../../common/components/RentalHeader";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {useHistory} from "react-router";

import {useParams} from "react-router-dom";

import {RentalInfoBar} from "../../../common/components/RentalInfoBar";

import TinyLoader from "../../../../common/components/General/TinyLoader";
import {loadRentalInsurances, submitRentalInsurance} from "./rentalInsurancePageSlice";
import RentalInsurance from "../../../../common/components/Rental/RentalInsurance";
import translate from "../../../common/helpers/utils/translate";
import {PinValidationWrapper} from "../../../../common/wrappers/PinValidationWrapper";


export const RentalInsurancePageContainer = () => {

    const dispatch = useAppDispatch()

    const {rentalUuid} = useParams();

    const {
        rentalContainer
    } = useAppSelector(store => store.rentalInsurancePage)

    const {
        data,
        selected
    } = useAppSelector(store => store.insurances)

    const {
        environment,
    } = useAppSelector(store => store)

    const history = useHistory()
    const [pageLoaded, setPageLoaded] = useState(false)


    useEffect(() => {
        if (rentalUuid) {
            dispatch(loadRentalInsurances(rentalUuid)).then(() => {
                setPageLoaded(true)
            })
        }
    }, [])

    const submit = () => {
        dispatch(submitRentalInsurance(rentalUuid)).then(() => {
            history.push('/' + environment.slug + '/dashboard/rental/transaction-check/' + rentalContainer.rental.uuid)
        })
    }

    if (rentalContainer && pageLoaded) {
        return (
            <main>


                <div className="o-container o-container--primary" style={{paddingBottom: '6rem'}}>

                    <RentalHeader
                        rentalId={rentalContainer.rental.id}
                        rentalUuid={rentalContainer.rental.uuid}
                        pageTitle={'Verzekeringen'}
                    />

                    <RentalInfoBar rentalContainer={rentalContainer}/>

                    <RentalInsurance
                        rentalContainer={rentalContainer}
                    />


                    <button onClick={() => submit()} className={'btn btn--primary'}>
                        <span>{translate('Naar betalen')}</span></button>

                </div>


            </main>
        )
    } else return <TinyLoader/>

}
