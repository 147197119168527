import fetchHelper from "../../../common/helpers/fetchHelper";

export default class InsurancesEffect {

    static setInsurance(value) {
        return parseInt(value)
    }

    static async list(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {

            const data = Object.values(response.items.data).map((item) => {
                return{
                    data: item
                }
            })

            return {
                insurance_required: response.items.insurance_required,
                data: data,
            }
        } else return null

    }

}

