import fetchHelper from "../../../common/helpers/fetchHelper";

export default class RentalExtendEffect {


    static setNewDate(newDate) {
        return newDate
    }

    static async submitMutation(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response
        } else return null

    }

    static reset() {
        return true
    }


}

