import {eventDemandPageSubmit, setCustomerData, setQuantity} from "../eventDemandPageSlice";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {Link} from "react-router-dom";

export const EventDemandDefaultDisplay = () => {

    const {availability, quantity, customerData} = useAppSelector((state) => state.eventDemandPage)
    const {slug} = useAppSelector(state => state.environment)
    const dispatch = useAppDispatch()

    return (
        <>

            <div className="c-scan__qr c-scan__qr--small">
                <h2>On demand entry</h2>


                <p>How may people would you like to give access?</p>
            </div>

            <form className="c-scan__on-demand">
                <small>This number will be redacted from the available space on this location</small>
                <div className="c-scan__on-demand--amount">
                    <div className="c-auth__form c-form" style={{
                        padding: '0',
                        margin: '0',
                        backgroundColor: 'transparent',
                        border: 0
                    }}>


                        <div className="c-form__row">
                            <div className="c-form__group">
                                <input data-hj-allow style={{
                                    background: '#fff',
                                    border: 0,
                                    padding: 0,
                                    margin: 0,
                                    fontSize: '4rem',
                                    textAlign: 'center'
                                }} disabled
                                       className="input"
                                       type="text"

                                       value={quantity}/>
                            </div>
                        </div>
                    </div>
                    {availability?.available &&
                    <div>
                        <button type={'button'} className="plus" disabled={quantity >= availability?.available}
                                onClick={() => dispatch(setQuantity(quantity + 1))}>+
                        </button>
                        <button type={'button'} disabled={quantity <= 0} className="minus"
                                onClick={() => dispatch(setQuantity(quantity - 1))}>-
                        </button>
                    </div>
                    }
                </div>

                <div className="c-form__row" style={{marginBottom: '2rem'}}>
                    <div className="c-form__group">
                        <input data-hj-allow value={customerData.firstName} className="input" type="text" placeholder={'Firstname*'}
                               onChange={(e) => dispatch(
                                   setCustomerData({
                                       firstName: e.target.value
                                   })
                               )}
                               required={true}/>
                    </div>

                    <div className="c-form__group">
                        <input data-hj-allow value={customerData.insertion}
                               onChange={(e) => dispatch(setCustomerData({
                                   insertion: e.target.value
                               }))}
                               className="input" type="text" placeholder={'Insertion'}/>
                    </div>

                    <div className="c-form__group">
                        <input data-hj-allow
                            value={customerData.lastName}
                            className="input"
                            type="text"
                            placeholder={'Lastname*'}
                            required={true}
                            onChange={(e) => dispatch(
                                setCustomerData({
                                    lastName: e.target.value
                                })
                            )}
                        />
                    </div>
                </div>

                <button type={'submit'} disabled={!quantity} onClick={() => dispatch(eventDemandPageSubmit())}
                        className="btn btn--small">
                    Confirm entry
                </button>

                <Link style={{marginTop: '2rem'}} to={'/' + slug + '/'}><u>Scan entry</u></Link>

            </form>
        </>
    )

}
