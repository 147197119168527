import RentalCreateAction from "./RentalCreateAction";

let defaultEndDate = new Date();
defaultEndDate.setDate(defaultEndDate.getDate() + 7);

export default class RentalCreateReducer {

    static initialState = {
        response: null,
        discountPercentage: 0,
        isSubmitting: false,
        resetMessage: ''
    };

    static reducer(state = RentalCreateReducer.initialState, action) {

        switch (action.type) {

            case RentalCreateAction.SUBMIT:

                return {
                    ...state,
                    isSubmitting: true
                };

            case RentalCreateAction.SUBMIT + '_FINISHED':

                return {
                    ...RentalCreateReducer.initialState,
                    response: action.payload,
                    isSubmitting: false,
                    resetMessage: action.payload?.resetMessage
                };

            case RentalCreateAction.RESET:

                return {
                    ...state,
                };

            case RentalCreateAction.RESET + '_FINISHED':

                return {
                    ...RentalCreateReducer.initialState,
                };

            default:
                return state
        }
    }
}
