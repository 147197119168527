const FormatCartToRentalRequest = (shoppingCart) => {

    const categories = []

    if (shoppingCart.bicycles.length) {

        shoppingCart.bicycles.forEach((bicycle) => {
            const bicycleCategory = {}
            bicycleCategory.category_id = bicycle.id
            bicycleCategory.quantity = bicycle.quantity
            categories.push(bicycleCategory)
        })
    }

    if (shoppingCart.accessoires.length) {

        shoppingCart.accessoires.forEach((accessoire) => {
            const accessoireCategory = {}
            accessoireCategory.category_id = accessoire.id
            accessoireCategory.quantity = accessoire.quantity
            categories.push(accessoireCategory)
        })

    }


    return categories

}

export default FormatCartToRentalRequest