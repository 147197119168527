export const calculateGiveItemsPerCategoryNotInOrderLine = (rentalOrderLines, giveItems, rentalItems, takeItems) => {

    const addedCategories = []

    const giveItemsNotInOrderLine = giveItems.filter((giveItem) => {
        return !rentalOrderLines.find((orderLine) => orderLine.type === 'category' && orderLine.categoryId === giveItem.availabilityCategory.id)
    })

    giveItemsNotInOrderLine.forEach((giveItem) => {

        const addedCategoryItem = addedCategories.find((addedCategoryItem) => addedCategoryItem.categoryId === giveItem.availabilityCategory.id)

        if (addedCategoryItem) { 

            addedCategories.map((addedCategoryItem) => {

                if (addedCategoryItem.categoryId === giveItem.availabilityCategory.id) {

                    return {
                        ...addedCategoryItem,
                        quantity: addedCategoryItem.quantity++
                    }
                } else return addedCategoryItem
            })

        } else {

            const label = giveItem.category.alternativeLabel ? giveItem.category.alternativeLabel : giveItem.category.name

            addedCategories.push({
                label: label,
                quantity: 1,
                categoryId: giveItem.availabilityCategory.id
            })
        }

    })

    rentalOrderLines.filter((orderLine) => orderLine.type === 'category').forEach((orderLine) => {

        const givenOrderLineItems = giveItems.filter((giveItem) => {
            return giveItem.availabilityCategory.id === orderLine.categoryId
        })

        const takenOrderLineItems = takeItems?.filter((takeItems) => {
            return takeItems.availabilityCategory.id === orderLine.categoryId
        })

        const rentalOrderLineItems = rentalItems.filter((rentalItem) => {
            return rentalItem.availabilityCategory.id === orderLine.categoryId
        })

        let givenCount = givenOrderLineItems.length + rentalOrderLineItems.length

        if (takenOrderLineItems) {
            givenCount -= takenOrderLineItems.length
        }

        if (givenCount > orderLine.quantity) {

            const label = orderLine.category.alternativeLabel ? orderLine.category.alternativeLabel : orderLine.category.name

            addedCategories.push({
                label: label,
                quantity: givenCount - orderLine.quantity,
                categoryId: orderLine.categoryId
            })
        }

    })

    return addedCategories

}
