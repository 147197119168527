import React from 'react'
import {connect} from "react-redux";
import {PDFDownloadLink} from "@react-pdf/renderer";

import RentalDispatcher from "../../../common/dispatchers/RentalDispatcher";
import TransactionAction from "../../../stores/transaction/TransactionAction";
import RentalPdf from "../../../common/components/Rental/RentalPdf";
import Scanner from "../../../common/components/General/Scanner";
import translate from "../../../createSlice/common/helpers/utils/translate";


const mapStateToProps = (state) => ({
    transaction: state.transaction.detail,
    rentalDetail: state.rentalDetail.data,
    environment: state.environment,
    loading: state.rentalDetail.loading,
    mutations: state.rentalDetail.mutations,
    user: state.user.detail,
    mail: state.rentalDetail.mail,
    rentalTotals: state.rentalTotals,
    rentalIssue: state.rentalIssue
});

class TransactionStatus extends React.Component {


    componentDidMount() {
        this._init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.transactionId !== this.props.match.params.transactionId) {
            this._init()
        }
    }

    _init = () => {
        this.props.resetAll()
        let rentalUuid = this.props.match.params.uuid
        let id = this.props.match.params.transactionId

        this.props.loadMin(rentalUuid)

        this.props.dispatch(TransactionAction.requestDetail({
            rental: rentalUuid,
            id: id
        }))

    }


    render() {

        if (this.props.transaction && this.props.rentalDetail) {

            const fileName = this.props.environment.slug + '-' + this.props.rentalDetail.data.rental.id + '.pdf'

            return (
                <main>

                    <Scanner type={'rental'}/>]

                    <div className="o-container o-container--primary">
                        <header className="c-subject-header c-subject-header--split">
                            <h1>{translate('statusPayment')} #{this.props.transaction.id}</h1>
                        </header>
                        <div className="s-order-detail s-order-detail--finish">
                            <div className="c-info-blocks c-form">
                                <div className="c-info-blocks__row">
                                    <div className="c-info-blocks__text ">

                                        {this.props.transaction.status === 'completed' &&
                                        <div
                                            className="c-info-blocks__text__highlight c-info-blocks__text__highlight--positive c-info-blocks__text__highlight--icon">
                                            <i className="icon icon-check-circle"/>
                                            <div><h4>{translate('paymentSuccessfullyCompleted')}</h4>
                                                <p>{translate('paymentSuccessfullyCompletedSub')}</p></div>
                                        </div>
                                        }


                                        <div className={'c-info-blocks__flex'}>

                                            {!this.props.rentalDetail.loading &&
                                            <PDFDownloadLink className={'btn btn--small btn--outline pdf-download'}
                                                             document={<RentalPdf {...this.props}  />}
                                                             fileName={fileName}>
                                                {({blob, url, loading, error}) =>
                                                    loading ? 'Document ' + translate('beingGenerated') + '...' : 'download PDF'
                                                }
                                            </PDFDownloadLink>
                                            }

                                            {this.props.transaction.status === 'pending' &&

                                            <div
                                                className="c-info-blocks__text__highlight c-info-blocks__text__highlight--negative c-info-blocks__text__highlight--icon">
                                                <i className="icon icon-cross"/>
                                                <div><h4>{translate('paymentPending')}</h4></div>
                                            </div>

                                            }

                                            {this.props.transaction.status === 'cancelled' &&

                                            <div
                                                className="c-info-blocks__text__highlight c-info-blocks__text__highlight--negative c-info-blocks__text__highlight--icon">
                                                <i className="icon icon-cross"/>
                                                <div><h4>{translate('paymentCancelled')}</h4></div>
                                            </div>

                                            }


                                            {this.props.transaction.status === '' &&
                                            <div
                                                className="c-info-blocks__text__highlight c-info-blocks__text__highlight--negative c-info-blocks__text__highlight--icon">
                                                <i className="icon icon-cross"/>
                                                <div><h4>{translate('paymentPartiallyCompleted')}</h4></div>
                                            </div>
                                            }

                                            {this.props.transaction.status !== 'completed' && this.props.transaction.status !== 'cancelled' &&
                                            <div className="c-info-blocks__row">
                                                <div className="c-info-blocks__single">
                                                    <button className="btn btn--primary"
                                                            onClick={() => this._toPayment()}>
                                                        <span>{translate('backToPayment')}</span>
                                                    </button>
                                                </div>
                                            </div>
                                            }

                                            <div className="c-info-blocks__row">
                                                <div className="c-info-blocks__single">
                                                    <button className="btn btn--primary" onClick={() => this._toHome()}>
                                                        <span>{translate('toRental')}</span>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )
        } else {
            return null
        }
    }

    _toHome = () => {
        const {history} = this.props
        history.push('/' + this.props.environment.slug + '/dashboard/rental/detail/' + this.props.rentalDetail.data.rental.uuid)
    }

    _toPayment = () => {
        const {history} = this.props
        history.push('/' + this.props.environment.slug + '/dashboard/rental/' + this.props.rentalDetail.data.rental.uuid + '/transaction/' + this.props.transaction.id)
    }

}

export default connect(mapStateToProps, RentalDispatcher)(TransactionStatus);
