import React from "react";
import {connect} from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import {Formik, Field} from 'formik';
import CountryAction from "../../../stores/country/CountryAction";
import SearchUser from "../../../views/RentalCreate/components/SearchUser";
import SelectContactPerson from "../General/SelectContactPerson";
import RentalCustomerDataAction from "../../../stores/rental/customerData/RentalCustomerDataAction";
import RentalCreateAction from "../../../stores/rental/create/RentalCreateAction";
import RentalTotalsAction from "../../../stores/rental/totals/RentalTotalsAction";
import ToTransactionCheck from "../../helpers/ToTransactionCheck";
import translate from "../../../createSlice/common/helpers/utils/translate";


const mapStateToProps = (state) => ({
    countries: state.country.list,
    environment: state.environment,
    data: state.rentalCustomerData,
    createRental: state.rentalCreate,
});


class RentalForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showMore: false
        }
    }

    componentDidMount() {
        this.props.dispatch(CountryAction.countryList())
    }

    changeForm() {
        if (this.props.data.completed) {
            this.props.dispatch(RentalCustomerDataAction.unsetFormCompletion())
        }
    }

    validate = values => {
        this.props.dispatch(RentalCustomerDataAction.validateForm(values))
    }

    render() {


        if (this.props.countries && this.props.data) {

            return (

                <Formik
                    key={'form-view'}
                    enableReinitialize={true}
                    validate={this.validate}
                    initialValues={this.props.data.form.data}
                    onSubmit={(values) => {
                        this.props.dispatch(RentalCustomerDataAction.validateForm(values)).then(() => {
                            this.props.dispatch(RentalCustomerDataAction.updateForm(values)).then(() => {
                                if (this.props.create) {
                                    this.props.dispatch(RentalCreateAction.submit(
                                        this.props.createRental.response ?
                                            this.props.createRental.response.items.rental.uuid :
                                            null
                                    ))
                                }
                            })
                        })
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit} onChange={() => this.changeForm()}>


                            <div className="c-info-blocks__row">
                                <div className="c-info-blocks__single">
                                    <header className="c-subject-header c-subject-header--closed">
                                        <h2>{translate('scanItems')}</h2>
                                    </header>
                                    <div className="c-info-blocks__single__body">


                                        <>
                                            <div className="c-form__row">

                                                {this.props.create &&
                                                    <div
                                                        className="c-form__group c-form__group--radio c-form__group--radio-label">
                                                        <span>{translate('newCustomer')}?</span>
                                                        <div>
                                                            <label htmlFor="newTrue">
                                                                <Field data-hj-allow
                                                                       tabIndex="-1"
                                                                       type="radio"
                                                                       id="newTrue"
                                                                       name={'newCustomer'}
                                                                       value={'true'}
                                                                       required
                                                                />
                                                                <span>{translate('yes')}</span>
                                                            </label>
                                                            <label htmlFor="newFalse">
                                                                <Field data-hj-allow
                                                                       tabIndex="-1"
                                                                       type="radio"
                                                                       id="newFalse"
                                                                       name={'newCustomer'}
                                                                       value={'false'}
                                                                       required
                                                                />
                                                                <span>{translate('no')}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                }

                                                {values.newCustomer === 'true' &&
                                                    <div
                                                        className="c-form__group c-form__group--radio c-form__group--radio-label">
                                                        <span>{translate('customerType')}</span>
                                                        <div>
                                                            <label htmlFor="private">
                                                                <Field data-hj-allow
                                                                       tabIndex="-1"
                                                                       type="radio" id="private"
                                                                       name={'type'}
                                                                       value={'private'}
                                                                       required
                                                                />
                                                                <span> {translate('privateCustomerType')} </span>
                                                            </label>

                                                            <label htmlFor="company">
                                                                <Field data-hj-allow
                                                                       tabIndex="-1"
                                                                       type="radio"
                                                                       id="company"
                                                                       name={'type'}
                                                                       value={'company'}
                                                                       required
                                                                />
                                                                <span> {translate('company')} </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                }

                                            </div>

                                            {this.props.create && values.newCustomer === 'false' && <SearchUser/>}

                                        </>


                                        {(values.type === 'company' && this.props.create && values.contactPersons && values.newCustomer === 'false') &&
                                            <SelectContactPerson
                                                contactPerson={values.contactPerson}
                                                setFieldValue={setFieldValue}
                                                contactPersons={values.contactPersons}/>
                                        }


                                        {values.type === 'private' &&
                                            <>
                                                <div className="c-form__row c-form__row--five">
                                                    <div className="c-form__group">
                                                        <label htmlFor="">{translate('lastName')}*</label>
                                                        <Field data-hj-allow
                                                               tabIndex="0"
                                                               name={'privatePerson.last_name'}
                                                               type="text"
                                                               minLength={2}
                                                               required
                                                        />
                                                    </div>
                                                    <div className="c-form__group c-form__group--extra-small">
                                                        <label htmlFor="">{translate('prefix')}</label>
                                                        <div className="c-form__select">
                                                            <i className="icon icon-arrow-down"/>
                                                            <Field data-hj-allow as="select"
                                                                   name="privatePerson.gender_id" tabIndex="-1">
                                                                <option value={false}
                                                                        disabled={true}>{translate('prefix')}
                                                                </option>
                                                                <option
                                                                    value={1}>{translate('malePrefix')}
                                                                </option>
                                                                <option
                                                                    value={2}>{translate('femalePrefix')}
                                                                </option>
                                                                <option
                                                                    value={3}>{translate('neutralPrefix')}
                                                                </option>
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className="c-form__group c-form__group--extra-small">
                                                        <label htmlFor="">{translate('initials')}</label>
                                                        <Field data-hj-allow
                                                               tabIndex="-1"
                                                               name={'privatePerson.initials'}
                                                               type="text"
                                                        />
                                                    </div>
                                                    <div className="c-form__group">
                                                        <label htmlFor="">{translate('firstName')}</label>
                                                        <Field data-hj-allow
                                                               tabIndex="-1"
                                                               name={'privatePerson.first_name'}
                                                               type="text"
                                                        />
                                                    </div>
                                                    <div className="c-form__group c-form__group--extra-small">
                                                        <label htmlFor="">{translate('insertion')}</label>
                                                        <Field data-hj-allow
                                                               name={'privatePerson.insertion'}
                                                               tabIndex="-1"
                                                               type="text"
                                                        />
                                                    </div>

                                                </div>

                                                <div className="c-form__row">
                                                    <div className="c-form__group">
                                                        <label htmlFor="">{translate('emailAddress')}</label>
                                                        <Field data-hj-allow
                                                               name={'privatePerson.email'}
                                                               tabIndex="-1"
                                                               type="email"
                                                        />
                                                    </div>
                                                    <div className="c-form__group">
                                                        <label
                                                            htmlFor="">{translate('phoneNumber')}</label>
                                                        <Field data-hj-allow
                                                               pattern={"^[0-9-]*$"}
                                                               name={'privatePerson.telephone'}
                                                               tabIndex="-1"
                                                               type="text"
                                                        />
                                                    </div>
                                                    <div className="c-form__group">
                                                        <label
                                                            htmlFor="">{translate('documentNumber')}</label>
                                                        <Field data-hj-allow
                                                               name={'customer.document_number'}
                                                               tabIndex="-1"
                                                               type="text"
                                                        />
                                                    </div>
                                                    <div className="c-form__group">
                                                        <label
                                                            htmlFor="">{translate('documentType')}</label>
                                                        <div className={'c-form__select'}>
                                                            <i className="icon icon-arrow-down"/>
                                                            <Field as="select" name="customer.document_type"
                                                                   data-hj-allow>
                                                                <option
                                                                    value={null}>
                                                                </option>
                                                                <option
                                                                    value={'passport'}>{translate('Paspoort')}
                                                                </option>
                                                                <option
                                                                    value={'drivers-license'}>{translate('Rijbewijs')}
                                                                </option>
                                                                <option
                                                                    value={'identity-card'}>{translate('Identiteitskaart')}
                                                                </option>
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="c-form__row">
                                                    <div className="c-form__group">
                                                        <label
                                                            htmlFor="">{translate('notes')}</label>
                                                        <Field data-hj-allow as={'textarea'} name={'remarks'} id=""
                                                               cols="16"
                                                               tabIndex="-1"
                                                               rows="5"/>
                                                    </div>
                                                </div>

                                                {!this.state.showMore &&
                                                    <p className={'c-add-more'}
                                                       onClick={() => this._toggleShowMore()}>+ {translate('addMoreOptional')}</p>
                                                }
                                                {!!this.state.showMore &&
                                                    <div className="c-form__row c-form__row--six">

                                                        <div className="c-form__group">
                                                            <label htmlFor="">Land</label>
                                                            <div className="c-form__select">
                                                                <i className="icon icon-arrow-down"/>
                                                                <Field data-hj-allow as="select"
                                                                       name="address.country_id" tabIndex="-1">
                                                                    <option value={false}
                                                                            disabled={true}>Land
                                                                    </option>
                                                                    {this.props.countries.map((language) => (
                                                                        <option value={language.id}
                                                                                key={'personal-language-' + language.id}>{language.name}</option>
                                                                    ))}
                                                                </Field>
                                                            </div>
                                                        </div>

                                                        <div className="c-form__group">
                                                            <label htmlFor="">Straat</label>
                                                            <Field data-hj-allow
                                                                   name={'address.street'}
                                                                   tabIndex="-1"
                                                                   type="text"
                                                            />
                                                        </div>
                                                        <div className="c-form__group c-form__group--small">
                                                            <label
                                                                htmlFor="">{translate('houseNumber')}</label>
                                                            <Field data-hj-allow
                                                                   name={'address.house_number'}
                                                                   tabIndex="-1"
                                                                   type="number"
                                                            />
                                                        </div>
                                                        <div className="c-form__group c-form__group--small">
                                                            <label
                                                                htmlFor="">{translate('addition')}</label>
                                                            <Field data-hj-allow
                                                                   name={'address.house_number_addition'}
                                                                   tabIndex="-1"
                                                                   type="text"
                                                            />
                                                        </div>
                                                        <div className="c-form__group c-form__group--small">
                                                            <label htmlFor="">{translate('zipcode')}</label>
                                                            <Field data-hj-allow
                                                                   name={'address.zip_code'}
                                                                   tabIndex="-1"
                                                                   type="text"
                                                            />
                                                        </div>
                                                        <div className="c-form__group">
                                                            <label htmlFor="">{translate('city')}</label>
                                                            <Field data-hj-allow
                                                                   name={'address.city'}
                                                                   tabIndex="-1"
                                                                   type="text"
                                                            />
                                                        </div>
                                                    </div>
                                                }

                                            </>
                                        }
                                        {values.type === 'company' &&
                                            <>

                                                <div className="c-form__row c-form__row--five">
                                                    <div className="c-form__group">
                                                        <label htmlFor="">{translate('companyName')}*</label>
                                                        <Field data-hj-allow
                                                               name={'company.name'}
                                                               tabIndex="-1"
                                                               type="text"
                                                               minLength={2}
                                                               required
                                                        />
                                                    </div>

                                                    <div className="c-form__group">
                                                        <label htmlFor="">{translate('initials')}</label>
                                                        <Field data-hj-allow
                                                               name={'contactPerson.initials'}
                                                               type="text"
                                                        />
                                                    </div>
                                                    <div className="c-form__group">
                                                        <label htmlFor="">{translate('firstName')}</label>
                                                        <Field data-hj-allow
                                                               name={'contactPerson.first_name'}
                                                               tabIndex="-1"
                                                               type="text"
                                                        />
                                                    </div>
                                                    <div className="c-form__group">
                                                        <label htmlFor="">{translate('insertion')}</label>
                                                        <Field data-hj-allow
                                                               name={'contactPerson.insertion'}
                                                               tabIndex="-1"
                                                               type="text"
                                                        />
                                                    </div>
                                                    <div className="c-form__group">
                                                        <label htmlFor="">{translate('lastName')}*</label>
                                                        <Field data-hj-allow
                                                               name={'contactPerson.last_name'}
                                                               tabIndex="0"
                                                               type="text"
                                                               minLength={2}
                                                               required
                                                        />
                                                    </div>


                                                </div>

                                                <div className="c-form__row">

                                                    <div className="c-form__group">
                                                        <label htmlFor="">{translate('emailAddress')}</label>
                                                        <Field data-hj-allow
                                                               name={'company.primary_email'}
                                                               tabIndex="-1"
                                                               type="email"
                                                        />
                                                    </div>
                                                    <div className="c-form__group">
                                                        <label
                                                            htmlFor="">{translate('phoneNumber')}</label>
                                                        <Field data-hj-allow
                                                               pattern={"^[0-9-]*$"}
                                                               name={'contactPerson.phone'}
                                                               tabIndex="-1"
                                                               type="text"
                                                        />
                                                    </div>

                                                </div>


                                                <div className="c-form__row">
                                                    <div className="c-form__group">
                                                        <label
                                                            htmlFor="">{translate('notes')}</label>
                                                        <Field as={'textarea'} name={'remarks'} id="" cols="30"
                                                               data-hj-allow
                                                               tabIndex="-1"
                                                               rows="10"/>
                                                    </div>
                                                </div>

                                                {!this.state.showMore &&
                                                    <p className={'c-add-more'}
                                                       onClick={() => this._toggleShowMore()}>+ {translate('addMoreOptional')}</p>
                                                }
                                                {!!this.state.showMore &&
                                                    <>
                                                        <div className="c-form__row c-form__row--five">
                                                            <div className="c-form__group">
                                                                <label htmlFor="">{translate('streetName')}</label>
                                                                <Field data-hj-allow
                                                                       tabIndex="-1"
                                                                       name={'address.street'}
                                                                       type="text"
                                                                />
                                                            </div>
                                                            <div className="c-form__group c-form__group--small">
                                                                <label
                                                                    htmlFor="">{translate('houseNumber')}</label>
                                                                <Field data-hj-allow
                                                                       tabIndex="-1"
                                                                       name={'address.house_number'}
                                                                       type="number"
                                                                />
                                                            </div>
                                                            <div className="c-form__group c-form__group--small">
                                                                <label
                                                                    htmlFor="">{translate('addition')}</label>
                                                                <Field data-hj-allow
                                                                       tabIndex="-1"
                                                                       name={'address.house_number_addition'}
                                                                       type="text"
                                                                />
                                                            </div>
                                                            <div className="c-form__group c-form__group--small">
                                                                <label htmlFor="">{translate('zipcode')}</label>
                                                                <Field data-hj-allow
                                                                       tabIndex="-1"
                                                                       name={'address.zip_code'}
                                                                       type="text"
                                                                />
                                                            </div>
                                                            <div className="c-form__group">
                                                                <label htmlFor="">{translate('city')}</label>
                                                                <Field data-hj-allow
                                                                       tabIndex="-1"
                                                                       name={'address.city'}
                                                                       type="text"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="c-form__row">
                                                            <div className="c-form__group">
                                                                <label htmlFor="">{translate('country')}</label>
                                                                <div className="c-form__select">
                                                                    <i className="icon icon-arrow-down"/>
                                                                    <Field data-hj-allow as="select"
                                                                           name="address.country_id" tabIndex="-1">
                                                                        <option value={false}
                                                                                disabled={true}>{translate('country')}
                                                                        </option>
                                                                        {this.props.countries.map((language) => (
                                                                            <option value={language.id}
                                                                                    key={'company-lanuage-' + language.id}>{language.name}</option>
                                                                        ))}
                                                                    </Field>
                                                                </div>
                                                            </div>

                                                        </div>


                                                        <div className={'c-form__row c-form__row--quarter'}>

                                                            <div className="c-form__group">
                                                                <label htmlFor="">{translate('Website')}</label>
                                                                <Field data-hj-allow
                                                                       tabIndex="-1"
                                                                       name={'company.website'}
                                                                       type="text"
                                                                />
                                                            </div>


                                                            <div className="c-form__group">
                                                                <label
                                                                    htmlFor="">{translate('emailAddress')} {translate('contactPerson')}</label>
                                                                <Field data-hj-allow
                                                                       tabIndex="-1"
                                                                       name={'contactPerson.email'}
                                                                       type="email"
                                                                />
                                                            </div>

                                                            <div className="c-form__group">
                                                                <label htmlFor="">{translate('cocNumber')}</label>
                                                                <Field data-hj-allow
                                                                       tabIndex="-1"
                                                                       name={'company.coc_number'}
                                                                       type="number"
                                                                />
                                                            </div>

                                                            <div className="c-form__group">
                                                                <label htmlFor="">{translate('vatNumber')}</label>
                                                                <Field data-hj-allow
                                                                       tabIndex="-1"
                                                                       name={'company.vat_number'}
                                                                       type="number"
                                                                />
                                                            </div>


                                                        </div>
                                                    </>
                                                }

                                            </>
                                        }

                                        {!this.props.data.form.completed && this.props.create &&
                                            <button
                                                tabIndex="0"
                                                style={{
                                                    backgroundColor: this.props.environment.data.info.primary_color_dark,
                                                    color: '#fff',
                                                    fontSize: '1.4rem',
                                                    marginTop: '1rem'
                                                }} className={'btn btn--small btn--primary'}
                                                type={'submit'}>{translate('submitData')}</button>
                                        }

                                    </div>

                                </div>
                            </div>


                        </form>
                    )}
                </Formik>
            )

        } else return null

    }

    _toggleShowMore = () => {
        this.setState({
            showMore: !this.state.showMore
        })
    }

}

export default connect(mapStateToProps)(RentalForm);
