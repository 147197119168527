import CalculateDaysBetween from "../../../common/helpers/CalculateDaysBetween";
import fetchHelper from "../../../common/helpers/fetchHelper";

export default class RentalCancellationEffect {

    static async requestCosts(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            const refundPrice = parseFloat(values.rentalData.totalPrice) - parseFloat(response.items.cost)
            const daysLeft = CalculateDaysBetween(new Date(), values.rentalData.rental.date_rent_start)

            return {
                costs: response.items.cost,
                refund: {
                    price: refundPrice,
                    percentage: parseInt((refundPrice / values.rentalData.totalPrice) * 100)
                },
                daysLeft: daysLeft
            }
        } else return null

    }

    static async changeReason(value, dispatch) {
        return value
    }

    static async submitMutation(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response
        } else return null
    }
}

