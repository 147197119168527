import {BaseModel} from 'sjs-base-model';
import FormatDate from "../helpers/FormatDate";

export default class MutationModel extends BaseModel {
    id = null
    date = null
    type = null
    oldState = null
    newState = null
    transactionId = null

    constructor(data) {
        super();
        this.update(data);
    }

    update(data) {

        this.id = data.id
        this.date = FormatDate(data.date_updated)
        this.type = data.type.name
        this.transactionId = data.transaction_id

        switch (data.type.id) {

            // Verlengen
            case(2):

                this.oldState = 'nvt'
                this.newState = 'nvt'

                break;
            default:
                this.oldState = 'nvt'
                this.newState = 'nvt'

        }

    }

}
