import CountryAction from './CountryAction'

export default class CountryReducer {

    static initialState = {
        list: []
    };

    static reducer(state = CountryReducer.initialState, action) {
        switch (action.type) {

            case CountryAction.REQUEST_COUNTRY_LIST:
                return {
                    ...state,
                    list: []
                };
            case CountryAction.REQUEST_COUNTRY_LIST + '_FINISHED':
                return {
                    ...state,
                    list: action.payload.response
                };

            default:
                return state;
        }
    }
}