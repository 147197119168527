export const cleanScanCode = (code) => {

    code = code.replace('http://', '');
    code = code.replace('https://', '');
    code = code.replace('www.', '');
    code = code.replace('t29.nl/i/', '');
    code = code.replace('t29.nl/b/', '');
    code = code.replace('\\000026', '');

    code = code.replace('2CR.NL/?1ATX0', '');
    code = code.replace('2cr.nl/?1ABB0', '');
    code = code.replace('2CR.NL/?1ABB0', '');
    code = code.split('!')[0]

    return code

}