import React from "react";
import NotificationSuccess from "./NotificationSuccess";
import NotificationError from "./NotificationError";
import NotificationInfo from "./NotificationInfo";

const Notifications = (props) => {

    return (
        <>
            {!!props.info &&
            <NotificationInfo text={props.info}/>}

            {!!props.error &&
            <NotificationError text={props.error}/>}

            {!!props.success &&
            <NotificationSuccess text={props.success}/>}
        </>

    )

};

export default Notifications