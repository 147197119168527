import moment from "moment";


export const calculateNextStartTime = () => {

    const currentMoment = moment()
    const currentMinute = parseInt(currentMoment.format('mm'))
    const currentHour = parseInt(currentMoment.format('HH'))
    let minute = ''
    let hour = currentHour + ''

    if (currentMinute > 45) {
        hour = (currentHour + 1) + ''
        minute = '00'
    } else if (currentMinute > 30) {
        minute = '45'
    } else if (currentMinute > 15) {
        minute = '30'
    } else {
        minute = '15'
    }

    return hour + ':' + minute

}