import RentalCategoryAction from "../../stores/rental/category/RentalCategoryAction";
import RentalTotalsAction from "../../stores/rental/totals/RentalTotalsAction";
import InsurancesAction from "../../stores/insurance/list/InsurancesAction";

const ChangeBicycleNumber = (data, direction, dispatch, rentalId) => {

    const values = {
        quantity: data.quantity + direction,
        id: data.id,
        available: data.available,
    }

    console.log(data, direction, dispatch, rentalId)

    // Mutating the number
    dispatch(RentalCategoryAction.mutateBicycleSelectionNumber(values)).then(() => {
        dispatch(RentalCategoryAction.updateCart()).then(() => {
            dispatch(InsurancesAction.list())
            dispatch(RentalTotalsAction.requestTotals(false, false, false, rentalId))
        })
    })

}

export default ChangeBicycleNumber
