import {EventScanDefaultDisplay} from "./displays/EventScanDefaultDisplay";
import {EventResult} from "../../../common/components/EventResult";
import {EventScanManualDisplay} from "./displays/EventScanManualDisplay";
import {EventScanning} from "../../../common/components/EventScanning";
import {EventHeader} from "../../../common/components/EventHeader";
import {EventWrapper} from "../../../common/wrappers/EventWrapper";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import React, {useEffect} from "react";
import {getEventAvailability, reset, setCode} from "./eventScanPageSlice";

export const EventScanPageContainer = () => {

    const {partnerLocation} = useAppSelector((state) => state)

    const {
        scanning,
        display,
        resultCode,
        availability,
        rentalContainer
    } = useAppSelector((state) => state.eventScanPage)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getEventAvailability())
    }, [resultCode])

    return (
        <EventWrapper>


            <EventHeader
                availability={availability}
                partnerLocation={partnerLocation.detail}
            />

            {!resultCode &&
            <>
                {(display === 'default' && !scanning && !resultCode) && <EventScanDefaultDisplay/>}
                {(display === 'manual' && !scanning && !resultCode) && <EventScanManualDisplay/>}
                {scanning && <EventScanning text={'scanning'}/>}
            </>}

            {resultCode &&
            <>
                <EventResult
                    resultCode={resultCode}
                    availability={availability}
                    rentalContainer={rentalContainer}
                />

                <a className="back-btn" onClick={() => dispatch(reset())}>
                    <h2><u>Back</u></h2>
                </a>

            </>}

        </EventWrapper>
    )

}