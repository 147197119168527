import React from "react";

const IconLock = () => (
    <svg width="20px" height="23px" viewBox="0 0 448 512" version="1.1">
        <title>lock</title>
        <g id="icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="lock" fill="#000000" fillRule="nonzero">
                <path
                    d="M400,224 L376,224 L376,152 C376,68.2 307.8,0 224,0 C140.2,0 72,68.2 72,152 L72,224 L48,224 C21.5,224 0,245.5 0,272 L0,464 C0,490.5 21.5,512 48,512 L400,512 C426.5,512 448,490.5 448,464 L448,272 C448,245.5 426.5,224 400,224 Z M296,224 L152,224 L152,152 C152,112.3 184.3,80 224,80 C263.7,80 296,112.3 296,152 L296,224 Z"
                    id="Shape"/>
            </g>
        </g>
    </svg>
);

export default IconLock