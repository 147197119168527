const MapIssuesToRentalRequest = (issueItems) => {

    let categories = []

    issueItems.forEach((item) => {

        const categoryIndex = categories.findIndex(category => category.category_id === item.availability_category.id)

        if (categories[categoryIndex]) {
            const newCount = categories[categoryIndex].quantity + 1
            categories[categoryIndex].quantity = newCount

        } else {

            categories.push({
                category_id: item.availability_category.id,
                quantity: 1
            })
        }


    })

    return categories

}

export default MapIssuesToRentalRequest
