import RentalPeriodAction from "./RentalPeriodAction";
import moment from 'moment'
import {calculateNextStartTime} from "../../../createSlice/common/helpers/calculate/calculateNextStartTime";

let defaultEndDate = new Date();
defaultEndDate.setDate(defaultEndDate.getDate() + 7);

export default class RentalPeriodReducer {

    static initialState = {
        period: {
        },
        time: calculateNextStartTime(),
        loadedFromInfo: false,
        response: null,
        availability: null,
    };

    static reducer(state = RentalPeriodReducer.initialState, action) {


        switch (action.type) {
            case RentalPeriodAction.SET + '_FINISHED':
                return {
                    ...state,
                    period: action.payload,
                };

            case RentalPeriodAction.LOAD_FROM_INFO + '_FINISHED':
                return {
                    ...state,
                    period: action.payload.period,
                    time: action.payload.time,
                    loadedFromInfo: true
                };

            case RentalPeriodAction.LOAD_ENVIRONMENT_SETTINGS + '_FINISHED':
                return {
                    ...state,
                    period: {
                        ...action.payload
                    },
                };

            case RentalPeriodAction.RESET + '_FINISHED':
                return {
                    ...RentalPeriodReducer.initialState
                }
            case RentalPeriodAction.SUBMIT_MUTATION:
                return {
                    ...state,
                    response: null
                };
            case RentalPeriodAction.SUBMIT_MUTATION + '_FINISHED':
                return {
                    ...RentalPeriodReducer.initialState,
                    response: action.payload
                };
            case RentalPeriodAction.SET_TIME + '_FINISHED':
                return {
                    ...state,
                    time: action.payload
                };
            case RentalPeriodAction.CHECK_AVAILABILITY:
                return {
                    ...state,
                    checking: true,
                    availability: RentalPeriodReducer.initialState.availability
                };
            case RentalPeriodAction.CHECK_AVAILABILITY + '_FINISHED':
                return {
                    ...state,
                    checking: false,
                    ...action.payload,
                };
            default:
                return state
        }
    }
}
