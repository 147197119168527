export const checkCategoryGiveExceptions = (args) => {

    let tooManyCategoriesGiven = false
    let notAllCategoriesGiven = false

    const {rentalContainer, categoryMutations, takeItems, currentRentalItems, giveItems} = args
    const rentalOrderLines = rentalContainer?.rentalItems

    const categoryCount = rentalOrderLines?.filter((orderLine) => orderLine.type === 'category').map((orderLine) => {

        const categoryMutation = categoryMutations?.find((categoryMutationItem) => categoryMutationItem.categoryId === orderLine.categoryId)

        // If there already is a category mutation item, use that one
        if (categoryMutation) {
            return {
                id: orderLine.categoryId,
                quantity: parseInt(categoryMutation.quantity + '')
            }
        } else {
            return {
                id: orderLine.categoryId,
                quantity: orderLine.quantity
            }
        }

    })

    categoryCount?.forEach((categoryCountItem) => {

        let categoryQuantityToBeGiven = categoryCountItem.quantity
        const filteredTakeItems = takeItems?.filter((takeItem) => takeItem.availabilityCategory.id === categoryCountItem.id)
        const filteredGiveItems = giveItems?.filter((giveItem) => giveItem.availabilityCategory.id === categoryCountItem.id)
        const filteredCurrentRentalItems = currentRentalItems?.filter((rentalItem) => rentalItem.availabilityCategory.id === categoryCountItem.id)

        // Add the taken item to the count (since it will be subtracted while removing the current rental items)
        if (filteredTakeItems?.length) {
            categoryQuantityToBeGiven += filteredTakeItems.length
        }

        if (filteredCurrentRentalItems?.length) {
            categoryQuantityToBeGiven -= filteredCurrentRentalItems.length
        }

        if (filteredGiveItems?.length) {
            categoryQuantityToBeGiven -= filteredGiveItems.length
        }

        // If there are still categories to be given
        if (categoryQuantityToBeGiven > 0) {
            notAllCategoriesGiven = true
        } else {

            // If there are too many of this category given
            if (categoryQuantityToBeGiven < 0 && rentalOrderLines.length) {
                tooManyCategoriesGiven = true
            }
        }

    })

    giveItems.forEach((giveItem) => {

        const rentalOrderLine = rentalOrderLines?.find((rentalOrderLineItem) => rentalOrderLineItem.categoryId === giveItem.availabilityCategory.id)

        // Category is not part of the rental
        if (!rentalOrderLine && rentalOrderLines.length) {
            tooManyCategoriesGiven = true
        }

    })


    return {
        tooManyCategoriesGiven: tooManyCategoriesGiven,
        notAllCategoriesGiven: notAllCategoriesGiven
    }

}