import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {apiPartnerEventAvailability} from "../../../common/api/partner/event/apiPartnerEventAvailability";
import {apiPartnerRentalCreate} from "../../../common/api/partner/rental/apiPartnerRentalCreate";
import moment from "moment";
import {apiPartnerEventCheckin} from "../../../common/api/partner/event/apiPartnerEventCheckin";
import {formatMobianCheckinResponseToCode} from "../../../common/helpers/format/formatMobianCheckinResponseToCode";


export const eventDemandPageSubmit = createAsyncThunk(
    'eventDemandPage/submit',
    async (_, {getState, dispatch}) => {

        await dispatch(getEventAvailability())

        const {eventDemandPage} = getState()

        // if availability is smaller than the selected quantity
        if (eventDemandPage.availability?.available && eventDemandPage.availability.available < eventDemandPage.quantity) {
            return {
                quantity: eventDemandPage.availability?.available,
                notifications: [{
                    type: 'alert',
                    message: 'The selected quantity is not available anymore. Available: ' + eventDemandPage.availability.available
                }]
            }
        } else {

            const createResponse = await apiPartnerRentalCreate({
                delivery: false,
                dateRentStart: moment().format('YYYY-MM-DD'),
                dateRentEnd: moment().format('YYYY-MM-DD'),
                categories: [{
                    categoryId: parseInt(eventDemandPage.availability?.categoryId + ''),
                    quantity: eventDemandPage.quantity
                }],
                private: eventDemandPage.customerData
            })


            const response = await apiPartnerEventCheckin({
                code: createResponse.data.items?.rental?.uuid
            })

            return {
                rentalContainer: response.data.items?.rental,
                code: formatMobianCheckinResponseToCode(response.data)
            }

        }
    }
)

export const setQuantity = createAsyncThunk(
    'eventDemandPage/setQuantity',
    async (value, {getState, dispatch}) => {

        const {eventDemandPage} = getState()

        // Never allow the quantity to be higher than the availability
        if (eventDemandPage.availability?.available && eventDemandPage.availability.available < value) {
            value = eventDemandPage.availability?.available
        }

        return value
    }
)

export const getEventAvailability = createAsyncThunk(
    'eventDemandPage/getEventAvailability',
    async (_) => {
        try {
            const response = await apiPartnerEventAvailability()
            return response.data.items
        } catch (e) {
            console.log(e)
        }
    }
)

const initialState = {
    scanning: false,
    loading: false,
    quantity: 0,
    notifications: [],
    completed: false,
    customerData: {
        firstName: '',
        insertion: '',
        lastName: ''
    }
}

const eventDemandPageSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        reset: () => initialState,
        setCustomerData: (state, action) => {
            state.customerData = {
                ...state.customerData,
                ...action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getEventAvailability.fulfilled, (state, action) => {
            state.availability = action.payload
        })
        builder.addCase(eventDemandPageSubmit.pending, (state) => {
            state.scanning = true
        })
        builder.addCase(eventDemandPageSubmit.fulfilled, (state, action) => {

            if (action.payload?.code) {
                // success
                state.resultCode = action.payload.code
                state.rentalContainer = action.payload.rentalContainer

            } else {
                if (action.payload?.quantity) {
                    state.quantity = action.payload.quantity
                }
                if (action.payload?.notifications) {
                    state.notifications = action.payload.notifications
                }
            }

            state.scanning = false

        })
        builder.addCase(setQuantity.fulfilled, (state, action) => {
            state.quantity = action.payload
        })
    },
})

export const {reset, setCustomerData} = eventDemandPageSlice.actions
export const eventDemandPageReducer = eventDemandPageSlice.reducer
