import React from "react";
import {Route, Switch} from "react-router";
import RouteEnum from "../../constants/RouteEnum";
import {connect} from "react-redux";
import translate from "../../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    environment: state.environment.data,
    authentication: state.authentication,
});

class AuthWrapper extends React.Component {

    render() {

        const props = this.props;
        const environment = props.environment;
        const timeOut = this.props.authentication.timeOut;

        return (
            <div className="c-auth">
                <div className="c-auth__intro">
                    <img src={environment.logo} alt="logo" className="c-auth__logo"/>
                    <h1>{environment.info.intro_title}</h1>
                    <span>{environment.info.intro_subtitle}</span>

                    <Switch>

                        <Route
                            exact
                            path={RouteEnum.PinForgot}
                            component={PinForgotText}
                        />

                        {!timeOut && <Route render={(props) => (<PinText {...props} text={environment.info.intro_text}/>)}/>}
                        {!!timeOut && <Route component={PinTimeOutText}/>}

                    </Switch>


                </div>

                {props.children}

            </div>
        )

    }

}

export default connect(mapStateToProps)(AuthWrapper);

const PinText = (props) => (
    <p>
        {props.text}
    </p>
);

const PinForgotText = () => (
    <p>
        {translate('pinForgotText')}
    </p>
);

const PinTimeOutText = () => (
    <p>
        {translate('pinTimeOutText')}
    </p>
);