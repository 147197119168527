import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {apiPartnerRentalList} from "../../../common/api/partner/rental/apiPartnerRentalList";
import {apiPartnerLocationList} from "../../../common/api/partner/location/apiPartnerLocationList";
import {apiPartnerRentalDeviationList} from "../../../common/api/partner/rental/apiPartnerRentalDeviationList";
import {cleanObject} from "../../../common/helpers/utils/cleanObject";

export const loadRentalOverviewPage = createAsyncThunk(
    'rentalOverviewPage/load',
    async (initial, {getState}) => {

        const {rentalOverviewPage} = getState();
        let rentalListResponse

        if (rentalOverviewPage.display === 'default') {
            rentalListResponse = await apiPartnerRentalList({
                page: rentalOverviewPage.activePage,
                limit: 12,
            }, {
                sort: rentalOverviewPage.sort,
                order_by: rentalOverviewPage.orderBy,
                sort_table: rentalOverviewPage.sortTable,
                filter: rentalOverviewPage.filters
            })
        } else {
            rentalListResponse = await apiPartnerRentalDeviationList({
                page: rentalOverviewPage.activePage,
                limit: 12,
            }, {
                sort: rentalOverviewPage.sort,
                order_by: rentalOverviewPage.orderBy,
                sort_table: rentalOverviewPage.sortTable,
                filter: rentalOverviewPage.filters
            })
        }

        if (initial) {

            const partnerLocationListResponse = await apiPartnerLocationList({clientLocations: true})

            return {
                rentalList: rentalListResponse.data.items?.data,
                pages: rentalListResponse.data.items?.pages,
                partnerLocations: partnerLocationListResponse.data.items?.locations
            }
        } else {
            return {
                rentalList: rentalListResponse.data.items?.data,
                pages: rentalListResponse.data.items?.pages
            }
        }
    }
)

const initialState = {
    loading: false,
    rentalList: [],
    activePage: 0,
    partnerLocations: [],
    filters: {},
    sort: 'ASC',
    display: 'default'
}

export const rentalOverviewPageSlice = createSlice({
    name: 'rentalOverviewPage',
    initialState,
    reducers: {

        changePage: (state, action) => {
            state.activePage = action.payload
        },

        setFilters: (state, action) => {
            state.activePage = 0
            state.filters = action.payload
            state.filters = cleanObject(state.filters)
        },

        toggleDisplay: (state) => {
            state.activePage = 0
            state.display === 'default' ? state.display = 'deviation' : state.display = 'default'
        },

        setOrderBy: (state, action) => {


            if (action.payload === 'last_name') {
                state.sortTable = 'private_persons'
            } else {
                delete state.sortTable
            }

            if (state.orderBy === action.payload) {
                state.sort === 'ASC' ? state.sort = 'DESC' : state.sort = 'ASC'
            } else {
                state.orderBy = action.payload
            }

        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadRentalOverviewPage.pending, (state) => {
            state.loading = true
        })
        builder.addCase(loadRentalOverviewPage.fulfilled, (state, action) => {
            state.rentalList = action.payload.rentalList
            state.pages = action.payload.pages
            state.loading = false

            if (action.payload.partnerLocations) {
                state.partnerLocations = action.payload.partnerLocations
            }
        })
    },
})

export const {changePage, setFilters, setOrderBy, toggleDisplay} = rentalOverviewPageSlice.actions

export const rentalOverviewPageReducer = rentalOverviewPageSlice.reducer