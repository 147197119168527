import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './rootReducer';

const rootStore = (initialState, history) => {

    return createStore(rootReducer(history), initialState, applyMiddleware(thunk));

};

export default rootStore