import RentalDeliveryAction from "./RentalDeliveryAction";

export default class RentalDeliveryReducer {

    static initialState = {
        delivery: {
            locationSet: false,
            enabled: false,
            deliveryPickupLocation: false,
            deliveryPickupSubLocation: false,
            subLocationNumber: false,
            partnerLocationId: false,
            type: false,
            custom: {
                street: '',
                number: '',
                zip_code: '',
                city: '',
                isCityId: false,
            },
            completed: false
        },
        locations: null,
        response: null,
        deliveryAddressValidation: false
    };

    static reducer(state = RentalDeliveryReducer.initialState, action) {

        switch (action.type) {

            case RentalDeliveryAction.SET_INITIAL_DATA + '_FINISHED':

                return {
                    ...state,
                    response: null
                };

            case RentalDeliveryAction.SET_DELIVERY_STATUS:
                return {
                    ...state,
                };

            case RentalDeliveryAction.SET_DELIVERY_STATUS + '_FINISHED':

                return {
                    ...state,

                    delivery: {
                        ...state.delivery,
                        enabled: action.payload
                    },
                };

            case RentalDeliveryAction.GET_DELIVERY_LOCATIONS:
                return {
                    ...state,
                };

            case RentalDeliveryAction.GET_DELIVERY_LOCATIONS + '_FINISHED':

                return {
                    ...state,
                    locations: action.payload
                };

            case RentalDeliveryAction.SET_DELIVERY_LOCATION:
                return {
                    ...state,
                };

            case RentalDeliveryAction.SET_DELIVERY_LOCATION + '_FINISHED':

                return {
                    ...state,
                    subDeliveryLocations: false,
                    delivery: {
                        ...state.delivery,
                        deliveryPickupLocation: action.payload.deliveryLocation,
                        deliveryPickupSubLocation: false,
                        subLocationNumber: false,
                        type: false,
                        partnerLocationId: false,
                        completed: action.payload.completed
                    }

                }

            case RentalDeliveryAction.SET_SUB_DELIVERY_LOCATION:
                return {
                    ...state,
                };

            case RentalDeliveryAction.SET_SUB_DELIVERY_LOCATION + '_FINISHED':
                return {
                    ...state,
                    delivery: {
                        ...state.delivery,
                        subLocationNumber: false,
                        deliveryPickupSubLocation: action.payload.deliverySubLocation,
                        completed: action.payload.completed
                    }

                }

            case RentalDeliveryAction.SET_SUB_LOCATION_NUMBER:
                return {
                    ...state,
                };

            case RentalDeliveryAction.SET_SUB_LOCATION_NUMBER + '_FINISHED':
                return {
                    ...state,
                    delivery: {
                        ...state.delivery,
                        subLocationNumber: action.payload,
                        completed: true
                    }

                }

            case RentalDeliveryAction.SET_CUSTOM_LOCATION_VALUE:

                return {
                    locationSet: true,
                    ...state,
                };

            case RentalDeliveryAction.SET_CUSTOM_LOCATION_VALUE + '_FINISHED':

                return {
                    ...state,
                    delivery: {
                        ...state.delivery,
                        custom: {
                            ...state.delivery.custom,
                            ...action.payload.value
                        },
                        completed: action.payload.completed
                    }
                }

            case RentalDeliveryAction.FORMAT_SUB_DELIVERY_LOCATIONS:
                return {
                    ...state,
                };

            case RentalDeliveryAction.FORMAT_SUB_DELIVERY_LOCATIONS + '_FINISHED':
                return {
                    ...state,
                    subDeliveryLocations: action.payload
                };

            case RentalDeliveryAction.SUBMIT_MUTATION:
                return {
                    ...state
                };

            case RentalDeliveryAction.SUBMIT_MUTATION + '_FINISHED':

                return {
                    ...state,
                    response: action.payload
                };

            case RentalDeliveryAction.VALIDATE_DELIVERY_BY_ADDRESS + '_FINISHED':

                return {
                    ...state,
                    deliveryAddressValidation: !!action.payload?.items?.available
                };

            case RentalDeliveryAction.RESET:
                return {
                    ...state
                };

            case RentalDeliveryAction.RESET + '_FINISHED':
                return {
                    ...RentalDeliveryReducer.initialState,
                    locations: state.locations
                };

            default:

                return state;

        }


    }
}