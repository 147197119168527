import React from "react";
import {Link} from "react-router-dom";
import RentalActionButtons from "../../../common/constants/RentalActionButtons";
import IsCurrentPartnerLocation from "../../../common/helpers/IsCurrentPartnerLocation";
import CheckDisplayModule from "../../../common/helpers/CheckDisplayModule";

const OrderActions = (props) => {

    const rentalActionButtons = RentalActionButtons(props)
    const isCurrentPartnerLocation = IsCurrentPartnerLocation(props)

    if (CheckDisplayModule('rentalMutations', props.environment, props.partnerUser) && rentalActionButtons && isCurrentPartnerLocation) {
        return (
            <div className="s-order-actions">

                {rentalActionButtons.filter(button => !button.hideOnStatus.includes(props.currentPhase)).map(button => {

                    let className = 'btn btn--small btn--icon'
                    let iconClassName = ''
                    if (button.icon) {
                        className += ' btn--icon'
                        iconClassName = 'icon ' + button.icon
                    }

                    return (
                        <Link to={button.link}
                              key={'action-button ' + button.text}
                              className={className}
                              style={{background: button.background}}>

                            {button.icon &&
                            <i className={iconClassName} style={{color: button.color}}/>
                            }
                            <span style={{color: button.color}}>{button.text}</span>
                        </Link>
                    )

                })}

            </div>
        )
    } else return null

}

export default OrderActions
