import React from 'react'
import {connect} from "react-redux";
import EnvironmentAction from "../../../stores/environment/EnvironmentAction";
import RouteEnum from "../../constants/RouteEnum";
import {withRouter} from "react-router";
import {setSearchBarValue, toggleSearchBar} from "../../../createSlice/features/pages/searchPage/searchPageSlice";
import translate from "../../../createSlice/common/helpers/utils/translate";

;

const mapStateToProps = (state) => ({
    ...state.searchPage,
    environment: state.environment
});

class SearchBar extends React.Component {

    componentDidUpdate() {
        if (this.props.showBar) {
            this.nameInput.focus();
        }
    }

    render() {

        let className = 'c-search-box'

        if (!this.props.searchBar.show) {
            className += ' is-hidden'
        }

        return (
            <form className={className} onSubmit={(e) => {
                this._submit(e)
            }}>
                <input data-hj-allow
                    ref={(input) => {
                        this.nameInput = input;
                    }}
                    onChange={(e) => {
                        this._inputChange(e)
                    }}
                    type="text"
                    value={this.props.searchBar.value}
                    placeholder={translate('searchRentalReservations')}

                />
                <div>
                    <button type={'submit'} className="btn btn--primary btn--icon"><i className="icon icon-search"/>
                        <span>{translate('search')}</span></button>
                    <button type={'button'} onClick={() => {
                        this._clickCross()
                    }} className="btn btn--tertiary btn--square"><i className="icon icon-cross"/></button>
                </div>
            </form>
        )

    }

    _clickCross = () => {
        this.props.dispatch(toggleSearchBar())
    }

    _inputChange = (e) => {
        this.props.dispatch(setSearchBarValue(e.target.value))
    }

    _submit = (e) => {

        e.preventDefault();

        this.props.history.push('/' + this.props.environment.slug + RouteEnum.Search + '/' + this.props.searchBar.value)
        this.props.dispatch(toggleSearchBar())


    }

}

export default connect(mapStateToProps)(withRouter(SearchBar));
