export const ToggleButton = (props) => {

    return (
            <label className="c-toggle-button">
                <input
                    type="checkbox"
                    defaultChecked={props.isToggledOn}
                    onChange={props.handleOnToggle}/>
                <span
                    className={"c-toggle-button__slider " + (props.isToggledOn ? "is-active" : "")}>
                        {props.isToggledOn ? "✓" : "Afwezig" }
                </span>
            </label>

    )

}