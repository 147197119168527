// Dependencies
import React from 'react';
import {connect} from "react-redux";

// Actions
import EnvironmentAction from "../../stores/environment/EnvironmentAction";
import RentalTotalsAction from "../../stores/rental/totals/RentalTotalsAction";

// Helpers
import ToTransactionCheck from "../../common/helpers/ToTransactionCheck";
import IsCurrentPartnerLocation from "../../common/helpers/IsCurrentPartnerLocation";

// Components
import ToDetailButton from "../../common/components/General/ToDetailButton";
import RentalBicycles from "../../common/components/Rental/RentalBicycles";
import RentalAccessoires from "../../common/components/Rental/RentalAccessoires";
import RentalCategoryAction from "../../stores/rental/category/RentalCategoryAction";
import RentalTotals from "../../common/components/Rental/RentalTotals";

// Dispatchers
import RentalDispatcher from "../../common/dispatchers/RentalDispatcher";
import Scanner from "../../common/components/General/Scanner";
import translate from "../../createSlice/common/helpers/utils/translate";
import {PinValidationWrapper} from "../../common/wrappers/PinValidationWrapper";

const mapStateToProps = (state) => ({
    rentalDetail: state.rentalDetail.data,
    rentalPeriod: state.rentalPeriod,
    environment: state.environment,
    rentalTotals: state.rentalTotals,
    ...state.rentalItems
});


class RentalItems extends React.Component {

    componentDidMount() {
        this._init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.rentalUuid !== this.props.match.params.rentalUuid) {
            this._init()
        }
    }

    _init = () => {
        this.props.resetAll()
        let rentalUuid = this.props.match.params.rentalUuid
        this.props.load(rentalUuid)
    }

    render() {

        if (this.props.rentalDetail && this.props.rentalPeriod.loadedFromInfo) {

            const isCurrentPartnerLocation = IsCurrentPartnerLocation(this.props)

            if (isCurrentPartnerLocation) {
                return (
                    <main>
                        <PinValidationWrapper>
                            <Scanner type={'rental'}/>
                            <div className="o-container o-container--primary">
                                <header className="c-subject-header c-subject-header--split">
                                    <h1>{translate('changeItems')}</h1>
                                    <div className="c-subject-header__buttons">
                                        <ToDetailButton history={this.props.history} slug={this.props.environment.slug}
                                                        uuid={this.props.rentalDetail.data.rental.uuid}/>
                                    </div>
                                </header>
                                <div className="s-order-detail">
                                    <div className="c-info-blocks c-form">

                                        <RentalBicycles loadFromInfo={true}/>
                                        <RentalAccessoires loadFromInfo={true}/>
                                        {this.props.rentalTotals.totals &&
                                        <RentalTotals data={this.props.rentalTotals.totals} hideInput={false}
                                                      useDetail={true}/>
                                        }

                                        <div className="c-form__row">
                                            <div className="c-form__group">
                                                <button onClick={() => this._toPayment()} type="submit"
                                                        className="btn btn--rounded btn--primary">
                                                    <span>{translate('agreeAndMakeChanges')}</span>
                                                </button>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </PinValidationWrapper>
                    </main>
                )
            } else return null
        } else return null

    }

    _toPayment = () => {

        this.props.dispatch(EnvironmentAction.setLoader(true)).then(() => {
            this.props.dispatch(RentalCategoryAction.submitMutation()).then(() => {
                if (this.props.response) {
                    this.props.dispatch(RentalTotalsAction.submitDeposit(this.props.rentalDetail.data.rental.uuid)).then(() => {
                        ToTransactionCheck(this.props)
                    })
                }
            })
        })

    }

}

export default connect(mapStateToProps, RentalDispatcher)(RentalItems);
