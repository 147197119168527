import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {apiPartnerLocationList} from "../../../common/api/partner/location/apiPartnerLocationList";
import {cleanObject} from "../../../common/helpers/utils/cleanObject";
import {apiClientRentalSearch} from "../../../common/api/client/rental/apiClientRentalSearch";

export const loadSearchPage = createAsyncThunk(
    'searchPage/load',
    async (initial, {getState}) => {

        const {searchPage} = getState()

        const rentalSearchResponse = await apiClientRentalSearch({
            page: searchPage.activePage,
            limit: 12
        }, {
            sort: searchPage.sort,
            order_by: searchPage.orderBy,
            sort_table: searchPage.sortTable,
            filter: searchPage.filters,
            value: searchPage.searchValue
        })

        if (initial) {

            const partnerLocationListResponse = await apiPartnerLocationList({clientLocations: true})

            return {
                rentalList: rentalSearchResponse.data.items?.data,
                pages: rentalSearchResponse.data.items?.pages,
                partnerLocations: partnerLocationListResponse.data.items?.locations
            }

        } else {
            return {
                rentalList: rentalSearchResponse.data.items?.data,
                pages: rentalSearchResponse.data.items?.pages
            }
        }
    }
)

const initialState = {
    loading: false,
    rentalList: [],
    activePage: 0,
    searchValue: '',
    partnerLocations: [],
    filters: {
        phase: 'note'
    },
    sort: 'ASC',
    searchBar: { // only in jsx
        value: '',
        show: false
    }
}

export const searchPageSlice = createSlice({
    name: 'searchPage',
    initialState,
    reducers: {
        changePage: (state, action) => {
            state.activePage = action.payload
        },
        setSearchValue: (state, action) => {
            state.activePage = 0
            state.searchValue = action.payload
        },
        setFilters: (state, action) => {
            state.activePage = 0
            state.filters = action.payload
            state.filters = cleanObject(state.filters)
        },
        toggleSearchBar: (state, action) => {
            state.searchBar.show = !state.searchBar.show
        },
        setSearchBarValue: (state, action) => {
            state.searchBar.value = action.payload
        },
        setOrderBy: (state, action) => {

            if (action.payload === 'last_name') {
                state.sortTable = 'private_persons'
            } else {
                delete state.sortTable
            }

            if (state.orderBy === action.payload) {
                state.sort === 'ASC' ? state.sort = 'DESC' : state.sort = 'ASC'
            } else {
                state.orderBy = action.payload
            }

        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadSearchPage.pending, (state) => {
            state.loading = true
        })
        builder.addCase(loadSearchPage.fulfilled, (state, action) => {
            state.rentalList = action.payload.rentalList
            state.pages = action.payload.pages
            state.loading = false

            if (action.payload.partnerLocations) {
                state.partnerLocations = action.payload.partnerLocations
            }
        })
    },
})

export const {
    changePage,
    setFilters,
    setSearchValue,
    toggleSearchBar,
    setSearchBarValue,
    setOrderBy
} = searchPageSlice.actions

export const searchPageReducer = searchPageSlice.reducer