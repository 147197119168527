import fetchHelper from "../../../common/helpers/fetchHelper";

export default class RentalDeliveryEffect {


    static setDeliveryStatus(value) {
        return parseInt(value)
    }

    static setInitialData() {
        return true
    }


    static async validateDeliveryByAddress(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response
        } else return null

    }



    static async submitMutation(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response
        } else return null

    }

    static async getDeliveryAddressList(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response.items.deliveryAddresses
        } else return null

    }

    static async formatSubDeliveryLocations(subDeliveryLocations) {

        const returnItems = []

        Object.keys(subDeliveryLocations).forEach(itemKey => {
            const formattedSubItem = subDeliveryLocations[itemKey]
            formattedSubItem.numbers = []

            if (!formattedSubItem.name) {
                formattedSubItem.name = ''
            }

            formattedSubItem.value = itemKey

            if (formattedSubItem.type === 'range') {

                if (formattedSubItem.type === 'range') {
                    const start = parseInt(formattedSubItem.range_start)
                    const end = parseInt(formattedSubItem.range_end)
                    let increaseNumber

                    if (formattedSubItem.range_side === 'ascending') {
                        increaseNumber = 1
                    } else {
                        increaseNumber = 2
                    }

                    for (let i = start; i < end; i += increaseNumber) {
                        const number = {}
                        number.value = i
                        number.label = i
                        formattedSubItem.numbers.push(number)
                    }
                }

            } else {
                if (formattedSubItem.number) {
                    const number = {};
                    number.value = formattedSubItem.number
                    number.label = formattedSubItem.number
                    formattedSubItem.numbers.push(number)
                }
            }

            const numberArray = Object.values(formattedSubItem.numbers)

            if (numberArray.length > 1) {
                formattedSubItem.label = formattedSubItem.name + ' ' + formattedSubItem.street + ' ' + numberArray[0]?.label + '-' + numberArray[numberArray.length - 1]?.label
            } else {
                formattedSubItem.label = formattedSubItem.name + ' ' + formattedSubItem.street + ' ' + numberArray[0]?.label
            }

            returnItems.push(formattedSubItem)
        })

        return returnItems.sort((a, b) => a.label.localeCompare(b.label))

    }

    static setDeliveryLocation(deliveryLocation) {

        const values = {
            deliveryLocation: deliveryLocation,
            completed: false
        }

        if ((deliveryLocation.items && !deliveryLocation.items.length) || deliveryLocation.value === 'none') {
            values.completed = true
        }

        return values;

    }

    static setSubDeliveryLocation(deliverySubLocation) {

        const values = {
            deliverySubLocation: deliverySubLocation,
            completed: false
        }

        if (deliverySubLocation.numbers && deliverySubLocation.numbers.length < 2) {
            values.completed = true
        }

        return values;
    }

    static setSubLocationNumber(number) {
        return number;
    }

    static reset() {
        return true;
    }


    static setCustomLocationValue(values) {

        values = {
            ...values,
            completed: true
        }

        // Checking if al fields are completed, including the current one
        Object.keys(values.current).forEach((key) => {
            const currentValue = values.current[key]

            if (((currentValue === null || currentValue === '') && (!values.value[key])) || (values.value[key] === '')) {
                values.completed = false
            }

        })

        return values
    }


}

