import RentalTotalsAction from "../../stores/rental/totals/RentalTotalsAction";
import RentalCategoryAction from "../../stores/rental/category/RentalCategoryAction";

const ChangeAccessoireNumber = (data, direction, dispatch) => {

    const values = {
        quantity: data.quantity + direction,
        id: data.id,
        available: data.available
    }

    // Mutating the number
    dispatch(RentalCategoryAction.mutateAccessoireSelectionNumber(values)).then(() => {
        dispatch(RentalCategoryAction.updateCart()).then(() => {
            dispatch(RentalTotalsAction.requestTotals())
        })
    })

}

export default ChangeAccessoireNumber