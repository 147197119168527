import {apiEnum} from "../../../../enums/apiEnum";
import {useAppAxios} from "../../../../../app/hooks";

export const apiPartnerActivityParticipationList = async (params) => {

    return await useAppAxios.post(apiEnum.PARTNER + `/item/participation-list`,
        {}, {
        params: {
            ...params,
        }
    }).then(function (response) {
        if (!response.data.items) {
            response.data.items = []
        }
        return response
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}
