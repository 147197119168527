import RentalItemsAction from "./RentalCategoryAction";

export default class RentalCategoryReducer {

    static initialState = {
        bicycles: {},
        bicyclesLoading: false,
        accessoiresLoading: false,
        loadedBicyclesFromInfo: false,
        accessoires: {},
        loadedAccessoiresFromInfo: false,
        shoppingCart: {
            totalPrice: 0,
            bicyclePrice: 0,
            bicycles: [],
            accessoirePrice: 0,
            accessoires: []
        },
        response: null
    }


    static reducer(state = RentalCategoryReducer.initialState, action) {


        switch (action.type) {

            case RentalItemsAction.GET_AVAILABLE_BICYCLES:

                return {
                    ...state,
                    bicycles: {},
                    bicyclesLoading: true,
                    shoppingCart: {
                        totalPrice: 0,
                        bicyclePrice: 0,
                        bicycles: [],
                        accessoirePrice: 0,
                        accessoires: []
                    },
                };

            case RentalItemsAction.GET_AVAILABLE_BICYCLES + '_FINISHED':

                if (action.payload) {
                    return {
                        ...state,
                        bicycles: action.payload.items,
                        loadedBicyclesFromInfo: true,
                        bicyclesLoading: false,
                    }
                } else {
                    return {
                        ...state,
                        bicyclesLoading: false,
                    }
                }

            case RentalItemsAction.LOAD_BICYCLE_SELECTION_FROM_INFO:

                return {
                    ...state,
                };

            case RentalItemsAction.LOAD_BICYCLE_SELECTION_FROM_INFO + '_FINISHED':

                return {
                    ...state,
                    bicycles: {
                        ...state.bicycles,
                        ...action.payload,
                    },
                }

            case RentalItemsAction.LOAD_ACCESSOIRE_SELECTION_FROM_INFO:

                return {
                    ...state,
                };

            case RentalItemsAction.LOAD_ACCESSOIRE_SELECTION_FROM_INFO + '_FINISHED':

                return {
                    ...state,
                    accessoires: {
                        ...state.accessoires,
                        ...action.payload,
                    },
                }

            case RentalItemsAction.GET_AVAILABLE_ACCESSOIRES:

                return {
                    ...state,
                    accessoires: {},
                    shoppingCart: {
                        totalPrice: 0,
                        bicyclePrice: 0,
                        bicycles: [],
                        accessoirePrice: 0,
                        accessoires: []
                    },
                    accessoiresLoading: true,
                };

            case RentalItemsAction.GET_AVAILABLE_ACCESSOIRES + '_FINISHED':

                if (action.payload) {
                    return {
                        ...state,
                        accessoires: action.payload.items,
                        accessoiresLoading: false
                    }
                } else return {
                    ...state,
                    accessoiresLoading: false
                }

            case RentalItemsAction.UPDATE_CART:

                return {
                    ...state,
                };

            case RentalItemsAction.UPDATE_CART + '_FINISHED':

                return {
                    ...state,
                    shoppingCart: action.payload
                }

            case RentalItemsAction.EMPTY_CART:
                return {
                    ...state,
                };

            case RentalItemsAction.EMPTY_CART + '_FINISHED':
                return {
                    ...state,
                    shoppingCart: {
                        totalPrice: 0,
                        bicyclePrice: 0,
                        bicycles: [],
                        accessoires: []
                    },
                }

            case RentalItemsAction.MUTATE_BICYCLE_SELECTION_NUMBER:
                return {
                    ...state,
                };

            case RentalItemsAction.MUTATE_BICYCLE_SELECTION_NUMBER + '_FINISHED':

                return {
                    ...state,
                    bicycles: {
                        ...state.bicycles,
                        [action.payload.id]: {
                            ...state.bicycles[action.payload.id],
                            quantity: action.payload.quantity
                        }
                    }
                };

            case RentalItemsAction.MUTATE_ACCESSOIRE_SELECTION_NUMBER:
                return {
                    ...state,
                };

            case RentalItemsAction.MUTATE_ACCESSOIRE_SELECTION_NUMBER + '_FINISHED':

                return {
                    ...state,
                    accessoires: {
                        ...state.accessoires,
                        [action.payload.id]: {
                            ...state.accessoires[action.payload.id],
                            quantity: action.payload.quantity
                        }
                    }
                };



            case RentalItemsAction.RESET + '_FINISHED':

                return {
                    ...RentalCategoryReducer.initialState,
                };

            case RentalItemsAction.SUBMIT_MUTATION:

                return {
                    ...state,
                    response: null
                };

            case RentalItemsAction.SUBMIT_MUTATION + '_FINISHED':

                return {
                    ...RentalCategoryReducer.initialState,
                    response: action.payload
                };

            default:
                return state
        }
    }
}