import data from '../static/data/redirects.json'


export const reformatOldSlugs = (slug) => {

    const redirectData = data.find(translateString => translateString["Old URL"] === slug)

    if (redirectData) {
        return redirectData['New URL']
    } else return slug

}

