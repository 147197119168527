// Dependencies
import React from 'react'
import ReactDOM from 'react-dom';
import {connect} from "react-redux";

// Actions
import RentalDetailAction from "../../stores/rental/detail/RentalDetailAction";
import UserAction from "../../stores/user/UserAction";

import RentalCustomer from "./components/RentalCustomer";
import RentalTotals from "../../common/components/Rental/RentalTotals";
import RentalMutations from "./components/RentalMutations";
import RentalCompany from "../../common/components/Rental/RentalCompany";
import OrderActions from "./components/OrderActions";
import TinyLoader from "../../common/components/General/TinyLoader";
import RentalItems from "./components/RentalItems";
import RentalDetailHeader from "./components/Header";
import RentalPdf from "../../common/components/Rental/RentalPdf";
import DifferentLocationAlert from "./components/DifferentLocationAlert";

// Helpers
import IsCurrentPartnerLocation from "../../common/helpers/IsCurrentPartnerLocation";

// Dispatchers
import RentalDispatcher from "../../common/dispatchers/RentalDispatcher";
import RentalData from "./components/RentalData";
import RentalTransactions from "./components/RentalTransactions";
import Scanner from "../../common/components/General/Scanner";

const mapStateToProps = (state) => ({
    identificationRequired: state.rentalDetail.identificationRequired,
    rentalDetail: state.rentalDetail.data,
    loading: state.rentalDetail.loading,
    mutations: state.rentalDetail.mutations,
    user: state.user.detail,
    environment: state.environment,
    transaction: state.rentalDetail.transactions,
    mail: state.rentalDetail.mail,
    paymentMail: state.rentalDetail.paymentMail,
    rentalTotals: state.rentalTotals,
    rentalIssue: state.rentalIssue,
    partnerUser: state.authentication.partnerUser,
    rentalNotation: state.environment.data.rental_notation
});

class Index extends React.Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.rentalUuid !== this.props.match.params.rentalUuid) {
            this._init()
        }
    }

    componentDidMount() {
        this._init()
    }

    _init = () => {
        let rentalUuid = this.props.match.params.rentalUuid

        this.props.resetAll()
        this.props.loadMin(rentalUuid)
    }

    render() {

        const data = this.props.rentalDetail

        const rentalUuid = this.props.match ? this.props.match.params.rentalUuid : false
        const {transaction, rentalDetail, loading, rentalTotals} = this.props

        if (transaction && data && !loading && rentalDetail.data) {

            const currentPhase = rentalDetail.data.bookingPhase.code
            const isCurrentPartnerLocation = IsCurrentPartnerLocation(this.props)

            return (
                <main>

                    <Scanner type={'rental'}/>

                    <DifferentLocationAlert/>

                    <div className="o-container o-container--primary">

                        <RentalDetailHeader
                            {...this.props}
                            transaction={transaction}
                            sendMail={this._sendMail}
                            sendPaymentMail={this._sendPaymentMail}
                            generatePdf={this._generatePdf}
                            rentalUuid={rentalUuid}
                            currentPhase={currentPhase}
                        />


                        <OrderActions{...this.props} rentalUuid={rentalUuid} currentPhase={currentPhase}/>

                        <div className="s-order-detail">

                            <div className="c-info-blocks">

                                <div className="c-info-blocks__row c-info-blocks__row--half">
                                    <RentalData{...this.props}/>
                                    <RentalCustomer{...this.props}/>
                                    <RentalCompany{...this.props}/>
                                </div>

                                <RentalItems/>

                                {isCurrentPartnerLocation && this.props.rentalDetail.RentalItems &&
                                <RentalTotals hideInput={true} overrideItems={this.props.rentalDetail.RentalItems}
                                              useDetail={true}/>}

                                <RentalMutations{...this.props}/>
                                <RentalTransactions {...this.props}/>

                            </div>
                        </div>
                    </div>
                </main>
            )
        } else {
            return <TinyLoader/>;
        }
    }

    _sendMail = () => {
        this.props.dispatch(RentalDetailAction.sendMail())
    }

    _sendPaymentMail = () => {
        this.props.dispatch(RentalDetailAction.sendPaymentMail())
    }

    _generatePdf = () => {
        ReactDOM.render(<RentalPdf/>, document.getElementById('root'));
    }


}

export default connect(mapStateToProps, RentalDispatcher)(Index);
