import {RentalTable} from "../../../common/components/RentalTable";
import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {changePage, setFilters, loadSearchPage, setSearchValue, setOrderBy} from "./searchPageSlice";
import {RentalFilter} from "../../../common/components/RentalFilter";
import TinyLoader from "../../../../common/components/General/TinyLoader";
import {useParams} from "react-router-dom";
import Scanner from "../../../../common/components/General/Scanner";
import translate from "../../../common/helpers/utils/translate";

export const SearchPageContainer = () => {

    const dispatch = useAppDispatch()
    const {
        rentalList,
        pages,
        filters,
        partnerLocations,
        loading,
        searchValue,
        orderBy,
        sort
    } = useAppSelector((state) => state.searchPage)

    const {searchString} = useParams();

    useEffect(() => {
        dispatch(setSearchValue(searchString + ''))
        dispatch(loadSearchPage(true))
    }, [])

    useEffect(() => {
        dispatch(loadSearchPage(false))
    }, [filters, orderBy, sort])

    return (
        <div className={'o-container o-container--primary s-rental-overview'}>

            <Scanner type={'rental'}/>

            <header className={'c-subject-header  c-subject-header--split'}>
                <h1>{translate('searchResults')}</h1>
            </header>

            <form className="c-search-box s-dashboard-search" onSubmit={(e) => {
                e.preventDefault()
                dispatch(loadSearchPage(false))
            }}>

                <input required={true} type="text" placeholder={translate('searchRentalReservations')}
                       value={searchValue}
                       onChange={(e) => dispatch(setSearchValue(e.target.value + ''))}
                />

                <div>
                    <button className="btn btn--primary btn--icon">
                        <i className="icon icon-search"/>
                        <span>{translate('search')}</span>
                    </button>
                </div>

            </form>

            {!!pages &&
            <div className="c-search-results__count">
                <small>{translate('total')} {pages.total} {translate('pagesOfResults')}</small>
            </div>
            }

            <div className="c-Rental-overview">

                <RentalFilter
                    filters={filters}
                    partnerLocations={partnerLocations}
                    onChange={{
                        phase: value => dispatch(setFilters({
                            ...filters,
                            phase: value
                        })),
                        startDate: value => dispatch(setFilters({
                            ...filters,
                            rentStart: {
                                ...filters.rentStart,
                                from: value
                            }
                        })),
                        endDate: value => dispatch(setFilters({
                            ...filters,
                            rentStart: {
                                ...filters.rentStart,
                                to: value
                            }
                        })),
                        partnerLocation: value => dispatch(setFilters({
                            ...filters,
                            partnerLocationId: value
                        })),
                        openPayment: value => {

                            if (value) {
                                dispatch(setFilters({
                                    ...filters,
                                    openPayment: value
                                }))
                            } else {
                                dispatch(setFilters({
                                    ...filters,
                                    openPayment: ''
                                }))
                            }

                        },
                        items: value => dispatch(setFilters({
                            ...filters,
                            items: value
                        })),
                    }}
                />

                {!loading &&
                <RentalTable
                    rentals={rentalList}
                    pagination={{
                        pages: pages,
                        onChangePage: selected => {
                            dispatch(changePage(selected))
                            dispatch(loadSearchPage(false))
                        }
                    }}
                    setOrderBy={(value) => {
                        dispatch(setOrderBy(value))
                    }}
                />
                }

                {loading &&
                <div className={'c-part-loader'}>
                    <TinyLoader/>
                </div>
                }

            </div>

        </div>
    )

}