import {BaseModel} from 'sjs-base-model';

export default class CustomerModel extends BaseModel {
    id = null
    type = null
    data = {}
    addresses = null

    constructor(data) {
        super();
        this.update(data);
    }

    update(data) {

        this.id = data.id
        this.addresses = data.addresses

        this.data = {
            name: this.formatCustomerName(data),
            ...data
        }
    }

    formatCustomerName = (customer) => {

        let customerName = ''

        if(customer.first_name){
            customerName = customer.first_name
        }

        if (customer.insertion && customer.insertion !== '') {
            customerName += ' ' + customer.insertion
        }

        customerName += ' ' + customer.last_name

        return customerName

    }

}
