const FormatItems = (data) => {

    const shoppingCart = data.shoppingCart

    if (data.items) {
        
        Object.keys(data.items).forEach((itemId) => {

            data.items[itemId].quantity = 0

            // Checking if the item is already added to the shopping cart
            shoppingCart.bicycles.forEach((bicyle) => {
                if (bicyle.id === parseInt(itemId)) {
                    data.items[itemId].quantity = bicyle.quantity
                }
            })

            shoppingCart.accessoires.forEach((accessoire) => {
                if (accessoire.id === parseInt(itemId)) {
                    data.items[itemId].quantity = accessoire.quantity
                }
            })

        })

        return data.items

    } else {
        return {}
    }

}

export default FormatItems