import React from "react";
import {connect} from "react-redux";
import RentalDispatcher from "../../../common/dispatchers/RentalDispatcher";
import RentalIssueItemsAction from "../../../stores/rental/issueItems/RentalIssueItemsAction";

const mapStateToProps = (state) => ({
    rentalDetail: state.rentalDetail.data,
    ...state.rentalIssue
});

class RentalIssueRemarks extends React.Component {

    render() {

        return null /**(
         <div className="c-order-handling__comment">
         <label htmlFor="depositChoice" className="c-form__checkbox">
         <input data-hj-allow onChange={(e) => this._onCheck(e)} type="checkbox"
         id="depositChoice" name="baggage"/>
         <span>Opmerking toevoegen</span>
         </label>
         <figure/>

         {this.props.remarks.show &&
                <div className="c-form__row">
                    <div className="c-form__group">
                        <label htmlFor="">Opmerking</label>
                        <textarea
                            onChange={(e) => this.props.dispatch(RentalIssueAction.changeRemarks(e.target.value))}
                            value={this.props.remarks.value} name="customer.message" id="" cols="30" rows="10"
                        />
                    </div>
                </div>

                }
         </div>
         ) */
    }


    _onCheck = (e) => {
        this.props.dispatch(RentalIssueItemsAction.toggleRemarks(e.target.checked))
    }

}

export default connect(mapStateToProps, RentalDispatcher)(RentalIssueRemarks);
