import fetchHelper from "../../common/helpers/fetchHelper";

export default class PaymentEffect {

    static async requestStatusList(values, dispatch) {

        const response = await fetchHelper( values, dispatch)

        if (response) {
            return {
                response: response.items,
            }
        }
    }

    static async requestMethodList(values, dispatch) {

        const response = await fetchHelper( values, dispatch)

        if (response) {
            return {
                response: response.items,
            }
        }

    }

}

