export const routeEnum = {
    LOGIN: process.env.PUBLIC_URL + '',
    PIN: process.env.PUBLIC_URL + '',
    PIN_FORGOT: process.env.PUBLIC_URL + 'pin/forgot',
    PIN_TIME_OUT: process.env.PUBLIC_URL + 'pin/time-out',
    DASHBOARD: process.env.PUBLIC_URL + 'dashboard',
    SEARCH: process.env.PUBLIC_URL + 'search',
    SCAN_ITEMS: process.env.PUBLIC_URL + 'scan-items',
    AVAILABILITY: process.env.PUBLIC_URL + 'availability',
    SETTINGS: process.env.PUBLIC_URL + 'settings',
    STOCK: process.env.PUBLIC_URL + 'stock',
    ITEMS_LATE: process.env.PUBLIC_URL + 'items-too-late',
    TRANSACTION_OVERVIEW: process.env.PUBLIC_URL + 'transacties',
    PRODUCT_RENTALS: process.env.PUBLIC_URL + 'product/rentals',
    PRODUCT_INFO: process.env.PUBLIC_URL + 'product',
    RENTAL: process.env.PUBLIC_URL + 'rental',
    RENTAL_OVERVIEW: process.env.PUBLIC_URL + 'rental/overview',
    RENTAL_GIVE_ITEMS: process.env.PUBLIC_URL + 'rental/give-items',
    RENTAL_RETURN_ITEMS: process.env.PUBLIC_URL + 'rental/return-items',
    RENTAL_EXCHANGE_ITEMS: process.env.PUBLIC_URL + 'rental/exchange-items',
    RENTAL_CREATE: process.env.PUBLIC_URL + 'rental/create',
    RENTAL_PAYMENT: process.env.PUBLIC_URL + 'rental/create/payment',
    RENTAL_PAYMENT_RESULT: process.env.PUBLIC_URL + 'rental/create/payment/result',
    RENTAL_EXTEND: process.env.PUBLIC_URL + 'Rental/extend',
    RENTAL_PERIOD: process.env.PUBLIC_URL + 'rental/period',
    RENTAL_ITEMS: process.env.PUBLIC_URL + 'rental/items',
    RENTAL_CUSTOMER: process.env.PUBLIC_URL + 'rental/customer',
    RENTAL_DELIVERY: process.env.PUBLIC_URL + 'rental/delivery',
    RENTAL_CANCELLATION: process.env.PUBLIC_URL + 'rental/cancel',
}

