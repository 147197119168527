import RentalDetailAction from "./RentalDetailAction";

export default class RentalDetailReducer {

    static initialState = {
        mutations: {},
        transactions: [],
        mail: {
            response: null,
            loading: null // was isMailing
        },
        paymentMail: {
            response: null,
            loading: null // was isMailing
        },
        loading: false,
        data: false,
    };

    static reducer(state = RentalDetailReducer.initialState, action) {

        switch (action.type) {

            case RentalDetailAction.REQUEST_DETAIL:
                return {
                    ...state,
                    ...state.detail
                };

            case RentalDetailAction.REQUEST_DETAIL + '_FINISHED':

                return {
                    ...state,
                    data: {
                        ...action.payload.data,
                    },
                }


            case RentalDetailAction.REQUEST_MUTATIONS + '_FINISHED':

                return {
                    ...state,
                    mutations: action.payload
                };


            case RentalDetailAction.REQUEST_TRANSACTIONS + '_FINISHED':

                return {
                    ...state,
                    transactions: action.payload
                };


            case RentalDetailAction.SEND_MAIL:

                return {
                    ...state,
                    mail: {
                        loading: true,
                        response: null
                    },
                };

            case RentalDetailAction.SEND_MAIL + '_FINISHED':

                if (action.payload) {
                    return {
                        ...state,
                        mail: {
                            loading: false,
                            response: action.payload
                        },

                    }
                } else {
                    return {...state}
                }

            case RentalDetailAction.SEND_PAYMENT_MAIL + '_FINISHED':

                if (action.payload) {
                    return {
                        ...state,
                        paymentMail: {
                            loading: false,
                            response: action.payload
                        },

                    }
                } else {
                    return {...state}
                }


            case RentalDetailAction.SET_LOADING + '_FINISHED':

                return {
                    ...state,
                    loading: action.payload
                }


            case RentalDetailAction.RESET + '_FINISHED':
                if (action.payload) {
                    return {
                        ...RentalDetailReducer.initialState,
                    }
                } else {
                    return {...state}
                }


            default:
                return state;
        }


    }
}