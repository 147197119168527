import React from 'react'
import {connect} from "react-redux";

const mapStateToProps = (state) => ({
    ...state.rentalReturn
});

class StatusCount extends React.Component{

    render() {

        return(
            <div className="c-order-handling__number">
                <span>{this.props.status.correctCount}</span>
                {!!this.props.status.completed &&
                <div className="c-order-handling__number--check"><i
                    className="icon icon-check"/></div>
                }
                {!!this.props.status.errors.length &&
                <div className="c-order-handling__number__warning"><i
                    className="icon icon-info"/></div>
                }
            </div>
        )

    }

}

export default connect(mapStateToProps)(StatusCount);
