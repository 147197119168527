import FormatItems from "../../../common/helpers/FormatItems";
import fetchHelper from "../../../common/helpers/fetchHelper";

export default class RentalCategoryEffect {

    static updateCart(values) {

        const shoppingCart = {
            bicycles: [],
            accessoires: [],
            totalPrice: 0,
            bicyclePrice: 0,
            accessoirePrice: 0
        }


        if (values.bicycles) {
            values.bicycles = Object.keys(values.bicycles).forEach((key) => {
                if (values.bicycles[key].quantity > 0) {
                    shoppingCart.bicycles.push(values.bicycles[key])
                    shoppingCart.totalPrice += values.bicycles[key].quantity * values.bicycles[key].price
                    shoppingCart.bicyclePrice += values.bicycles[key].quantity * values.bicycles[key].price

                }
            });
        }

        if (values.accessoires) {
            values.accessoires = Object.keys(values.accessoires).forEach((key) => {
                if (values.accessoires[key].quantity > 0) {
                    shoppingCart.accessoires.push(values.accessoires[key])
                    shoppingCart.totalPrice += values.accessoires[key].quantity * values.accessoires[key].price
                    shoppingCart.accessoirePrice += values.accessoires[key].quantity * values.accessoires[key].price
                }
            });
        }

        return shoppingCart

    }

    static async emptyCart() {
        return true;
    }

    static mutateSelectionNumber(values) {

        let maxNumber = values.available
        values.excludedLocations = []

        // Don't allow smaller values than 0
        if (values.quantity < 0) {
            values.quantity = 0
        }

        // Dont allow the number to be bigger than the max availability
        if (values.quantity > maxNumber) {
            values.quantity = maxNumber
        }

        return values

    }


    static loadQuantityFromInfo(data) {

        // Setting the quantity
        const rentalItems = {...data.rentalItems}
        let returnItems = {}

        Object.values(rentalItems).forEach((rentalItem) => {
            if (data.items[rentalItem.category_id]) {

                returnItems = {
                    ...returnItems,

                    [rentalItem.category_id]: {
                        ...data.items[rentalItem.category_id],
                        quantity: rentalItem.quantity,
                        available: data.items[rentalItem.category_id].available
                    }

                }

            }

        })

        return returnItems;
    }

    static async getAvailableBicycles(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            const data = {}
            data.items = response.items
            data.shoppingCart = values.shoppingCart
            data.type = 'bicycles'

            return {
                items: FormatItems(data),
            }
        } else return null

    }

    static async getAvailableAccessoires(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            const data = {}
            data.items = response.items
            data.shoppingCart = values.shoppingCart
            data.type = 'accessoire'

            return {
                items: FormatItems(data),
            }
        } else return null
    }

    static async reset() {
        return true;
    }

    static async submitMutation(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response
        } else {
            return null
        }

    }

}