import React from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router";
import BarcodeReader from 'react-barcode-reader'
import ScanDispatcher from "../../dispatchers/ScanDispatcher";
import EnvironmentAction from "../../../stores/environment/EnvironmentAction";
import translate from "../../../createSlice/common/helpers/utils/translate";
import GetContrast from "../../helpers/GetContrast";

const mapStateToProps = (state) => ({
    ...state
});

class Scanner extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tempVal: ''
        }
    }

    handleScan = (data) => {
        this.props.scan(this.props.type, data, this.props.history, this.props.environment, this.props.activeDashboardButton, true)
    }

    handleError = (err) => {
        console.error(err)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.location !== this.props.location) {
            this._checkScannerType()
        }
    }

    componentDidMount() {
        this._checkScannerType()
    }

    componentWillUnmount() {
        this.props.dispatch(EnvironmentAction.enableRentalScanner(true))
    }

    _checkScannerType = () => {
        this.props.dispatch(EnvironmentAction.enableRentalScanner(true))

        if (this.props.type !== 'rental') {
            this.props.dispatch(EnvironmentAction.enableRentalScanner(false))
        }
    }

    render() {
        if (this.props.type !== 'rental' || this.props.environment.rentalScannerEnabled) {

            return (
                <>
                    <BarcodeReader
                        minLength={1}
                        onError={this.handleError}
                        onScan={this.handleScan}/>

                    <form style={{display: 'none'}} className={'c-custom-input'} onSubmit={e => {
                        e.preventDefault()
                        this.handleScan(this.state.tempVal)}
                    }>

                        <input data-hj-allow
                               type={'text'}
                               value={this.state.tempVal}
                               onChange={(e) => this.setState({tempVal: e.target.value})}
                               placeholder={translate('customInput')}
                        />

                        <button type={'submit'} style={{backgroundColor: 'green'}}>
                            <i style={{color: GetContrast('green')}} className={'icon icon-arrow-right'}/>
                        </button>

                    </form>
                </>)

        } else {
            return null
        }
    }
}

export default connect(mapStateToProps, ScanDispatcher)(withRouter(Scanner));