// Dependencies
import React from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";

// Constants
import RouteEnum from "../../common/constants/RouteEnum";

// Components
import Scanner from "../../common/components/General/Scanner";
import DashboardButtons from "./components/DashboardButtons";
import TableFilter from "./components/TableFilter";
import RentalTable from "./components/RentalTable";
import CategoryTable from "./components/CategoryTable";
import DashboardDispatcher from "../../common/dispatchers/DashboardDispatcher";
import translate from "../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    ...state.dashboard,
    partnerUser: state.authentication.partnerUser,
    environment: state.environment,
    rentalReturn: state.rentalReturn,
    rentalNotation: state.environment.data.rental_notation
});

class DashboardOverview extends React.Component {

    componentDidMount() {
        this.props.load()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.rentalReturn.scannedItem !== this.props.rentalReturn.scannedItem && this.props.rentalReturn.scannedItem) {

            if (this.props.rentalReturn.scannedItem.rental) {

                if (this.props.rentalDashboardButtons.active) {

                    if (this.props.rentalDashboardButtons.active === 'return') {
                        this.props.history.push('/' + this.props.environment.slug + RouteEnum.RentalReturnItems + '/' + this.props.rentalReturn.scannedItem.rental.uuid)
                    }

                    if (this.props.rentalDashboardButtons.active === 'swap') {

                        this.props.history.push({
                            pathname: '/' + this.props.environment.slug + RouteEnum.RentalSwapItems + '/' + this.props.rentalReturn.scannedItem.rental.uuid,
                            state: {
                                code: this.props.rentalReturn.scannedItem.product_code
                            }
                        })
                    }

                    if (this.props.rentalDashboardButtons.active === 'period') {
                        this.props.history.push('/' + this.props.environment.slug + RouteEnum.RentalPeriod + '/' + this.props.rentalReturn.scannedItem.rental.uuid)
                    }

                } else {
                    this.props.history.push('/' + this.props.environment.slug + RouteEnum.PRODUCT_INFO + '/' + this.props.rentalReturn.scannedItem.id)
                }

            } else {
                this.props.history.push('/' + this.props.environment.slug + RouteEnum.PRODUCT_INFO + '/' + this.props.rentalReturn.scannedItem.id)
            }
        }
    }

    render() {

        const showScanner = this.props.rentalDashboardButtons.active === 'return' || 'swap'
        const {display, environment} = this.props
        const {primary_color_dark} = environment.data.info

        return (
            <main>

                {showScanner &&
                <Scanner type={'dashboard'} activeDashboardButton={this.props.rentalDashboardButtons.active}/>}

                <div className={'o-container o-container--primary'}>

                    <header className="c-subject-header"><h1>Dashboard</h1></header>

                    <DashboardButtons {...this.props} />

                    <div className="s-dashboard-table">

                        <header className="c-subject-header c-subject-header--closed c-subject-header--split">
                            <h2>{translate('rentalsAndReturns')}</h2>

                            <Link
                                to={'/' + environment.slug + RouteEnum.Rental}
                                style={{background: primary_color_dark}}
                                className="btn btn--primary"
                            >

                                <span>{translate('allRentals')}</span>

                            </Link>

                        </header>

                        <TableFilter {...this.props}/>

                        {["reservation", "registration", "return", "late"].includes(display.active) &&
                        <RentalTable {...this.props}/>
                        }

                        {["items", "items-return"].includes(display.active) &&
                        <CategoryTable {...this.props}/>
                        }

                    </div>
                </div>


            </main>
        )
    }

}

export default connect(mapStateToProps, DashboardDispatcher)(DashboardOverview);
