// Dependencies
import React from 'react'
import {connect} from "react-redux";

// Dispatchers
import RentalDispatcher from "../../../common/dispatchers/RentalDispatcher";
import Scanner from "../../../common/components/General/Scanner";
import translate from "../../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    ...state.rentalIssue,
    rentalDetail: state.rentalDetail.data,
    environment: state.environment,
    transaction: state.rentalDetail.transactions
});


class TransactionCheck extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            finishedChecking: false,
            startUpdating: false,
            differentLocation: false
        }

        this.props.resetAll()

        if (this.props.match) {
            let rentalUuid = this.props.match.params.rentalUuid
            this.props.loadMin(rentalUuid)

            setTimeout(() => {
                this.setState({
                    startUpdating: true
                })
            }, 2000)
        }

    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.startUpdating && !this.state.finishedChecking && this.props.rentalDetail && !this.props.rentalDetail.loading && this.props.transaction) {

            const lastTransaction = this.props.transaction[this.props.transaction.length - 1]

            if (lastTransaction) {

                if (lastTransaction.status === 'pending') {
                    this.props.history.push('/' + this.props.environment.slug + '/dashboard/rental/' + this.props.match.params.rentalUuid + '/transaction/' + lastTransaction.id)
                } else {
                    this.setState({
                        finishedChecking: true
                    })
                }
            } else {
                this.setState({
                    finishedChecking: true
                })
            }

        }

    }

    render() {

        return (
            <main>

                <Scanner type={'rental'}/>

                <div className="o-container o-container--primary">
                    <header className="c-subject-header c-subject-header--split">
                        <h1>
                            {translate('checkingTransactions')}
                        </h1>

                    </header>
                    <section className="c-order-handling">
                        <div className="c-order-handling__notifications">
                            {!this.state.finishedChecking &&
                            <div className="c-info-blocks__text__highlight c-info-blocks__text__highlight--icon">
                                <i className="icon icon-info"/>
                                <div><h4>{translate('checkingOpenAmountText')}</h4></div>
                            </div>
                            }
                            {!!this.state.finishedChecking &&
                            <>
                                <div
                                    className="c-info-blocks__text__highlight c-info-blocks__text__highlight--positive c-info-blocks__text__highlight--icon">
                                    <i className="icon icon-check-circle"/>
                                    <div><h4>{translate('paymentCompletedText')}</h4></div>
                                </div>
                                <div className="c-info-blocks__row">
                                    <div className="c-info-blocks__single">
                                        <button className="btn btn--primary" onClick={() => this._toHome()}>
                                            <span>{translate('toRental')}</span>
                                        </button>
                                    </div>
                                </div>
                            </>

                            }
                        </div>
                    </section>
                </div>
            </main>
        )
    }


    _toHome = () => {
        const {history} = this.props
        history.push('/' + this.props.environment.slug + '/dashboard/rental/detail/' + this.props.rentalDetail.data.rental.uuid)
    }
}

export default connect(mapStateToProps, RentalDispatcher)(TransactionCheck);
