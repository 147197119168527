const SetSortValues = (values, sortBy, sortDirection, currentData) => {

    switch (sortBy) {
        case('RentalNr'):
            values.params.order_by = 'id'
            break;
        case('periodFrom'):
            values.params.order_by = 'date_rent_start'
            break;
        case('periodUntil'):
            values.params.order_by = 'date_rent_end'
            break;
        case('renter'):
            values.params.sort_table = 'private_persons'
            values.params.order_by = 'last_name'
            break;
        default:
            break;
    }

    if (sortBy === false) {
        values.sortBy = currentData.sortBy
    }

    if (sortDirection === false) {
        values.sortDirection = currentData.sortDirection
        values.params.sort = currentData.sortDirection
    }

}

export default SetSortValues