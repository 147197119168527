import React from 'react'
import {connect} from "react-redux";
import DisplayPrice from "../../helpers/DisplayEuro";
import ChangeAccessoireNumber from "../../dispatchers/ChangeAccessoireNumber";

const mapStateToProps = (state) => ({
    ...state.rentalCreate,
});


class AccessoireItem extends React.Component {

render() {

        const data = this.props.data

        return (
            <div className="c-products__single c-products__single--small">
                <div className="c-products__single__primary">
                    <h4>{data.alternative_label ? data.alternative_label : data.name}</h4>
                </div>
                <div className="c-products__single__secondary">
                    <div className="c-products__single__price">
                        {DisplayPrice(data.price)}
                    </div>
                    <div className="c-products__single__supply">
                        {data.available}
                    </div>

                    {data.available > 0 &&
                    <div className="c-products__single__amount">
                        <button className="minus" onClick={() => {ChangeAccessoireNumber(data, -1, this.props.dispatch)}}>
                            <i className="icon icon-minus"/>
                        </button>
                        <span>{data.quantity}</span>
                        <button className="plus" onClick={() => {
                            ChangeAccessoireNumber(data, 1, this.props.dispatch)
                        }}><i className="icon icon-plus"/>
                        </button>
                    </div>
                    }
                </div>
            </div>
        )
    }

}

export default connect(mapStateToProps)(AccessoireItem);