import React from "react";
import {connect} from "react-redux";
import RentalDispatcher from "../../../common/dispatchers/RentalDispatcher";
import StatusCount from "./RentalReturnStatusCount";
import CustomBarcodeInput from "../../../common/components/General/CustomBarcodeInput";
import translate from "../../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    rentalDetail: state.rentalDetail,
    environment: state.environment,
    ...state.rentalReturn
});

class RentalReturnHeader extends React.Component {


    render() {

        return (
            <header className=" c-order-handling__header">
                <div className="j-a-left j-a">
                    <div className=" c-loading-scan">
                        <svg width="28px" height="28px" viewBox="0 0 49 49" className="c-loading-scan__qr">
                            <g id="Partner-omgeving---Boeking-innemen" stroke="none" strokeWidth="1"
                               fill="none" fillRule="evenodd">
                                <g id="qr-code" fill="#000000" fillRule="nonzero">
                                    <path
                                        d="M5.90797674,6.77300301 L5.90797674,20.0401224 C5.90797674,20.5701263 6.33765323,20.9995042 6.86735865,20.9995042 L20.1347766,20.9995042 C20.664482,20.9995042 21.0941585,20.5701263 21.0941585,20.0401224 L21.0941585,17.5244696 C21.0941585,16.9944657 20.664482,16.5650878 20.1347766,16.5650878 C19.6050712,16.5650878 19.1756934,16.9944657 19.1756934,17.5244696 L19.1756934,19.0810391 L7.82674049,19.0810391 L7.82674049,7.73208628 L19.1756934,7.73208628 L19.1756934,10.0417093 C19.1756934,10.5717132 19.6050712,11.0013897 20.1347766,11.0013897 C20.664482,11.0013897 21.0941585,10.5717132 21.0941585,10.0417093 L21.0941585,6.77300301 C21.0941585,6.24299901 20.664482,5.81362118 20.1347766,5.81362118 L6.86735865,5.81362118 C6.33765323,5.81362118 5.90797674,6.24299901 5.90797674,6.77300301 Z"
                                        id="Path"/>
                                    <path
                                        d="M11.5110893,10.4573518 C10.9810853,10.4573518 10.5520061,10.8867297 10.5520061,11.416435 L10.5520061,15.3966903 C10.5520061,15.9263957 10.9810853,16.3560722 11.5110893,16.3560722 L15.4913445,16.3560722 C16.02105,16.3560722 16.4507264,15.9263957 16.4507264,15.3966903 L16.4507264,11.416435 C16.4507264,10.8867297 16.02105,10.4573518 15.4913445,10.4573518 L11.5110893,10.4573518 Z M14.5322613,14.4376071 L12.4704711,14.4376071 L12.4704711,12.3758169 L14.5322613,12.3758169 L14.5322613,14.4376071 Z"
                                        id="Shape"/>
                                    <path
                                        d="M20.1347766,27.8504402 L6.86735865,27.8504402 C6.33765323,27.8504402 5.90797674,28.2798181 5.90797674,28.8095235 L5.90797674,42.0772401 C5.90797674,42.6069454 6.33765323,43.0363233 6.86735865,43.0363233 L20.1347766,43.0363233 C20.664482,43.0363233 21.0941585,42.6069454 21.0941585,42.0772401 L21.0941585,28.8095235 C21.0941585,28.2798181 20.664482,27.8504402 20.1347766,27.8504402 L20.1347766,27.8504402 Z M19.1756934,41.1178582 L7.82674049,41.1178582 L7.82674049,29.7689053 L19.1756934,29.7689053 L19.1756934,41.1178582 Z"
                                        id="Shape"/>
                                    <path
                                        d="M28.9707641,20.9995042 L42.2384807,20.9995042 C42.7678875,20.9995042 43.197564,20.5701263 43.197564,20.0401224 L43.197564,6.77300301 C43.197564,6.24299901 42.7678875,5.81362118 42.2384807,5.81362118 L28.9707641,5.81362118 C28.4410587,5.81362118 28.0113823,6.24299901 28.0113823,6.77300301 L28.0113823,20.0401224 C28.0113823,20.5701263 28.4410587,20.9995042 28.9707641,20.9995042 L28.9707641,20.9995042 Z M29.9298474,7.73208628 L41.2788002,7.73208628 L41.2788002,19.0810391 L29.9298474,19.0810391 L29.9298474,7.73208628 Z"
                                        id="Shape"/>
                                    <path
                                        d="M43.197564,42.0772401 L43.197564,28.8095235 C43.197564,28.2798181 42.7678875,27.8504402 42.2384807,27.8504402 L28.9707641,27.8504402 C28.4410587,27.8504402 28.0113823,28.2798181 28.0113823,28.8095235 L28.0113823,42.0772401 C28.0113823,42.6069454 28.4410587,43.0363233 28.9707641,43.0363233 L42.2384807,43.0363233 C42.7678875,43.0363233 43.197564,42.6069454 43.197564,42.0772401 Z M41.2788002,41.1178582 L29.9298474,41.1178582 L29.9298474,29.7689053 L41.2788002,29.7689053 L41.2788002,41.1178582 Z"
                                        id="Shape"/>
                                    <path
                                        d="M42.2384807,25.4422813 C42.7678875,25.4422813 43.197564,25.0126048 43.197564,24.4828994 C43.197564,23.9528955 42.7678875,23.5238162 42.2384807,23.5238162 L25.4192895,23.5238162 L25.4192895,6.66670362 C25.4192895,6.1369982 24.9896131,5.70732171 24.4602063,5.70732171 C23.9299038,5.70732171 23.5005258,6.1369982 23.5005258,6.66670362 L23.5005258,23.5238162 L10.7678894,23.5238162 C10.2384825,23.5238162 9.80880605,23.953194 9.80880605,24.4828994 C9.80880605,25.0129034 10.2384825,25.4422813 10.7678894,25.4422813 L23.5005258,25.4422813 L23.5005258,42.0772401 C23.5005258,42.6069454 23.9299038,43.0363233 24.4602063,43.0363233 C24.9896131,43.0363233 25.4192895,42.6069454 25.4192895,42.0772401 L25.4192895,25.4422813 L42.2384807,25.4422813 Z"
                                        id="Path"/>
                                    <path
                                        d="M11.5110893,38.4642551 L15.4913445,38.4642551 C16.02105,38.4642551 16.4507264,38.0345787 16.4507264,37.5048733 L16.4507264,33.5246181 C16.4507264,32.9946141 16.02105,32.5655348 15.4913445,32.5655348 L11.5110893,32.5655348 C10.9810853,32.5655348 10.5520061,32.9946141 10.5520061,33.5246181 L10.5520061,37.5048733 C10.5520061,38.0345787 10.9810853,38.4642551 11.5110893,38.4642551 L11.5110893,38.4642551 Z M12.4704711,34.4839999 L14.5322613,34.4839999 L14.5322613,36.54579 L12.4704711,36.54579 L12.4704711,34.4839999 Z"
                                        id="Shape"/>
                                    <path
                                        d="M37.5944514,10.4573518 L33.6141962,10.4573518 C33.0844908,10.4573518 32.6548143,10.8867297 32.6548143,11.416435 L32.6548143,15.3966903 C32.6548143,15.9263957 33.0844908,16.3560722 33.6141962,16.3560722 L37.5944514,16.3560722 C38.1244554,16.3560722 38.5538333,15.9263957 38.5538333,15.3966903 L38.5538333,11.416435 C38.5538333,10.8867297 38.1244554,10.4573518 37.5944514,10.4573518 Z M36.6350696,14.4376071 L34.5738766,14.4376071 L34.5738766,12.3758169 L36.6350696,12.3758169 L36.6350696,14.4376071 Z"
                                        id="Shape"/>
                                    <path
                                        d="M33.6141962,38.4642551 L37.5944514,38.4642551 C38.1244554,38.4642551 38.5538333,38.0345787 38.5538333,37.5048733 L38.5538333,33.5246181 C38.5538333,32.9946141 38.1244554,32.5655348 37.5944514,32.5655348 L33.6141962,32.5655348 C33.0844908,32.5655348 32.6548143,32.9946141 32.6548143,33.5246181 L32.6548143,37.5048733 C32.6548143,38.0345787 33.0844908,38.4642551 33.6141962,38.4642551 L33.6141962,38.4642551 Z M34.5738766,34.4839999 L36.6350696,34.4839999 L36.6350696,36.54579 L34.5738766,36.54579 L34.5738766,34.4839999 Z"
                                        id="Shape"/>
                                    <path
                                        d="M6.67327265,23.5276979 L6.62161594,23.5396417 C6.10594444,23.6614679 5.78644912,24.1780351 5.90797674,24.6937066 C6.01218603,25.1353269 6.40603126,25.4333234 6.84078382,25.4333234 C6.91393928,25.4333234 6.98769189,25.4246642 7.06174315,25.4070472 L7.11310136,25.3951034 C7.62907144,25.2735757 7.94856676,24.7570085 7.82674049,24.241337 C7.70551145,23.7259642 7.18894415,23.4064688 6.67327265,23.5276979 Z"
                                        id="Path"/>
                                    <path
                                        d="M19.9415864,12.8257977 L19.8899297,12.8380401 C19.3742582,12.9589706 19.0544643,13.4752392 19.1753948,13.9912093 C19.2787082,14.4334267 19.672852,14.7317219 20.1085004,14.7317219 C20.18076,14.7317219 20.2542141,14.7233613 20.3282654,14.7057442 L20.3796235,14.6938005 C20.8955936,14.57287 21.2153876,14.0563028 21.0944571,13.5409299 C20.9735266,13.0252585 20.4569593,12.7048673 19.9415864,12.8257977 Z"
                                        id="Path"/>
                                    <path
                                        d="M0.959083261,7.88287612 C1.48938584,7.88287612 1.9184651,7.45349821 1.9184651,6.92379286 L1.9184651,1.94384563 L6.71716596,1.94384563 C7.24716996,1.94384563 7.67684644,1.51476629 7.67684644,0.984762289 C7.67684644,0.45505694 7.24716996,0.0253804533 6.71716596,0.0253804533 L0.959083261,0.0253804533 C0.429676487,0.0253804533 0,0.45505694 0,0.984762289 L0,6.92379286 C0,7.45349821 0.429676487,7.88287612 0.959083261,7.88287612 Z"
                                        id="Path"/>
                                    <path
                                        d="M47.9625237,0.0253804533 L42.204441,0.0253804533 C41.674437,0.0253804533 41.2447605,0.45505694 41.2447605,0.984762289 C41.2447605,1.51476629 41.674437,1.94384563 42.204441,1.94384563 L47.0031419,1.94384563 L47.0031419,6.92379286 C47.0031419,7.45349821 47.4322211,7.88287612 47.9625237,7.88287612 C48.4919305,7.88287612 48.921607,7.45349821 48.921607,6.92379286 L48.921607,0.984762289 C48.921607,0.45505694 48.4919305,0.0253804533 47.9625237,0.0253804533 Z"
                                        id="Path"/>
                                    <path
                                        d="M6.71716596,46.9873163 L1.9184651,46.9873163 L1.9184651,42.0076677 C1.9184651,41.4782609 1.48938584,41.0485844 0.959083261,41.0485844 C0.429676487,41.0485844 0,41.4782609 0,42.0076677 L0,47.9466982 C0,48.4764036 0.429676487,48.9060801 0.959083261,48.9060801 L6.71716596,48.9060801 C7.24716996,48.9060801 7.67684644,48.4764036 7.67684644,47.9466982 C7.67684644,47.4166942 7.24716996,46.9873163 6.71716596,46.9873163 L6.71716596,46.9873163 Z"
                                        id="Path"/>
                                    <path
                                        d="M47.9625237,41.0485844 C47.4322211,41.0485844 47.0031419,41.4782609 47.0031419,42.0076677 L47.0031419,46.9873163 L42.204441,46.9873163 C41.674437,46.9873163 41.2447605,47.4166942 41.2447605,47.9466982 C41.2447605,48.4764036 41.674437,48.9060801 42.204441,48.9060801 L47.9625237,48.9060801 C48.4919305,48.9060801 48.921607,48.4764036 48.921607,47.9466982 L48.921607,42.0076677 C48.921607,41.4782609 48.4919305,41.0485844 47.9625237,41.0485844 L47.9625237,41.0485844 Z"
                                        id="Path"/>
                                </g>
                            </g>
                        </svg>
                        <svg width="120px" height="120px" className="c-loading-scan__loader" x="0px" y="0px"
                             viewBox="0 0 100 100" enableBackground="new 0 0 0 0">
                            <path fill="#00000"
                                  d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                <animateTransform attributeName="transform" attributeType="XML"
                                                  type="rotate" dur="1.5s" from="0 50 50" to="360 50 50"
                                                  repeatCount="indefinite"/>
                            </path>
                        </svg>
                    </div>
                    <h2>{translate('itemsInRental')}</h2>
                    <StatusCount/>
                </div>
                <CustomBarcodeInput type={'return'}/>
            </header>
        )
    }
}

export default connect(mapStateToProps, RentalDispatcher)(RentalReturnHeader);
