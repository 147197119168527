import fetchHelper from "../../common/helpers/fetchHelper";

export default class UserEffect {

    static async list(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return {
                success: true,
                data: response.items.users
            }
        } else {
            return {
                success: false,
                notifications: {
                    error: 'Er heeft zich een onbekende fout voorgedaan. Probeer het later opnieuw.'
                }
            }
        }
    }

    static async detail(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return {
                success: true,
                data: response.items.user
            }
        } else return null

    }

}

