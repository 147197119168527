import moment from "moment";
import CalculateDaysBetween from "../../../common/helpers/CalculateDaysBetween";
import fetchHelper from "../../../common/helpers/fetchHelper";

export default class RentalPeriodEffect {

    static async set(values) {

        return values
    }


    static async setTime(value) {
        return value;
    }

    static async checkAvailability(values, dispatch) {

        let availability = null
        let availabilityItems = []

        const responseBicycle = await fetchHelper(values.bicycles, dispatch)
        const responseAccessoires = await fetchHelper(values.accessoires, dispatch)


        if (responseBicycle && responseAccessoires && values.shoppingCartItems) {
            availability = true;

            if (responseBicycle.items) {
                availabilityItems = Object.values(responseBicycle.items)
            }

            if (responseAccessoires.items) {
                availabilityItems = availabilityItems.concat(Object.values(responseAccessoires.items))
            }


            // Looping trough the shoppingcartItems to check
            values.shoppingCartItems.filter((item) => item.type === 'category').forEach((shoppingCartItem) => {

                const availabilityItem = availabilityItems.filter((item) => item.id === shoppingCartItem.category_id)[0]

                if (availabilityItem) {
                    if (availabilityItem.available < shoppingCartItem.quantity) {

                        // Setting the availability to false in case one of the items is false
                        availability = false
                    }
                } else {
                    availability = false
                }

            })



            return {
                error: false,
                availability: availability
            }

        } else return {
            error: true,
            availability: availability
        }

    }

    static async reset() {
        return true;
    }

    static async loadEnvironmentSettings(environment) {

        const days = environment?.data?.info?.default_rent_days ? environment?.data?.info?.default_rent_days : 1

        return {
            days: days,
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().add(days - 1, 'days').format('YYYY-MM-DD'),
        }
    }


    static async submitMutation(values, dispatch) {
        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response
        } else return null
    }

}
