import {SvgQrIcon} from "../svg/SvgQrIcon";

export const EventScanning = (props) => {

    return (
        <div className="c-scan__qr c-scan__qr--scanning">
            <div className="c-scan__qr--load"/>
            <div
                style={{
                    position: 'absolute',
                    left: 'calc(50% - 4rem)',
                    top: '6rem',
                }}
            >
                <SvgQrIcon/>
            </div>
            {!!props.text && <h2>{props.text}</h2>}
        </div>
    )

}