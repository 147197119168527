import {BaseModel} from 'sjs-base-model';
import CalculateDaysBetween from "../helpers/CalculateDaysBetween";

export default class CategoryModel extends BaseModel {
    id = null
    category = null
    title = null
    data = null
    discount = '-'
    days = null

    constructor(data) {
        super();
        this.update(data);
    }

    update(data) {

        this.id = '#' + data.id
        if (data.category) {
            this.category = data.category.name
        }else{
            this.category = data.description
            this.title = data.description
        }

        if (data.discount_price) {
            this.discount = data.discount_price
        }


        this.data = data

        if(this.data.date_rent_start && this.data.date_rent_end){
            this.days = CalculateDaysBetween(this.data.date_rent_start, this.data.date_rent_end)
        }

    }

}
