import {RentalItemsToReturn} from "../../../../common/components/RentalItemsToReturn";
import {takeItem} from "../rentalItemsExchangePageSlice";
import {ScannedItems} from "../../../../common/components/ScannedItems";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {changeStep} from "../rentalItemsExchangePageSlice";
import translate from "../../../../common/helpers/utils/translate";

export const RentalItemsExchangeTakeStep = (props) => {

    const dispatch = useAppDispatch()

    const {
        rentalItems,
        rentalTakeItems,
        step,
        giveItemValidation
    } = useAppSelector(store => store.rentalItemsExchangePage)

    let containerClass = ''

    if (props.disabled) {
        containerClass += ' is-disabled'
    }

    return (
        <div className={'c-swap-container'}>

            <div className={containerClass}>

                <h2>1. {translate('itemsToReturnTitle')}</h2>

                <RentalItemsToReturn
                    takeItemAction={(item) => dispatch(takeItem({
                        item: item,
                        scanned: false
                    }))}
                    itemsToScan={rentalItems}
                    scannedItems={rentalTakeItems}
                />

                <ScannedItems
                    scanCode={props.scanCode}
                    title={translate('exchangeItemsTitle')}
                    scanItemAction={(item) => dispatch(takeItem({
                        item: item,
                        scanned: true
                    }))}
                    removeItemAction={(item) => dispatch(takeItem({
                        item: item,
                        scanned: false
                    }))}
                    items={rentalTakeItems}
                    validation={false}
                    disabled={props.disabled}
                    itemScanner={true}
                />

            </div>

            {(!!rentalTakeItems.length && step === 'take') &&
            <button
                onClick={() => dispatch(changeStep('give'))}
                className={'btn btn--primary'}
            >
                <span>{translate('continue')}</span>
            </button>
            }

            {(step === 'give') &&
            <button
                disabled={giveItemValidation.processing}
                onClick={() => dispatch(changeStep('take'))}
                className={'btn btn--primary'}
            >
                <span>{translate('edit')}</span>
            </button>
            }

        </div>
    )

}