import {calculateGiveItemsPerCategoryNotInOrderLine} from "../helpers/calculate/calculateGiveItemsPerCategoryNotInOrderLine";
import {useAppDispatch} from "../../app/hooks";
import {RentalOrderLinesToGive} from "./RentalOrderLinesToGive";
import Loader from "react-loader-spinner";
import translate from "../helpers/utils/translate";

const GiveItemModal = (props) => {

    const scenario = formatScenario(props)

    const {
        rentalContainer,
        rentalItems,
        categoryMutations,
        takeItems,
        giveItems,
        activeOption,
        rentalOrderLines
    } = props

    const categoriesAdded = calculateGiveItemsPerCategoryNotInOrderLine(rentalOrderLines, giveItems, rentalItems, takeItems)

    const calculateOrderLineLeft = (orderLineItem) => {

        const giveItemsInOrderLine = giveItems.filter((giveItem) => orderLineItem.type === 'category' && giveItem.category.id === orderLineItem.categoryId)
        const takeItemsInOrderLine = takeItems?.filter((takeItem) => orderLineItem.type === 'category' && takeItem.category.id === orderLineItem.categoryId)
        const rentalItemsInOrderLine = rentalItems.filter((rentalItem) => orderLineItem.type === 'category' && rentalItem.category.id === orderLineItem.categoryId)

        if (takeItemsInOrderLine) {
            return orderLineItem.quantity + takeItemsInOrderLine.length - (rentalItemsInOrderLine.length + giveItemsInOrderLine.length)
        } else {
            return orderLineItem.quantity - (rentalItemsInOrderLine.length + giveItemsInOrderLine.length)

        }

    }

    return (
        <div className="c-modal">
            <div className="c-modal__bg"/>
            <div className="c-modal__content c-modal__content--text">

                <button className="c-modal__close" onClick={() => props.onClose()}>
                    <i className="icon icon-cross"/>
                </button>

                <div className="c-modal__header">
                    <h2>{translate('attentionRequired')}</h2>
                </div>

                <div className="c-modal__body">
                    <p>{scenario?.text}</p>

                    <div className={'c-scenario-options'}>
                        {scenario?.options
                            .filter((option) => !props.excludeOptions?.includes(option))
                            .map((option) => {

                                let label = ''
                                const active = props.activeOption === option

                                switch (option) {
                                    case('swap'):
                                        label = translate('swapItemsLabel')
                                        break;
                                    case('add'):
                                        label = translate('addItemsLabel')
                                        break;
                                    case('delete'):
                                        label = translate('deleteItemsLabel')
                                        break;
                                    case('later'):
                                        label = translate('addItemsLaterLabel')
                                        break;
                                }

                                return (
                                    <div className={'c-form-group'}>
                                        <input data-hj-allow id={'give-modal-radio-' + option}
                                               onClick={() => props.changeOption(option)}
                                               type={'radio'} value={'option'} checked={active}/>
                                        <label style={{marginLeft: '.5rem', position: 'relative', top: '-.2rem'}}
                                               htmlFor={'give-modal-radio-' + option}>{label}</label>
                                    </div>
                                )

                            })}
                    </div>

                    {activeOption === 'swap' &&
                    <div className={'give-modal-display'} style={{margin: '2rem 0'}}>

                        <p>{translate('exchangeModalTitle')}</p>

                        <RentalOrderLinesToGive
                            rentalOrderLines={rentalContainer.rentalItems}
                            categoryMutations={categoryMutations}
                            giveItems={giveItems}
                            takeItems={takeItems}
                            rentalItems={rentalItems}
                            mutateCategoryAmountAction={(categoryMutation) => props.mutateCategoryAmountAction(categoryMutation)}
                        />
                    </div>}

                    {activeOption === 'add' &&
                    <div className={'give-modal-display'} style={{margin: '2rem 0'}}>

                        <p>{translate('addModalTitle')}</p>

                        <ul>
                            {categoriesAdded.map((categoryAdded) => {
                                return <li>{categoryAdded.label} x {categoryAdded.quantity}</li>
                            })}
                        </ul>

                    </div>}

                    {activeOption === 'later' &&
                    <div className={'give-modal-display'} style={{margin: '2rem 0'}}>

                        <p>{translate('laterModalTitle')}</p>

                        {rentalOrderLines?.filter((orderLineItem) => orderLineItem.type === 'category').map((rentalOrderLine) => {


                            const label = rentalOrderLine.category.alternativeLabel ? rentalOrderLine.category.alternativeLabel : rentalOrderLine.category.name
                            const quantityLeft = calculateOrderLineLeft(rentalOrderLine)

                            if (quantityLeft > 0) {
                                return (
                                    <ul className={'c-modal-order-line'}>
                                        <li>{label} x {quantityLeft}</li>
                                    </ul>
                                )
                            } else return null

                        })}

                    </div>}

                    {activeOption === 'delete' &&
                    <div className={'give-modal-display'} style={{margin: '2rem 0'}}>

                        <p>{translate('deleteModalTitle')}</p>

                        <RentalOrderLinesToGive
                            rentalOrderLines={rentalContainer.rentalItems}
                            categoryMutations={categoryMutations}
                            giveItems={giveItems}
                            takeItems={takeItems}
                            rentalItems={rentalItems}
                            mutateCategoryAmountAction={(categoryMutation) => props.mutateCategoryAmountAction(categoryMutation)}
                        />

                    </div>}

                </div>


                <div className="c-modal__action">

                    {props.submitting &&
                    <>

                        <Loader
                            height="20"
                            width="20"
                            color='#002BD3'
                            ariaLabel='loading'
                        />

                        <p style={{margin: '0 2rem 0 1rem'}}> {translate('processing')}...</p>
                    </>
                    }

                    {!props.submitting &&
                    <button className="btn btn--small btn--primary"
                            onClick={() => props.onSubmit()}
                    >
                        <span>{translate('processing')}</span>
                    </button>
                    }

                </div>
            </div>
        </div>
    )

}


const formatScenario = (props) => {
    if (props.notAllCategoriesGiven && props.tooManyCategoriesGiven) {
        return {
            text: translate('giveItemModalAlert1'),
            options: ['swap', 'delete', 'add', 'later']
        }
    } else {
        if (props.tooManyCategoriesGiven) {
            return {
                text: translate('giveItemModalAlert2'),
                options: ['swap', 'add']
            }
        } else {
            if (props.notAllCategoriesGiven) {
                return {
                    text: translate('giveItemModalAlert3'),
                    options: ['later', 'delete']
                }
            }
        }
    }
}

export default GiveItemModal
