const DisplayName = (data) => {
    let name = ''

    name += data.first_name ? ' ' + data.first_name : ''
    name += data.insertion ? ' ' + data.insertion : ''
    name += data.last_name ? ' ' + data.last_name : ''

    return name
}

export default DisplayName