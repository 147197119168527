import {calculateDaysBetween} from "../calculate/calculateDaysBetween";
import moment from "moment";
import {calculateScannedCount} from "../calculate/calculateScanCount";

export const formatRentalItemsToCategoryMutations = (args) => {

    const {rentalOrderLines, categoryMutations, rentalItems, rentalGiveItems, rentalTakeItems} = args

    let orderLines = rentalOrderLines?.filter((orderLine) => orderLine.type === 'category').map((rentalOrderLine) => {

        const categoryMutation = categoryMutations.find((categoryMutation) => categoryMutation.categoryId === rentalOrderLine.categoryId)
        if (categoryMutation) {

            return categoryMutation

        } else {

            const scannedCount = calculateScannedCount(rentalOrderLine.categoryId, rentalItems, rentalGiveItems, rentalTakeItems)
            let quantity = rentalOrderLine.quantity

            if (scannedCount && scannedCount > rentalOrderLine.quantity) {
                quantity = scannedCount
            }

            return {
                quantity: quantity,
                categoryId: rentalOrderLine.categoryId,
                mutationPrice: rentalOrderLine.mutationPrice
            }
        }
    })

    rentalGiveItems.forEach((rentalGiveItem) => {
        if (!orderLines.find((rentalOrderLine) => rentalOrderLine.categoryId === rentalGiveItem.availabilityCategory.id)) {
            const quantity = rentalGiveItems.filter((item) => item.availabilityCategory.id === rentalGiveItem.availabilityCategory.id).length

            orderLines.push({
                quantity: quantity,
                categoryId: rentalGiveItem.availabilityCategory.id,
            })

        }
    })

    /** Exchange compensation
    if (args.compensationData && rentalTakeItems?.length) {


        let takePrice = 0
        let givePrice = 0
        const {categoryAvailabilities, rentalContainer} = args.compensationData
        const days = calculateDaysBetween(rentalContainer.rental.dateRentStart, rentalContainer.rental.dateRentEnd)
        const daysLeft = calculateDaysBetween(moment().startOf('day').toDate() + '', rentalContainer.rental.dateRentEnd)

        // Calculate the price from the taken items over the total days
        rentalTakeItems?.forEach((rentalTakeItem) => {

            const categoryAvailabilityItem = categoryAvailabilities.find((categoryAvailability) => categoryAvailability.id === rentalTakeItem.availabilityCategory.id)

            if (categoryAvailabilityItem) {
                takePrice += categoryAvailabilityItem.price / days
            }

        })

        // Calculate the price from the given items over the total days
        rentalGiveItems.forEach((rentalGiveItem) => {

            const categoryAvailabilityItem = categoryAvailabilities.find((categoryAvailability) => categoryAvailability.id === rentalGiveItem.availabilityCategory.id)

            if (categoryAvailabilityItem) {
                givePrice += categoryAvailabilityItem.price / days
            }

        })


        // Calculate the compensation price per item
        const compensationPricePerItem = ((givePrice - takePrice) / rentalGiveItems.length) * (daysLeft - days)

        // Set the price compensation on the order lines of the given items
        rentalGiveItems.forEach((rentalGiveItem) => {

            orderLines = orderLines.map((orderLine) => {

                // Set the compensation price on the corresponding order line
                if (orderLine.categoryId === rentalGiveItem.availabilityCategory.id) {
                    return {
                        ...orderLine,
                        mutationPrice: compensationPricePerItem
                    }
                } else return orderLine

            })

        })

    } */


    return orderLines

}