import RentalCancellationAction from "./RentalCancellationAction";

export default class RentalCancellationReducer {

    static initialState = {
        info: null,
        response: null,
        cancellationReason: null
    };

    static reducer(state = RentalCancellationReducer.initialState, action) {

        switch (action.type) {

            case RentalCancellationAction.REQUEST_COSTS:

                return {
                    ...state,
                    info: RentalCancellationReducer.initialState.costs,
                };

            case RentalCancellationAction.REQUEST_COSTS + '_FINISHED':

                return {
                    ...state,
                    info: action.payload,
                };


            case RentalCancellationAction.CHANGE_REASON:

                return {
                    ...state,
                };

            case RentalCancellationAction.CHANGE_REASON + '_FINISHED':

                return {
                    ...state,
                    cancellationReason: action.payload
                };

            case RentalCancellationAction.SUBMIT_MUTATION:

                return {
                    ...state,
                };

            case RentalCancellationAction.SUBMIT_MUTATION + '_FINISHED':

                return {
                    ...RentalCancellationReducer.initialState,
                    response: action.payload
                };

            default:
                return state;
        }


    }
}