import React from 'react'
import MutationModel from "../../../common/models/MutationModel";
import DisplayPrice from "../../../common/helpers/DisplayEuro";
import {connect} from "react-redux";
import IsCurrentPartnerLocation from "../../../common/helpers/IsCurrentPartnerLocation";
import CheckDisplayModule from "../../../common/helpers/CheckDisplayModule";
import translate from "../../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    environment: state.environment,
    rentalDetail: state.rentalDetail.data,
    mutations: Object.values(state.rentalDetail.mutations),
    partnerUser: state.authentication.partnerUser
});


class RentalMutations extends React.Component {

    render() {


        const {mutations} = this.props
        const transactions = this.props.transaction

        const isCurrentPartnerLocation = IsCurrentPartnerLocation(this.props)

        if (CheckDisplayModule('rentalMutations', this.props.environment, this.props.partnerUser) && mutations && isCurrentPartnerLocation && mutations.length > 0) {

            return (
                <div className="c-info-blocks__row">

                    <div className="c-info-blocks__single">
                        <header className="c-subject-header c-subject-header--closed"><h2>{translate('mutations')}</h2></header>
                        <div className="c-info-blocks__single__body c-info-blocks__single__body--scroll">

                            <table className="c-table-standard">
                                <tbody>

                                <tr>
                                    <td>ID</td>
                                    <td>{translate('date')}</td>
                                    <td>Type</td>
                                    <td>{translate('price')}</td>
                                    <td>{translate('status')}</td>

                                </tr>

                                {mutations.sort((a, b) => new Date(b.date_updated) - new Date(a.date_updated)).map((mutationItem) => {

                                    const mutation = new MutationModel(mutationItem)
                                    const transaction = Object.values(transactions).filter((transaction) => {
                                        return (transaction.id === mutation.transactionId)
                                    })[0]

                                    let totalPrice = 0
                                    let status = translate('na')
                                    let trClass = ''

                                    if (transaction) {
                                        totalPrice = transaction.total
                                        status = transaction.status
                                        trClass = 'enable-hover'
                                    }

                                    return (
                                        <tr className={trClass} key={'mutation-' + mutation.id}>
                                            <td>#{mutation.id}</td>
                                            <td>{mutation.date}</td>
                                            <td>{mutation.type}</td>
                                            <td className="table-button">{DisplayPrice(totalPrice)}</td>
                                            <td>{status}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        } else return null
    }

}

export default connect(mapStateToProps)(RentalMutations);
