import React from "react";
import {connect} from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import RentalDeliveryAction from "../../../stores/rental/delivery/RentalDeliveryAction";
import RentalTotalsAction from "../../../stores/rental/totals/RentalTotalsAction";
import translate from "../../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    rentalDelivery: state.rentalDelivery.delivery,
    deliveryAddressValidation: state.rentalDelivery.deliveryAddressValidation, // clean up in tsx...
    rentalItems: state.rentalItems,
    environment: state.environment,
    rentalPeriod: state.rentalPeriod
});


class RentalOptions extends React.Component {

    componentDidMount() {
        this.props.dispatch(RentalDeliveryAction.validateDeliveryByAddress())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.rentalPeriod?.period !== this.props.rentalPeriod.period) {
            this.props.dispatch(RentalDeliveryAction.validateDeliveryByAddress())
        }

        if (prevProps.rentalDelivery?.delivery?.deliveryPickupLocation?.value !== this.props.rentalDelivery?.delivery?.deliveryPickupLocation?.value) {
            this.props.dispatch(RentalDeliveryAction.validateDeliveryByAddress())
        }

        if ((prevProps.rentalItems?.shoppingCart.bicycles !== this.props.rentalItems?.shoppingCart.bicycles)) {
            this.props.dispatch(RentalDeliveryAction.validateDeliveryByAddress())
        }

        if ((prevProps.rentalItems?.shoppingCart.accessoires !== this.props.rentalItems?.shoppingCart.accessoires)) {
            this.props.dispatch(RentalDeliveryAction.validateDeliveryByAddress())
        }

    }

    render() {

        const deliveryCheck = this._deliveryCheck();

        if (!this.props.environment.data.info.delivery_disabled) {
            return (
                <div className="c-info-blocks__row">
                    <div className="c-info-blocks__single">
                        <header className="c-subject-header c-subject-header--closed">
                            <h2>{translate('options')}</h2>
                        </header>
                        <div className="c-info-blocks__single__body">
                            <div className="c-form__row">
                                <div
                                    className="c-form__group c-form__group--radio c-form__group--radio-label">
                                    <span>{translate('delivery')}?</span>
                                    {deliveryCheck.status &&
                                    <div>
                                        <label htmlFor="deliveryYes">
                                            <input data-hj-allow type="radio" id="deliveryYes" name="delivery"
                                                   checked={this.props.rentalDelivery.enabled === 1}
                                                   value={1}
                                                   onChange={(e) => this._onChangeDelivery(e)}/>
                                            <span>{translate('yes')}</span>
                                        </label>
                                        <label htmlFor="deliveryNo">
                                            <input data-hj-allow type="radio" id="deliveryNo" name="delivery"
                                                   checked={this.props.rentalDelivery.enabled === 0 || this.props.rentalDelivery.enabled === false}
                                                   value={0}
                                                   onChange={(e) => this._onChangeDelivery(e)}/>
                                            <span>{translate('no')}</span>
                                        </label>
                                    </div>
                                    }

                                    {!deliveryCheck.status &&
                                    <small>{deliveryCheck.reason}</small>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else return null

    }

    _onChangeDelivery = (e) => {

        this.props.dispatch(RentalDeliveryAction.setDeliveryStatus(e.currentTarget.value))

        if (e.currentTarget.value === '1') {
            this.props.dispatch(RentalTotalsAction.setDeposit(false)).then(() => {
                this.props.dispatch(RentalTotalsAction.requestTotals(!!this.props.create, !!this.props.create, true))
            })
        } else {
            this.props.dispatch(RentalTotalsAction.setDeposit(!!this.props.environment.data.info.enable_deposit)).then(() => {
                this.props.dispatch(RentalTotalsAction.requestTotals(!!this.props.create, !!this.props.create, true))
            })
        }

    }

    _deliveryCheck = () => {

        const deliveryLocation = this.props.rentalDelivery.deliveryPickupLocation
        const shoppingCartBicycles = this.props.rentalItems.shoppingCart.bicycles
        const shoppingCartAccessoires = this.props.rentalItems.shoppingCart.accessoires
        let reason = '';

        let deliverable = false


        // Checking if the deliveryLocation is deliverable
        if (deliveryLocation && deliveryLocation.address && deliveryLocation.address.deliverable) {
            if (this.props.deliveryAddressValidation) {
                deliverable = true
            } else {
                reason = translate('noDeliveryDepot')
            }

        } else {
            reason = translate('noDeliveryHolidayAddress')
        }

        // Checking if the shopping cart items are deliverable
        if (shoppingCartBicycles.some(item => item.delivery === 0) || shoppingCartAccessoires.some(item => item.delivery === 0)) {
            deliverable = false;
            reason = 'Er is geen bezorging mogelijk door de items in de boeking/reservering'
        }

        return {
            status: deliverable,
            reason: reason
        };

    }

}


export default connect(mapStateToProps)(RentalOptions);
