import React from "react";

export const EventWrapper = (props) => {

    return (
        <div className="c-scan">
            <div className="o-container o-container--primary">
                {props.children}
            </div>
        </div>
    )

}