export const formatMobianCheckinResponseToCode = (response) => {

    switch (response.status.code) {
        case(200):
            return 'success'
        case(401):
            return 'unauthorized'
        case(404):
            return 'invalidCode'
        case(400):
            return response.items?.error.code
        default:
            return 'unknown'
    }
}