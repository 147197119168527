// Dependencies
import React from 'react'
import {connect} from "react-redux";
import moment from 'moment'

// Actions
import RentalExtendAction from "../../stores/rental/extend/RentalExtendAction";
import RentalTotalsAction from "../../stores/rental/totals/RentalTotalsAction";
import RentalDetailAction from "../../stores/rental/detail/RentalDetailAction";
import RentalReturnAction from "../../stores/rental/returnItems/RentalReturnItemsAction";

// Components
import RentalReturnHeader from "./components/RentalReturnHeader";
import RentalReturnItems from "./components/RentalReturnItems";
import RentalReturnScannedItems from "./components/RentalReturnScannedItems";
import RentalReturnNotifications from "./components/RentalReturnNotifications";
import RentalReturnComments from "./components/RentalReturnRemarks";
import RentalReturnActions from "./components/RentalReturnActionsBar";
import ToDetailButton from "../../common/components/General/ToDetailButton";
import TinyLoader from "../../common/components/General/TinyLoader";
import RentalReturnPopup from "./components/RentalReturnPopup";
import RentalReturnErrors from "./components/RentalReturnErrors";
import Scanner from "../../common/components/General/Scanner";
import RentalInfoBar from "../RentalDetail/components/RentalInfoBar";
import RentalTotals from "../../common/components/Rental/RentalTotals";

// Helpers
import ToTransactionCheck from "../../common/helpers/ToTransactionCheck";
import IsCurrentPartnerLocation from "../../common/helpers/IsCurrentPartnerLocation";

// Dispatchers
import RentalDispatcher from "../../common/dispatchers/RentalDispatcher";
import translate from "../../createSlice/common/helpers/utils/translate";
import {PinValidationWrapper} from "../../common/wrappers/PinValidationWrapper";
import {apiPartnerTimeUnitAmount} from "../../createSlice/common/api/partner/timeUnit/apiPartnerTimeUnitAmount";
import {apiPartnerTimeUnitList} from "../../createSlice/common/api/partner/timeUnit/apiPartnerTimeUnitList";
import {apiPartnerTimeUnitCalculate} from "../../createSlice/common/api/partner/timeUnit/apiPartnerTimeUnitCalculate";

const mapStateToProps = (state) => ({
    rentalDetail: state.rentalDetail.data,
    environment: state.environment,
    rentalTotals: state.rentalTotals,
    ...state.rentalReturn
});

class RentalMutationReturnItems extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            extend: false
        }
    }

    componentDidMount() {
        this.processItemOnInit()
        this._init()

        this.setState({
            extend: !!this.props.environment.data.info.charging_period_defaultf
        })

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.processItemOnInit()

        if (prevProps.match.params.rentalUuid !== this.props.match.params.rentalUuid) {
            this._init()
        }

        if (this.props.rentalDetail !== prevProps.rentalDetail) {
            const rentalIsLate = this._rentalLateCheck()
            if (!rentalIsLate) {
                this.state.extend = false
            }
        }

    }

    _init = () => {
        this.props.resetAll()
        let rentalUuid = this.props.match.params.rentalUuid
        this.props.load(rentalUuid)
    }

    processItemOnInit = () => {
        if (this.props.match && this.props.scannedItem) {
            this.props.dispatch(RentalReturnAction.validateAndSetItem(this.props.match.params.rentalUuid)).then(() => {
                this.props.dispatch(RentalReturnAction.loadStatusFromItems())
            })

        }
    }

    render() {


        if (this.props.rentalDetail) {

            const isCurrentPartnerLocation = IsCurrentPartnerLocation(this.props)
            const rentalIsLate = this._rentalLateCheck()

            if (isCurrentPartnerLocation) {
                return (
                    <>
                        <PinValidationWrapper>

                            <Scanner type={'return'}/>

                            <main className={'c-split-sidebar'}>
                                <div className="o-container o-container--primary">
                                    <header className="c-subject-header c-subject-header--split">
                                        <h1>{translate('take')} <small
                                            style={{textDecoration: 'underline'}}>{this.props.rentalDetail.RentalNr}
                                        </small>
                                        </h1>
                                        <div className=" c-subject-header__buttons">
                                            <ToDetailButton history={this.props.history}
                                                            slug={this.props.environment.slug}
                                                            uuid={this.props.rentalDetail.data.rental.uuid}/>
                                        </div>
                                    </header>

                                    <RentalInfoBar/>

                                    <section className="c-order-handling">

                                        <RentalReturnHeader/>


                                        <RentalReturnItems/>
                                        <RentalReturnScannedItems/>
                                        <RentalReturnNotifications/>
                                        <RentalReturnComments/>

                                        {!!rentalIsLate &&

                                        <div className={'c-return-late'}>

                                            <header>
                                                <h4>{translate('tooLate')}</h4>
                                            </header>

                                            <div className={'c-red-bar'}
                                                 style={{padding: '1rem', margin: '2rem 0', background: 'red'}}>
                                                <h3 style={{
                                                    margin: 0,
                                                    fontSize: '2rem',
                                                    color: '#fff'
                                                }}>{translate('lateRentalReturnText')} </h3>
                                            </div>

                                            <label>
                                                <input data-hj-allow checked={this.state.extend} onChange={(e) => {
                                                    this._setExtend(e)
                                                }} type={'checkbox'}/>
                                                {translate('calculatePeriod')}
                                            </label>
                                            {!!this.state.extend && this.props.rentalTotals.totals && this.props.rentalDetail.RentalItems &&
                                            <RentalTotals data={this.props.rentalTotals.totals} hideInput={true}/>}
                                        </div>
                                        }

                                        <RentalReturnActions
                                            submit={() => this._submit()}
                                            history={this.props.history}/>
                                        <RentalReturnErrors/>

                                    </section>
                                </div>

                            </main>

                            {this.props.popup && <RentalReturnPopup
                                late={rentalIsLate && !this.state.extend}
                                submit={() => this._submit(true)}
                                history={this.props.history}/>}


                        </PinValidationWrapper>
                    </>
                )
            } else return null
        } else {
            return <TinyLoader/>
        }
    }

    _submit = (overwrite) => {

        const rentalIsLate = this._rentalLateCheck()

        if (rentalIsLate && !this.state.extend && !overwrite) {
            this.props.dispatch(RentalReturnAction.togglePopup())
        } else {

            this.props.dispatch(RentalDetailAction.setLoader(true))

            if (this.state.extend) {
                this.props.dispatch(RentalExtendAction.submitMutation()).then(() => {
                    this.props.dispatch(RentalReturnAction.submit(overwrite)).then(() => {
                        this._handleResponse()
                    })
                })
            } else {
                this.props.dispatch(RentalReturnAction.submit(overwrite)).then(() => {
                    this._handleResponse()
                })
            }
        }


    }

    _handleResponse = () => {
        if (this.props.response && this.props.response.status.code === 200) {
            //this.props.dispatch(RentalTotalsAction.submitDeposit(this.props.rentalDetail.data.rental.uuid, false, true)).then(() => {
            ToTransactionCheck(this.props)
            this.props.dispatch(RentalDetailAction.setLoader(false))
            //})
        } else {

            this.props.dispatch(RentalDetailAction.setLoader(false))

            if (this.props.response) {
                this.props.dispatch(RentalReturnAction.togglePopup())
            }
        }
    }

    _setExtend = async (e) => {
        this.setState({
            extend: e.target.checked
        })

        if (e.target.checked) {

            const timeUnitList = await apiPartnerTimeUnitList().then((response) => {
                return response
            })

            const timeUnit = timeUnitList.data.items.data.find((timeUnitItem) => timeUnitItem.type.code === 'days');

            const amountResponse = await apiPartnerTimeUnitAmount({
                timeUnitId: timeUnit.id,
                startDate: moment(this.props.rentalDetail.data.rental.date_rent_start).format('YYYY-MM-DD HH:mm'),
                endDate: moment().format('YYYY-MM-DD HH:mm'),
                ceil: 1
            })

            const calculateResponse = await apiPartnerTimeUnitCalculate({
                timeUnitId: timeUnit.id,
                amount: amountResponse.data.items?.amount ? amountResponse.data.items?.amount : 1,
                startDate: moment(this.props.rentalDetail.data.rental.date_rent_start).format('YYYY-MM-DD'),
                startTime: moment(this.props.rentalDetail.data.rental.date_rent_start).format('HH:mm'),
            })

            this.props.dispatch(RentalExtendAction.setNewDate(moment(calculateResponse.data.status.message.endDate + ' ' + calculateResponse.data.status.message.endTime).format('YYYY-MM-DD HH:mm'))).then(() => {
                this.props.dispatch(RentalTotalsAction.requestTotals())
            })
        }
    }

    _rentalLateCheck = () => {

        if (this.props.environment.data.info?.charging_period_after_time && this.props.rentalDetail.data) {
            return moment(this.props.rentalDetail.data.rental.date_rent_end).isBefore(moment())
        } else return false
    }

}

export default connect(mapStateToProps, RentalDispatcher)(RentalMutationReturnItems);
