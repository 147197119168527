import React from 'react'
import Loader from "react-loader-spinner";


const TinyLoader = (props) => {

    const color = props.color ? props.color : '#eee';

    return (
        <div className={'tiny-loader'}>
            <Loader type="ThreeDots" color={color} height={80} width={80}/>
        </div>
    )

};

export default TinyLoader
