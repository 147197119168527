export const calculateScannedCount = (categoryId, rentalItems, giveItems, takeItems) => {

    let scannedCount = 0;
    const filteredRentalItems = rentalItems?.filter((item) => item.availabilityCategory.id === categoryId)
    const filteredGiveItems = giveItems.filter((item) => item.availabilityCategory.id === categoryId)
    const filteredTakeItems = takeItems?.filter((item) => item.availabilityCategory.id === categoryId)

    // Add current rental items including the the items added while scanning
    scannedCount += filteredRentalItems.length + filteredGiveItems.length

    // Remove the taken items
    if (filteredTakeItems) {
        scannedCount -= filteredTakeItems.length
    }

    return scannedCount

}