import {useEffect, useState} from "react";
import {apiPartnerItemDetailById} from "../api/partner/item/apiPartnerItemDetailById";
import BarcodeReader from "react-barcode-reader";
import {apiPartnerItemDetailByCode} from "../api/partner/item/apiPartnerItemDetailByCode";
import {cleanScanCode} from "../helpers/utils/cleanScanCode";
import translate from "../helpers/utils/translate";



export const ItemScanner = (props) => {

    const [itemCode, setItemCode] = useState('')
    const [item, setItem] = useState()
    const [error, setError] = useState(false)

    useEffect(() => {

        if (item) {
            props.onScanAction(item)
        }

    }, [item])

    useEffect(() => {

        if(props.scanCode){
            submitCode(props.scanCode)
        }

    }, [props.scanCode])

    const submitCode = async (scanCode) => {
        setError(false)
        let code = ''

        if (scanCode) {
            code = scanCode
        } else {
            code = itemCode
        }


        if (code.includes("t29.nl/i/")) {
            code = cleanScanCode(code)
            const idResponse = await apiPartnerItemDetailById(parseInt(code))

            switch (idResponse.data.status.code) {
                case(200):
                    setItem(idResponse.data?.items?.item)
                    break;
                case(404):
                    setError(translate('itemNotFound'))
                    break;
                default:
                    setError(translate('somethingWentWrong'))
                    break;
            }

        } else {
            code = cleanScanCode(code)
            const codeResponse = await apiPartnerItemDetailByCode(code)

            switch (codeResponse.data.status.code) {
                case(200):
                    setItem(codeResponse.data?.items?.item)
                    break;
                case(404):
                    setError(translate('itemNotFound'))
                    break;
                default:
                    setError(translate('somethingWentWrong'))
                    break;
            }

        }

        setItemCode('') // reset

    }

    return (

        <>
            {!props.disabled &&
            <BarcodeReader
                minLength={1}
                onScan={submitCode}
            />
            }

            <form className={'c-custom-input'} onSubmit={e => {
                e.preventDefault()
                submitCode();

            }} style={{marginBottom: '1.5rem'}}>

                {error &&
                <div className={'c-scan-error'}>
                    <small><span style={{color: '#ff8282', fontSize: '1rem'}}>{error}</span></small>
                </div>
                }

                <input data-hj-allow
                    type={'text'}
                    value={itemCode}
                    onChange={(e) => {
                        setError(false)
                        setItemCode(e.target.value)
                    }}
                    placeholder={translate('customInput')}
                />

                <button type={'submit'}>
                    <i className={'icon icon-arrow-right'}/>
                </button>

            </form>
        </>
    )
}
