import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {apiPartnerRentalDetail} from "../../../common/api/partner/rental/apiPartnerRentalDetail";
import {apiPartnerMutationHanldeInsurance} from "../../../common/api/partner/mutation/apiPartnerMutationInsurance";

export const loadRentalInsurances = createAsyncThunk(
    'rentalInsurances/load',
    async (rentalUuid, {getState}) => {
        try {

            const rentalDetailResponse = await apiPartnerRentalDetail(rentalUuid)
            const rentalContainer = rentalDetailResponse?.data?.items

            return {
                rentalContainer: rentalContainer,
            }

        } catch (e) {
            console.log(e)
        }
    }
)

export const submitRentalInsurance = createAsyncThunk(
    'rentalInsurances/submit',
    async (rentalUuid, {getState}) => {
        try {

            const rentalDetailResponse = await apiPartnerMutationHanldeInsurance({
                insuranceId: getState().insurances.selected,
                rentalUuid: rentalUuid
            })
            const rentalContainer = rentalDetailResponse?.data?.items

            return {
                rentalContainer: rentalContainer,
            }

        } catch (e) {
            console.log(e)
        }
    }
)

const initialState = {
    loading: false,
    insuranceItems: [],
    submitOption: 'default',
    submitting: false
}

export const rentalInsurancePageSlice = createSlice({
    name: 'rentalInsurance',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(loadRentalInsurances.pending, (state) => {
            Object.assign(state, initialState)
            state.loading = true
        })
        builder.addCase(loadRentalInsurances.fulfilled, (state, action) => {
            state.rentalContainer = action.payload.rentalContainer
        })

    },
})

export const {} = rentalInsurancePageSlice.actions

export const rentalInsurancePageReducer = rentalInsurancePageSlice.reducer
