import RentalExtendAction from "./RentalExtendAction";

export default class RentalExtendReducer {

    static initialState = {
        newDate: '',
        availability: null,
        submitted: false,
        error: false,
        checking: false,
        totals: null,
        response: null
    };

    static reducer(state = RentalExtendReducer.initialState, action) {

        switch (action.type) {


            case RentalExtendAction.SET_NEW_DATE:

                return {
                    ...state,
                    newDate: RentalExtendReducer.initialState.newDate,
                };

            case RentalExtendAction.SET_NEW_DATE + '_FINISHED':

                return {
                    ...state,
                    newDate: action.payload,
                };

            case RentalExtendAction.CHECK_AVAILABILITY:

                return {
                    ...state,
                    checking: true,
                    availability: RentalExtendReducer.initialState.availability
                };

            case RentalExtendAction.CHECK_AVAILABILITY + '_FINISHED':

                return {
                    ...state,
                    checking: false,
                    ...action.payload,
                };

            case RentalExtendAction.SUBMIT_MUTATION:
                return {
                    ...state,
                };
            case RentalExtendAction.SUBMIT_MUTATION + '_FINISHED':
                return {
                    ...state,
                    response: action.payload
                };

            case RentalExtendAction.REQUEST_TOTALS:

                return {
                    ...state,
                    submitted: RentalExtendReducer.initialState.submitted
                };

            case RentalExtendAction.REQUEST_TOTALS + '_FINISHED':

                return {
                    ...state,
                    totals: action.payload
                };

            case RentalExtendAction.RESET:

                return {
                    ...RentalExtendReducer.initialState
                };

            default:
                return state;
        }


    }
}