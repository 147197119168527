import React from "react";
import StatusCount from "./RentalReturnStatusCount";
import {connect} from "react-redux";
import RentalDispatcher from "../../../common/dispatchers/RentalDispatcher";
import RentalReturnAction from "../../../stores/rental/returnItems/RentalReturnItemsAction";
import translate from "../../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    rentalDetail: state.rentalDetail.data,
    ...state.rentalReturn
});


class RentalReturnActionsBar extends React.Component {

    render() {
        if (this.props.items.filter(item => item.valid.status.code !== 'locked').length) {
            return (
                <div className="c-order-handling__action-bar">
                    <div className="c-order-handling__action-bar__status">
                        <span>{translate('scanItems')}:</span>
                        <StatusCount/>
                    </div>
                    <div className="c-order-handling__action-bar__buttons">
                        <button onClick={() => this._handleSubmit()} className="btn btn--primary">
                            <span>{translate('takeItems')}</span></button>
                    </div>
                </div>
            )
        } else return null
    }

    _handleSubmit = () => {
        if (this.props.status.errors.length) {
            this.props.dispatch(RentalReturnAction.togglePopup())
        } else {
            this.props.submit(false);
        }
    }



}

export default connect(mapStateToProps, RentalDispatcher)(RentalReturnActionsBar);
