import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import translate from "../../../createSlice/common/helpers/utils/translate";

class SelectContactPerson extends React.Component {


    render() {

        return (
            <div className="c-form__row">
                <div className="c-form__group">

                    <select onChange={(e) => this.handleChange(e)} value={this.props.contactPerson?.id}>
                        <option>{translate('selectContactPerson')}</option>
                        {this.props.contactPersons.map((contactPerson) => {
                            return (
                                <option key={'contact-person-' + contactPerson?.id} value={contactPerson?.id}>{contactPerson?.email}</option>
                            )
                        })}

                        <option>{translate('newContactPerson')}</option>

                    </select>

                </div>
            </div>
        )

    }

    handleChange = (e) => {

        const selectedId = e.target.value
        const selectedContactPerson = this.props.contactPersons.filter((contactPerson) => contactPerson.id === parseInt(selectedId))[0]

        this.props.setFieldValue('contactPerson', selectedContactPerson)

    }


}

export default SelectContactPerson;
