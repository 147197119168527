import RentalModel from "../../common/models/RentalModel";
import fetchHelper from "../../common/helpers/fetchHelper";

export default class DashboardEffect {

    static async requestRentalsInPeriod(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return {
                ...values,
                pages: response.items.pages,
                data: Object.values(response.items.data).map((item) => {
                    return new RentalModel(item)
                })
            }

        } else return null

    }

    static async requestItemsInPeriod(values, dispatch) {
        const response = await fetchHelper(values, dispatch)

        if (response) {
            return {
                ...values,
                pages: response.items.pages,
                data: response.items.data
            }

        } else return null

    }


    static async requestWeeklyAvailability(values, dispatch) {

        const response = await fetchHelper(values, dispatch);
        if (response) {
            return response.items
        }else return null
    }


    static setRentalButton(value) {
        return value;
    }

    static setPage(pageNumber) {
        return pageNumber;
    }

    static reset() {
        return true;
    }

}
