import CustomerModel from "../../common/models/CustomerModel";
import fetchHelper from "../../common/helpers/fetchHelper";

export default class CustomerEffect {

    static async list(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return {
                success: true,
                data: response.items.data
            }
        } else return null

    }

    static async detail(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return {
                success: true,
                data: new CustomerModel(response.items)
            }
        } else return null

    }

}

