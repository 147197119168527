export const SvgInvalidCode = () => {

    return (
        <svg width="169px" height="152px" viewBox="0 0 169 152" version="1.1" xmlns="http://www.w3.org/2000/svg"
        style={{
            maxWidth: '12rem',
            width: '12rem',
            height: 'auto'
        }}
        >

            <g id="Mobian---Parkeerplaatsen" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="scan-fail-2">
                    <g id="Group-2" opacity="0.985423224" transform="translate(0.000000, 41.000000)" fill="#3FA2F7"
                       fillRule="nonzero">
                        <g id="qr-code">
                            <path
                                d="M0.0767345075,3.16886656 L0.0767345075,42.6194329 C0.0767345075,44.195431 1.3548693,45.4722117 2.93055463,45.4722117 L42.3964065,45.4722117 C43.9720918,45.4722117 45.2502263,44.195431 45.2502263,42.6194329 L45.2502263,35.1389902 C45.2502263,33.5629921 43.9720918,32.2862114 42.3964065,32.2862114 C40.8207211,32.2862114 39.5434747,33.5629921 39.5434747,35.1389902 L39.5434747,39.7675419 L5.78437453,39.7675419 L5.78437453,6.02075751 L39.5434747,6.02075751 L39.5434747,12.8885585 C39.5434747,14.4645566 40.8207211,15.7422251 42.3964065,15.7422251 C43.9720918,15.7422251 45.2502263,14.4645566 45.2502263,12.8885585 L45.2502263,3.16886656 C45.2502263,1.59286823 43.9720918,0.316087768 42.3964065,0.316087768 L2.93055463,0.316087768 C1.3548693,0.316087768 0.0767345075,1.59286823 0.0767345075,3.16886656 Z"
                                id="Path"/>
                            <path
                                d="M16.7440025,14.1244963 C15.167429,14.1244963 13.8910707,15.4012768 13.8910707,16.976387 L13.8910707,28.8119122 C13.8910707,30.3870225 15.167429,31.664691 16.7440025,31.664691 L28.5838468,31.664691 C30.1595321,31.664691 31.4376669,30.3870225 31.4376669,28.8119122 L31.4376669,16.976387 C31.4376669,15.4012768 30.1595321,14.1244963 28.5838468,14.1244963 L16.7440025,14.1244963 Z M25.730915,25.9600212 L19.5978224,25.9600212 L19.5978224,19.829166 L25.730915,19.829166 L25.730915,25.9600212 Z"
                                id="Shape"/>
                            <path
                                d="M42.3964065,65.8438761 L2.93055463,65.8438761 C1.3548693,65.8438761 0.0767345075,67.1206568 0.0767345075,68.6957671 L0.0767345075,108.148109 C0.0767345075,109.72322 1.3548693,111 2.93055463,111 L42.3964065,111 C43.9720918,111 45.2502263,109.72322 45.2502263,108.148109 L45.2502263,68.6957671 C45.2502263,67.1206568 43.9720918,65.8438761 42.3964065,65.8438761 L42.3964065,65.8438761 Z M39.5434747,105.29533 L5.78437453,105.29533 L5.78437453,71.5485458 L39.5434747,71.5485458 L39.5434747,105.29533 Z"
                                id="Shape"/>
                            <path
                                d="M68.6803281,45.4722117 L108.147068,45.4722117 C109.721865,45.4722117 111,44.195431 111,42.6194329 L111,3.16886656 C111,1.59286823 109.721865,0.316087768 108.147068,0.316087768 L68.6803281,0.316087768 C67.1046427,0.316087768 65.8265082,1.59286823 65.8265082,3.16886656 L65.8265082,42.6194329 C65.8265082,44.195431 67.1046427,45.4722117 68.6803281,45.4722117 L68.6803281,45.4722117 Z M71.5332598,6.02075751 L105.29236,6.02075751 L105.29236,39.7675419 L71.5332598,39.7675419 L71.5332598,6.02075751 Z"
                                id="Shape"/>
                            <path
                                d="M111,108.148109 L111,68.6957671 C111,67.1206568 109.721865,65.8438761 108.147068,65.8438761 L68.6803281,65.8438761 C67.1046427,65.8438761 65.8265082,67.1206568 65.8265082,68.6957671 L65.8265082,108.148109 C65.8265082,109.72322 67.1046427,111 68.6803281,111 L108.147068,111 C109.721865,111 111,109.72322 111,108.148109 Z M105.29236,105.29533 L71.5332598,105.29533 L71.5332598,71.5485458 L105.29236,71.5485458 L105.29236,105.29533 Z"
                                id="Shape"/>
                            <path
                                d="M108.147068,58.6830727 C109.721865,58.6830727 111,57.4054042 111,55.8302937 C111,54.2542956 109.721865,52.978403 108.147068,52.978403 L58.1159537,52.978403 L58.1159537,2.85277901 C58.1159537,1.27766852 56.8378191,0 55.2630221,0 C53.6855605,0 52.4083139,1.27766852 52.4083139,2.85277901 L52.4083139,52.978403 L14.5332469,52.978403 C12.9584497,52.978403 11.6803149,54.2551835 11.6803149,55.8302937 C11.6803149,57.4062921 12.9584497,58.6830727 14.5332469,58.6830727 L52.4083139,58.6830727 L52.4083139,108.148109 C52.4083139,109.72322 53.6855605,111 55.2630221,111 C56.8378191,111 58.1159537,109.72322 58.1159537,108.148109 L58.1159537,58.6830727 L108.147068,58.6830727 Z"
                                id="Path"/>
                            <path
                                d="M16.7440025,97.4046842 L28.5838468,97.4046842 C30.1595321,97.4046842 31.4376669,96.1270159 31.4376669,94.5519054 L31.4376669,82.7163805 C31.4376669,81.1403821 30.1595321,79.8644895 28.5838468,79.8644895 L16.7440025,79.8644895 C15.167429,79.8644895 13.8910707,81.1403821 13.8910707,82.7163805 L13.8910707,94.5519054 C13.8910707,96.1270159 15.167429,97.4046842 16.7440025,97.4046842 L16.7440025,97.4046842 Z M19.5978224,85.5691593 L25.730915,85.5691593 L25.730915,91.7000145 L19.5978224,91.7000145 L19.5978224,85.5691593 Z"
                                id="Shape"/>
                            <path
                                d="M94.332732,14.1244963 L82.4928878,14.1244963 C80.9172024,14.1244963 79.6390676,15.4012768 79.6390676,16.976387 L79.6390676,28.8119122 C79.6390676,30.3870225 80.9172024,31.664691 82.4928878,31.664691 L94.332732,31.664691 C95.9093055,31.664691 97.1865521,30.3870225 97.1865521,28.8119122 L97.1865521,16.976387 C97.1865521,15.4012768 95.9093055,14.1244963 94.332732,14.1244963 Z M91.4789121,25.9600212 L85.3475958,25.9600212 L85.3475958,19.829166 L91.4789121,19.829166 L91.4789121,25.9600212 Z"
                                id="Shape"/>
                            <path
                                d="M82.4928878,97.4046842 L94.332732,97.4046842 C95.9093055,97.4046842 97.1865521,96.1270159 97.1865521,94.5519054 L97.1865521,82.7163805 C97.1865521,81.1403821 95.9093055,79.8644895 94.332732,79.8644895 L82.4928878,79.8644895 C80.9172024,79.8644895 79.6390676,81.1403821 79.6390676,82.7163805 L79.6390676,94.5519054 C79.6390676,96.1270159 80.9172024,97.4046842 82.4928878,97.4046842 L82.4928878,97.4046842 Z M85.3475958,85.5691593 L91.4789121,85.5691593 L91.4789121,91.7000145 L85.3475958,91.7000145 L85.3475958,85.5691593 Z"
                                id="Shape"/>
                            <path
                                d="M2.35321777,52.9899455 L2.19955743,53.0254609 C0.665618047,53.3877185 -0.284766947,54.9237618 0.0767345075,56.4571416 C0.386720101,57.7703256 1.55826965,58.656436 2.85150397,58.656436 C3.06911545,58.656436 3.28850325,58.6306874 3.50877942,58.578302 L3.66155183,58.5427866 C5.19637937,58.1814168 6.14676437,56.6453735 5.78437453,55.1119937 C5.42376123,53.579502 3.88715716,52.6294635 2.35321777,52.9899455 Z"
                                id="Path"/>
                            <path
                                d="M41.8217343,21.1672106 L41.6680739,21.2036141 C40.1341346,21.563208 39.1828614,23.0983635 39.5425866,24.6326311 C39.8499072,25.9475907 41.0223449,26.8345892 42.3182439,26.8345892 C42.5331907,26.8345892 42.7516906,26.8097284 42.9719668,26.7573431 L43.124739,26.7218276 C44.6595665,26.3622337 45.6108399,24.8261904 45.2511147,23.2936985 C44.8913894,21.7603189 43.3547855,20.8076167 41.8217343,21.1672106 Z"
                                id="Path"/>
                        </g>
                    </g>
                    <g id="Group-3" transform="translate(68.000000, 0.000000)">
                        <circle id="Oval-Copy" stroke="#D41414" strokeWidth="5.6" fill="#FFFFFF" cx="50.5" cy="50.5"
                                r="47.7"/>
                        <g id="Path-2" transform="translate(13.596595, 13.246617)" fill="#D41414">
                            <path
                                d="M41.6185287,10.8783569 L41.6178171,33.0153569 L63.6655567,33.0161702 L63.6655567,41.6362036 L41.6178171,41.6353569 L41.6185287,63.7740169 L33.0338452,63.7740169 L33.0338171,41.6353569 L10.9868171,41.6362036 L10.9868171,33.0161702 L33.0338171,33.0153569 L33.0338452,10.8783569 L41.6185287,10.8783569 Z"
                                id="Combined-Shape"
                                transform="translate(37.326187, 37.326187) rotate(45.000000) translate(-37.326187, -37.326187) "/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )

}