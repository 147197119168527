import React from "react";
import {connect} from "react-redux";
import RentalDispatcher from "../../../common/dispatchers/RentalDispatcher";
import RentalReturnItemsAction from "../../../stores/rental/returnItems/RentalReturnItemsAction";
import translate from "../../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    ...state.rentalReturn
});

class RentalReturnScannedItems extends React.Component {
    render() {

        let title = 'Gescande items'

        if (this.props.title) {
            title = this.props.title
        }

        return (
            <div className="c-order-handling__scanned">
                <header>
                    <h4>{title}</h4>
                </header>
                <div className={'c-info-blocks__single__body c-info-blocks__single__body--scroll'}>
                    <table className={'c-table-standard'}>
                        <tbody>
                        <tr>
                            <th></th>
                            <th>{translate('productCodeNew')}</th>
                            <th>{translate('productCodeOld')}</th>
                            <th>{translate('category')}</th>
                            <th></th>
                        </tr>

                        {!!this.props.items.length && this.props.items.map((item) => {

                            let className = ''
                            let removalButton = true

                            if (item.valid.status.code === 'locked') {
                                className += 'is-locked'
                                removalButton = false
                            }

                            return (
                                <tr className={className} key={'item-' + item.id}>
                                    <td>{(!this.props.locked && removalButton) &&
                                    <i className="icon-cross c-button-icon"
                                       onClick={() => this._removeItem(item)}/>}</td>
                                    <td>{item.product_code}</td>
                                    <td>{item.product_code_prev}</td>
                                    <td>{item.category.name}</td>
                                    <td><ScannedStatusIcon valid={item.valid}/></td>
                                </tr>
                            )
                        })

                        }
                        </tbody>
                    </table>
                    {!this.props.items.length &&
                    <p className={'c-no-scanned-items'}>{translate('noItemsInRentalAlert')}</p>
                    }
                </div>

                {!this.props.locked && <h4 style={{marginTop: '2rem', textAlign: 'center'}}>{translate('scanItemsToReturn')}</h4>}


            </div>
        )
    }

    _removeItem = (item) => {
        this.props.dispatch(RentalReturnItemsAction.scanCode(item.product_code ? item.product_code : item.product_code_prev)).then(() => {
            this.props.dispatch(RentalReturnItemsAction.validateAndSetItem()).then(() => {
                this.props.dispatch(RentalReturnItemsAction.loadStatusFromItems())
            })
        })
    }
}

const ScannedStatusIcon = (props) => {

    switch (props.valid.status.code) {
        case(400):
            return <i className="icon icon-info"/>
        case('locked'):
            return <i className="icon icon-lock"/>
        default:
            return <i className="icon icon-check"/>
    }
}


export default connect(mapStateToProps, RentalDispatcher)(RentalReturnScannedItems);
