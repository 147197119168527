import React from "react";
import {connect} from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import BicycleItem from "../Items/BicycleItem";
import RentalCategoryAction from "../../../stores/rental/category/RentalCategoryAction";
import TinyLoader from "../General/TinyLoader";
import translate from "../../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    ...state.rentalItems,
    rentalPeriod: state.rentalPeriod,
    rentalDelivery: state.rentalDelivery,
});


class RentalBicycles extends React.Component {

    componentDidMount() {

        if (!this.props.loadFromInfo) {
            this.props.dispatch(RentalCategoryAction.getAvailableBicycles(false));
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.rentalPeriod !== prevProps.rentalPeriod || this.props.rentalDelivery.delivery.deliveryPickupLocation !== prevProps.rentalDelivery.delivery.deliveryPickupLocation) {
            this.props.dispatch(RentalCategoryAction.emptyCart()).then(() => {
                this.props.dispatch(RentalCategoryAction.getAvailableBicycles(false));
            })
        }
    }

    render() {

        if (this.props.bicycles && !this.props.bicyclesLoading) {

            return (
                <div className="c-info-blocks__row">
                    <div className="c-info-blocks__single">
                        <header className="c-subject-header c-subject-header--closed">
                            <h2>{translate('bicycles')}</h2>
                        </header>
                        <div className="c-info-blocks__single__body">
                            <div className="c-products">
                                {Object.values(this.props.bicycles)
                                    .filter(item => {
                                        return !item.inactive
                                    })
                                    .sort((a, b) => a.order > b.order ? 1 : -1)
                                    .map((bicycleData) => {

                                        return (
                                            <BicycleItem
                                                key={bicycleData.id + '-bicycle-key'}
                                                id={bicycleData.id}
                                                data={bicycleData}
                                            />
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <TinyLoader/>
        }


    }


}

export default connect(mapStateToProps)(RentalBicycles);
