import translate from "../../createSlice/common/helpers/utils/translate";

const DashboardRentalButtons = () => {
    return [
        {
            value: 'period',
            label: translate('changePeriod'),
            icon: 'calendar',
            keyPress: 'f2'
        },
        {
            value: 'swap',
            label: translate('exchangeItem'),
            icon: 'swap',
            keyPress: 'f3',
        },
        {
            value: 'issue',
            label:  translate('giveItems'),
            icon: 'swap',
            keyPress: 'f4',
        },
        {
            value: 'return',
            label: translate('takeItems'),
            icon: 'swap',
            keyPress: 'f5',
        },
    ]
}

export default DashboardRentalButtons