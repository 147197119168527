import {Link} from "react-router-dom";
import {routeEnum} from "../enums/routeEnum";
import {useAppSelector} from "../../app/hooks";
import translate from "../helpers/utils/translate";


export const RentalHeader = (props) => {

    const {slug} = useAppSelector(store => store.environment)

    return (
        <header className="c-subject-header c-subject-header--split">
            <h1>{props.pageTitle} <small style={{textDecoration: 'underline'}}>#{props.rentalId}</small></h1>

            <div className=" c-subject-header__buttons">
                <Link to={'/' + slug + '/dashboard/' + routeEnum.RENTAL + '/detail/' + props.rentalUuid}
                      className={'btn btn--small btn--outline'}>{translate('back')}</Link>
            </div>

        </header>
    )

}