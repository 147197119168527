import React from "react";
import {connect} from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import AccessoireItem from "../Items/AccessoireItem";
import RentalCategoryAction from "../../../stores/rental/category/RentalCategoryAction";
import TinyLoader from "../General/TinyLoader";

const mapStateToProps = (state) => ({
    accessoires: state.rentalItems.accessoires,
    rentalPeriod: state.rentalPeriod,
    rentalDelivery: state.rentalDelivery
});


class RentalAccessoires extends React.Component {

    componentDidMount() {
        if (!this.props.loadFromInfo) {
            this.props.dispatch(RentalCategoryAction.getAvailableAccessoires(true));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.rentalPeriod !== prevProps.rentalPeriod || this.props.rentalDelivery.delivery.deliveryPickupLocation !== prevProps.rentalDelivery.delivery.deliveryPickupLocation) {
            this.props.dispatch(RentalCategoryAction.emptyCart()).then(() => {
                this.props.dispatch(RentalCategoryAction.getAvailableAccessoires(true));
            })
        }
    }

    render() {

        if (this.props.accessoires && !this.props.accessoiresLoading) {
            return (
                <div className="c-info-blocks__row">
                    <div className="c-info-blocks__single">
                        <header className="c-subject-header c-subject-header--closed">
                            <h2>Accessoires</h2>
                        </header>
                        <div className="c-info-blocks__single__body">
                            <div className="c-products">
                                {Object.values(this.props.accessoires)
                                    .filter(item => {
                                        return !item.inactive
                                    })
                                    .sort((a, b) => a.order > b.order ? 1 : -1)
                                    .map((bicycleData) => {
                                        return (
                                            <AccessoireItem
                                                key={bicycleData.id + '-accessore-key'}
                                                id={bicycleData.id}
                                                data={bicycleData}
                                            />
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <TinyLoader/>
        }


    }


}

export default connect(mapStateToProps)(RentalAccessoires);
