import fetchHelper from "../../../common/helpers/fetchHelper";

export default class RentalCustomerDataEffect {


    static async validateForm(values) {
        return values
    }

    static async unsetFormCompletion() {
        return true
    }

    static async searchCustomerByEmail(values, dispatch) {
        const response = await fetchHelper(values, dispatch)

        if (response.items) {
            return {
                customerData: Object.values(response.items),
                formCompleted: false
            }
        } else return null

    }


    static async setSearchedCustomerData(values) {

        if (values.addresses) {
            values.address = Object.values(values.addresses).filter((item) => item.type_id === "2")[0]
        }

        values.newCustomer = 'false'
        values.customer = {}
        values.customer.id = values.id
        values.customer.document_type = values.document_type
        values.customer.document_number = values.document_number
        
        return {
            customerId: values.id,
            customerData: values,
            formCompleted: false
        }

    }


    static async submitMutation(values, dispatch) {

        const responseCustomerData = await fetchHelper(values.customerData, dispatch)

        if (responseCustomerData) {
            return responseCustomerData
        } else return null

    }

    static async reset() {
        return true;
    }

}

