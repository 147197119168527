import React from "react";
import {connect} from "react-redux";

import "react-datepicker/dist/react-datepicker.css";

import Select, {components} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import CityAction from "../../../stores/city/CityAction";
import RentalDeliveryAction from "../../../stores/rental/delivery/RentalDeliveryAction";
import TinyLoader from "../General/TinyLoader";
import RentalTotalsAction from "../../../stores/rental/totals/RentalTotalsAction";
import translate from "../../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    rentalDelivery: state.rentalDelivery,
    apiLocations: state.location,
    cities: state.city.list,
    environment: state.environment,
    partnerLocation: state.partnerLocation
});

const Option = props => {
    return (
        <div style={{color: props.data.color, backgroundColor: props.data.backgroundColour}}>
            <components.Option {...props} />
        </div>
    );
};


class RentalAccommodation extends React.Component {

    constructor(props) {
        super(props)

        this._changeCustomDeliveryLocation = this._changeCustomDeliveryLocation.bind(this);

        this.state = {
            defaultSet: false,
        }

    }

    componentDidMount() {
        this.props.dispatch(CityAction.cityList())
        this.props.dispatch(RentalDeliveryAction.getDeliveryLocations())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.rentalDelivery.delivery.deliveryPickupLocation && this.props.rentalDelivery.delivery.deliveryPickupLocation.address) {
            this.props.dispatch(RentalTotalsAction.setDiscountPercentage(this.props.rentalDelivery.delivery.deliveryPickupLocation.address.discount_percentage))
        }

        if (!this.props.rentalDelivery.delivery.locationSet) {
            this.setDefaultAddress()
        }


    }

    setDefaultAddress = () => {


        if (this.props.defaultAddress && this.props.rentalDelivery.locations && !this.state.defaultSet) {

            this.setState({
                defaultSet: true
            })

            const deliveryAddresses = Object.values(this.props.rentalDelivery.locations)

            let defaultDeliveryAddressId = false

            if (this.props.partnerLocation.detail?.default_holiday_address_id) {
                defaultDeliveryAddressId = parseInt(this.props.partnerLocation.detail.default_holiday_address_id);
            }

            if (defaultDeliveryAddressId && deliveryAddresses.length) {

                const defaultDeliveryAddress = deliveryAddresses.find((item) => item.address.id === defaultDeliveryAddressId)

                if (defaultDeliveryAddress && this.props.rentalDelivery.locations) {

                    this._changeDeliveryLocation(defaultDeliveryAddress, true)
                }
            }

        }
    }

    render() {


        const deliveryAddresses = this.props.rentalDelivery.locations

        if (deliveryAddresses) {

            // Vars
            const deliveryLocations = this._formatDeliveryLocations(deliveryAddresses)


            const deliverySubLocations = this.props.rentalDelivery.subDeliveryLocations
            const filterOptions = (candidate, input) => {
                if (candidate.data.searchValue && input !== '') {

                    const searchValuesSplitted = candidate.data.searchValue.split(" ")
                    const inputSplitted = input.toLowerCase().split(" ")
                    let result = true

                    inputSplitted.forEach(input => {
                        const exist = searchValuesSplitted.some(searchValue => (searchValue.includes(input)))

                        if (!exist) {
                            result = false
                        }
                    })

                    return result

                } else {
                    return true;
                }
            };


            return (

                <div className="c-info-blocks__row">
                    <div className="c-info-blocks__single">
                        <header className="c-subject-header c-subject-header--closed">
                            <h2>{translate('selectHolidayAddress')}</h2>
                        </header>
                        <div className="c-info-blocks__single__body">

                            <div className="c-info-blocks__row">
                                <div className="c-info-blocks__single">

                                    <div className="c-select-search">

                                        <Select
                                            placeholder={translate('searchAddresses')}
                                            options={deliveryLocations}
                                            components={{Option}}
                                            onChange={(value) => this._changeDeliveryLocation(value)}
                                            value={this.props.rentalDelivery.delivery.deliveryPickupLocation}
                                            filterOption={filterOptions}
                                        />


                                        {deliverySubLocations &&
                                        <>
                                            <div style={{marginTop: '2rem'}}>
                                                <Select
                                                    ref={(select) => {
                                                        this.selectSpecific = select;
                                                    }}
                                                    placeholder={translate('selectSpecificAddress')}
                                                    options={deliverySubLocations}
                                                    onChange={(value) => this._changeSubDeliveryLocation(value)}
                                                    value={this.props.rentalDelivery.delivery.deliveryPickupSubLocation}
                                                />
                                            </div>

                                            {this.props.rentalDelivery.delivery.deliveryPickupSubLocation && this.props.rentalDelivery.delivery.deliveryPickupSubLocation.numbers.length !== 1 &&
                                            <div style={{marginTop: '2rem'}}>
                                                <Select
                                                    ref={(select2) => {
                                                        this.selectNumber = select2;
                                                    }}
                                                    placeholder={translate('selectNumber')}
                                                    options={this.props.rentalDelivery.delivery.deliveryPickupSubLocation.numbers}
                                                    onChange={(value) => this._changeSubLocationNumber(value)}
                                                    value={this.props.rentalDelivery.delivery.subLocationNumber}
                                                />
                                            </div>
                                            }
                                        </>
                                        }

                                        {this.props.rentalDelivery.delivery.deliveryPickupLocation && this.props.rentalDelivery.delivery.deliveryPickupLocation.value === 'custom' &&

                                        <>
                                            <div className="custom-row custom-row-1">
                                                <input data-hj-allow type={'text'} className={'custom-location'}
                                                       ref={(custom) => {
                                                           this.customInput = custom;
                                                       }}
                                                       value={this.props.rentalDelivery.delivery.custom.street}
                                                       onChange={(event) => this.props.dispatch(RentalDeliveryAction.setCustomLocationValue({street: event.target.value}))}
                                                       placeholder={translate('streetName')}/>

                                                <input data-hj-allow type={'text'} className={'custom-location'}
                                                       value={this.props.rentalDelivery.delivery.custom.number}
                                                       onChange={(event) => this.props.dispatch(RentalDeliveryAction.setCustomLocationValue({number: event.target.value}))}
                                                       placeholder={translate('houseNumberAddition')}/>
                                            </div>

                                            <div className="custom-row custom-row-2">

                                                <input data-hj-allow type={'text'} className={'custom-location'}
                                                       value={this.props.rentalDelivery.delivery.custom.zip_code}
                                                       onChange={(event) => this.props.dispatch(RentalDeliveryAction.setCustomLocationValue({zip_code: event.target.value}))}
                                                       placeholder={translate('zipcode')}/>

                                                <CreatableSelect
                                                    isClearable
                                                    placeholder={translate('selectCity')}
                                                    value={this.props.rentalDelivery.delivery.custom.city}
                                                    formatCreateLabel={() => translate('addYourOwnCity')}
                                                    className={'custom-location custom-location-select'}
                                                    onChange={(value) => this.props.dispatch(RentalDeliveryAction.setCustomLocationValue({city: value}))}
                                                    options={this._formatCities(this.props.cities)}
                                                />

                                            </div>
                                        </>

                                        }


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )
        } else return <TinyLoader/>

    }

    _changeDeliveryLocation(location, init = false) {


        this.props.dispatch(RentalDeliveryAction.setDeliveryLocation(location)).then(() => {

            if (location.value === 'custom') {
                this.customInput.focus()
            }

            if (this.props.rentalDelivery.locations) {

                const selectedLocationData = this.props.rentalDelivery.locations[this.props.rentalDelivery.delivery.deliveryPickupLocation.value];

                if (selectedLocationData && selectedLocationData.items && selectedLocationData.items.length) {
                    this.props.dispatch(RentalDeliveryAction.formatSubDeliveryLocations(selectedLocationData.items)).then(() => {

                        if (!init) {
                            this.selectSpecific.focus()
                        }

                    })
                }
            }
        })

    }

    _changeSubDeliveryLocation(location) {

        this.props.dispatch(RentalDeliveryAction.setSubDeliveryLocation(location)).then(() => {


            if (this.props.rentalDelivery.delivery.deliveryPickupSubLocation.numbers && this.props.rentalDelivery.delivery.deliveryPickupSubLocation.numbers.length === 1) {

                this._changeSubLocationNumber(this.props.rentalDelivery.delivery.deliveryPickupSubLocation.numbers[0].value)

            }

            if (this.props.rentalDelivery.delivery.deliveryPickupSubLocation && this.props.rentalDelivery.delivery.deliveryPickupSubLocation.numbers.length !== 1) {
                this.selectNumber.focus()
            }
        })
    }

    _changeSubLocationNumber(number) {
        this.props.dispatch(RentalDeliveryAction.setSubLocationNumber(number))
    }

    _changeCustomDeliveryLocation(event) {

        const deliveryLocation = this.props.rentalDelivery.delivery.deliveryPickupLocation
        deliveryLocation.customLocationInput = event.target.value
    }


    _formatDeliveryLocations = (deliveryAddresses) => {

        let options = Object.keys(deliveryAddresses).map((itemId) => {

            const item = deliveryAddresses[itemId]
            item.value = itemId

            if (item.address.name) {
                item.label = item.address.name.trim()

                if (item.address.street) {
                    item.label += ' - ' + item.address.street.trim()

                    if (item.address.number) {
                        item.label += ' ' + item.address.number.trim()

                    }
                }

            } else {
                item.label = item.address.street + ' ' + item.address.number
            }

            item.searchValue = ''

            if (item.address.name) {
                item.searchValue += ' ' + item.address.name.trim()
            }

            if (item.address.street) {
                item.searchValue += ' ' + item.address.street.trim()
            }

            if (item.address.number) {
                item.searchValue += ' ' + item.address.number.trim()
            }

            if (item.address.zipcode) {
                item.searchValue += ' ' + item.address.zipcode.trim()
            }

            if (item.label) {
                item.searchValue += ' ' + item.label.trim()
            }

            if (item.items.length) {
                item.items.forEach((subItem) => {


                    if (subItem.name) {
                        item.searchValue += ' ' + subItem.name.trim()
                    }

                    if (subItem.street || subItem.number) {
                        if (subItem.street && subItem.number) {
                            item.searchValue += ' ' + subItem.street.trim() + ' ' + subItem.number.trim()
                        } else if (subItem.street) {
                            item.searchValue += ' ' + subItem.street.trim()
                        } else {
                            item.searchValue += ' ' + subItem.number.trim()
                        }
                    }

                    if (subItem.type === 'range') {
                        const start = parseInt(subItem.range_start)
                        const end = parseInt(subItem.range_end)
                        let increaseNumber

                        if (subItem.range_side === 'ascending') {
                            increaseNumber = 1
                        } else {
                            increaseNumber = 2
                        }

                        for (let i = start; i < end; i += increaseNumber) {
                            item.searchValue += ' ' + i
                        }
                    }


                })
            }

            item.searchValue = item.searchValue.trim().toLowerCase()

            return item

        })


        options = options.sort((a, b) => a.label.localeCompare(b.label))

        options.push(
            {
                value: 'none',
                label: translate('noHolidayAddress'),
                color: '#FF7700',
                backgroundColour: '#f3f6fa'
            },
        )

        options.push({
            value: 'custom',
            label: translate('alternativeHolidayAddress'),
            color: '#FF7700',
            backgroundColour: '#f3f6fa'
        })

        return options
    }

    _formatCities = (cities) => {
        return cities.map((city) => {
            city.value = city.id
            city.label = city.name
            return city
        })

    }

}

export default connect(mapStateToProps)(RentalAccommodation);
