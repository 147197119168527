import React from 'react'
import ScannedItems from "./components/ScannedItems";
import Scanner from "../../common/components/General/Scanner";
import CustomBarcodeInput from "../../common/components/General/CustomBarcodeInput";

class ScanItems extends React.Component {


    render() {
        return (
            <main style={{padding: '2rem 0'}}>
                <CustomBarcodeInput type={'items'}/>
                <Scanner type={'items'}/>
                <div className="o-container o-container--primary">
                    <ScannedItems/>
                </div>
            </main>
        )
    }

}

export default ScanItems