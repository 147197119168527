import {eventScanPageSubmit, setDisplay, setCode} from "../eventScanPageSlice";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";

export const EventScanManualDisplay = () => {

    const dispatch = useAppDispatch();
    const {code} = useAppSelector((state) => state.eventScanPage)

    return (
        <>
            <div className="c-scan__qr c-scan__qr--small">
                <h2>Enter code</h2>
                <form className="c-auth__form c-form" method={'post'} onSubmit={(e) => {
                    dispatch(eventScanPageSubmit())
                    e.preventDefault()
                }}>
                    <div className="c-form__row">
                        <div className="c-form__group">
                            <label htmlFor="password">Code</label>
                            <input data-hj-allow
                                value={code} type="text"
                                onChange={(e) => dispatch(setCode(e.target.value))}
                            />
                        </div>
                    </div>
                </form>
            </div>

            <div className="c-scan__on-demand">
                <button type='submit' className="btn btn--small" onClick={() => {
                    dispatch(eventScanPageSubmit())
                }}>
                    Process code
                </button>
                <a className="back-btn" href="#" onClick={() => dispatch(setDisplay('default'))}>
                    <h2>
                        <u>Back</u>
                    </h2>
                </a>
            </div>

        </>
    )

}
