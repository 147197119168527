import RouteEnum from "./RouteEnum";
import IsCurrentPartnerLocation from "../helpers/IsCurrentPartnerLocation";
import GetContrast from "../helpers/GetContrast";
import translate from "../../createSlice/common/helpers/utils/translate";

const RentalSubjectButtons = (props) => {

    const isCurrentPartnerLocation = IsCurrentPartnerLocation(props)
    const primaryColorLight = props.environment.data.info.primary_color_light
    const primaryColorDark = props.environment.data.info.primary_color_dark

    if (isCurrentPartnerLocation) {
        return [
            {
                text: translate('giveItems'),
                background: primaryColorLight,
                color: GetContrast(primaryColorLight),
                link: '/' + props.environment.slug + RouteEnum.RentalIssueItems + '/' + props.rentalUuid,
                hideOnStatus: ['completed', 'cancelled']
            },
            {
                text: translate('takeItems'),
                background: primaryColorLight,
                color: GetContrast(primaryColorLight),
                link: '/' + props.environment.slug + RouteEnum.RentalReturnItems + '/' + props.rentalUuid,
                hideOnStatus: ['completed', 'cancelled']
            },
            {
                text: translate('exchangeItem'),
                background: primaryColorLight,
                color: GetContrast(primaryColorLight),
                link: '/' + props.environment.slug + RouteEnum.RentalSwapItems + '/' + props.rentalUuid,
                hideOnStatus: ['completed', 'cancelled']
            },
        ]
    } else {
        return [
            {
                text: translate('exchangeItem'),
                background: primaryColorLight,
                color: GetContrast(primaryColorLight),
                link: '/' + props.environment.slug + RouteEnum.RentalSwapItems + '/' + props.rentalUuid,
                hideOnStatus: ['completed', 'cancelled']
            },
        ]
    }

}

export default RentalSubjectButtons