import React from 'react'
import DashboardHeader from "../components/General/Header";

class DashboardWrapper extends React.Component {

    render() {
        return (
            <>
                <DashboardHeader/>
                {this.props.children}
            </>
        )
    }

}

export default DashboardWrapper