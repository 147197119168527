import {useAppDispatch} from "../../../../app/hooks";
import {setOpeningTimeDayData, setOpeningTimeData, removeOpeningTime} from "../settingsPageSlice";
import {useState} from "react";
import moment from "moment";
import translate from "../../../../common/helpers/utils/translate";

export const SettingsOpeningTime = (props) => {

    const dispatch = useAppDispatch();
    const {openingTime} = props
    const [active, setActive] = useState(false);

    if (openingTime) {

        return (

            <div className="c-info-blocks__single__body"
                 style={{background: '#fff', padding: '1rem', margin: '2rem 0', borderTop: '1px solid #EEEEEE'}}>

                <header className={'c-collapse-header'} onClick={() => setActive(!active)} style={{
                    background: '#eee',
                    display: 'flex',
                    padding: '1rem',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderRadius: '.5rem',

                }}>
                    <h5 style={{margin: 0}}>{openingTime.name}</h5>
                    <i style={{fontSize: '1rem'}} className={'icon icon-arrow-down'}/>
                </header>

                {active &&
                <div className={'c-opening-body'} style={{marginTop: '1rem'}}>

                    <div className="c-form__row">
                        <div className={'c-form__group'}>
                            <label htmlFor="">{translate('designation')}</label>
                            <input
                                type={'text'}
                                value={openingTime.name}
                                required
                                onChange={(e) => {
                                    dispatch(setOpeningTimeData({
                                        i: props.i,
                                        openingTime: {
                                            ...openingTime,
                                            name: e.target.value
                                        }
                                    }))
                                }}
                            />
                        </div>
                        <div className={'c-form__group'}>
                            <label htmlFor="">{translate('fromDate')}</label>
                            <input
                                type={'date'}
                                value={moment(openingTime.dateFrom).format('YYYY-MM-DD')}
                                required
                                onChange={(e) => {
                                    dispatch(setOpeningTimeData({
                                        i: props.i,
                                        openingTime: {
                                            ...openingTime,
                                            dateFrom: e.target.value
                                        }
                                    }))
                                }}
                            />
                        </div>
                        <div className={'c-form__group'}>
                            <label htmlFor="">{translate('toDate')}</label>
                            <input
                                type={'date'}
                                value={moment(openingTime.dateTo).format('YYYY-MM-DD')}
                                required
                                onChange={(e) => {
                                    dispatch(setOpeningTimeData({
                                        i: props.i,
                                        openingTime: {
                                            ...openingTime,
                                            dateTo: e.target.value
                                        }
                                    }))
                                }}
                            />
                        </div>
                    </div>

                    {!!openingTime.openingDays && Object.entries(openingTime.openingDays).map(([dayKey, day]) => {

                        return (
                            <div className="c-info-blocks__row" key={props.i + '-' + dayKey}>
                                <div className="c-info-blocks__single">
                                    <div className={'c-form__group'}>

                                        <label htmlFor="">{dayKey}</label>

                                        <div className="c-form__row">
                                            <div className="c-form__group">
                                                <select
                                                    value={day.isOpen}
                                                    onChange={(e) => {
                                                        dispatch(setOpeningTimeDayData({
                                                            i: props.i,
                                                            dayKey: dayKey,
                                                            day: {
                                                                ...day,
                                                                isOpen: Number(e.target.value)
                                                            }
                                                        }))
                                                    }}>
                                                    <option value={0}>{translate('closed')}</option>
                                                    <option value={1}>{translate('open')}</option>
                                                </select>
                                            </div>
                                            <div className="c-form__group">
                                                <select
                                                    value={day.blockTime}
                                                    onChange={(e) => {
                                                        dispatch(setOpeningTimeDayData({
                                                            i: props.i,
                                                            dayKey: dayKey,
                                                            day: {
                                                                ...day,
                                                                blockTime: Number(e.target.value)
                                                            }
                                                        }))
                                                    }}>
                                                    <option value={0}>{translate('normalTimes')}</option>
                                                    <option value={1}>{translate('timeBlocks')}</option>
                                                </select>
                                            </div>
                                            <div className="c-form__group">

                                                <div className="c-form__row">
                                                    <input data-hj-allow
                                                        value={day.openFrom}
                                                        type="time"
                                                        onChange={(e) => {
                                                            dispatch(setOpeningTimeDayData({
                                                                i: props.i,
                                                                dayKey: dayKey,
                                                                day: {
                                                                    ...day,
                                                                    openFrom: e.target.value
                                                                }
                                                            }))
                                                        }}
                                                    />
                                                    <input data-hj-allow
                                                        value={day.openTo}
                                                        type="time"
                                                        onChange={(e) => {
                                                            dispatch(setOpeningTimeDayData({
                                                                i: props.i,
                                                                dayKey: dayKey,
                                                                day: {
                                                                    ...day,
                                                                    openTo: e.target.value
                                                                }
                                                            }))
                                                        }}
                                                    />
                                                </div>

                                                {!!day.blockTime &&
                                                <div className="c-form__row">
                                                    <input data-hj-allow
                                                        value={day.blockFrom}
                                                        type="time"
                                                        onChange={(e) => {
                                                            dispatch(setOpeningTimeDayData({
                                                                i: props.i,
                                                                dayKey: dayKey,
                                                                day: {
                                                                    ...day,
                                                                    blockFrom: e.target.value
                                                                }
                                                            }))
                                                        }}
                                                    />
                                                    <input data-hj-allow
                                                        value={day.blockTo}
                                                        type="time"
                                                        onChange={(e) => {
                                                            dispatch(setOpeningTimeDayData({
                                                                i: props.i,
                                                                dayKey: dayKey,
                                                                day: {
                                                                    ...day,
                                                                    blockTo: e.target.value
                                                                }
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                    <button
                        type={'button'}
                        onClick={() => dispatch(removeOpeningTime({i: props.i}))}
                        className="c-order-handling__number c-order-handling__number__small"
                        style={{background: 'red', border: '0', marginTop: '1.5rem'}}
                    >
                        <i className="icon icon-cross"/>
                    </button>

                </div>
                }

            </div>
        )
    } else return null
}
