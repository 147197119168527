
export const formatCustomerString = (rental) => {

    let string = ''

    if (rental.customer.type === 'private') {
        string += rental.customer_private_person?.first_name ? rental.customer_private_person?.first_name : ''
        rental.customer_private_person?.insertion ? string += ' ' + rental.customer_private_person.insertion : string += ''
        string += ' ' + rental.customer_private_person?.last_name
    } else {
        string = rental.customer_company?.name ? rental.customer_company.name : ''
    }

    return string
}