import React from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router";
import GetContrast from "../../helpers/GetContrast";
import ScanDispatcher from "../../dispatchers/ScanDispatcher";
import StripScannedUrl from "../../helpers/StripScannedUrl";
import translate from "../../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    environment: state.environment,
    authentication: state.authentication,
    returnItems: state.rentalReturn
});

class CustomBarcodeInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customValue: ''
        }
    }

    render() {

        const {light} = this.props.environment.colors;

        return (
            <form className={'c-custom-input'} onSubmit={e => this._customScan(e)}>

                <input data-hj-allow
                    type={'text'}
                    value={this.state.customValue}
                    onChange={(e) => this._changeCustomValue(e)}
                    placeholder={translate('customInput')}
                />

                <button type={'submit'} style={{backgroundColor: light}}>
                    <i style={{color: GetContrast(light)}} className={'icon icon-arrow-right'}/>
                </button>

            </form>
        )
    }

    _customScan = (e) => {
        e.preventDefault();
        const slug = this.props.environment.slug;
        const code = StripScannedUrl(this.state.customValue);

        if (this.props.type === 'rental') {

            this.props.history.push('/' + slug + '/dashboard/rental/detail/' + code)

        } else {

            this.props.scan(this.props.type, this.state.customValue);

            this.setState({
                customValue: ''
            })
        }
    };

    _changeCustomValue = (e) => {
        this.setState({
            customValue: e.target.value
        })
    }

}

export default  connect(mapStateToProps, ScanDispatcher)(withRouter(CustomBarcodeInput));

