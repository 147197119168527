import React from 'react'
import RouteEnum from "../../constants/RouteEnum";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import KeyboardEventHandler from 'react-keyboard-event-handler';
import AuthenticationAction from "../../../stores/authentication/AuthenticationAction";
import DashboardAction from "../../../stores/dashboard/DashboardAction";
import {toggleSearchBar} from "../../../createSlice/features/pages/searchPage/searchPageSlice";

const mapStateToProps = (state) => ({
    environment: state.environment,
    authentication: state.authentication,
});

class FunctionKeys extends React.Component {

    _handleKeyDown = (key) => {

        switch (key) {

            case('f1'):
                this.props.history.push('/' + this.props.environment.slug + RouteEnum.RentalCreate)
                break;
            case('f2'):
                this.props.dispatch(DashboardAction.setRentalButton('extend'))
                break;
            case('f3'):
                this.props.dispatch(DashboardAction.setRentalButton('swap'))
                break;
            case('f4'):
                this.props.dispatch(DashboardAction.setRentalButton('issue'))
                break;
            case('f5'):
                this.props.dispatch(DashboardAction.setRentalButton('return'))
                break;
            case('f6'):
                this.props.dispatch(toggleSearchBar())
                break;
            case('f7'):
                break;
            case('f8'):
                break;
            case('f9'):
                break;
            case('f10'):
                break;
            case('f11'):
                break;
            case('f12'):
                localStorage.removeItem('authToken')
                this.props.dispatch(AuthenticationAction.unsetUser())
                break;
            default:
                break;
        }
    }

    render() {
        return <KeyboardEventHandler
            handleKeys={['f1', 'f2', 'f3', 'f4', 'f5', 'f6', 'f7', 'f8', 'f8', 'f10', 'f11', 'f12']}
            onKeyEvent={(key, e) => this._handleKeyDown(key)}/>
    }


}

export default connect(mapStateToProps)(withRouter(FunctionKeys));