import {calculateScannedCount} from "../helpers/calculate/calculateScanCount";


const calculateCurrentAmount = (orderLine, categoryMutations) => {

    const categoryMutation = categoryMutations?.find((categoryMutation) => categoryMutation.categoryId === orderLine.categoryId)

    if (categoryMutation) {
        return categoryMutation.quantity
    } else return orderLine.quantity

}


export const RentalOrderLinesToGive = (props) => {

    const {rentalOrderLines} = props

    return (
        <div className="c-order-handling__list">

            {rentalOrderLines.filter((rentalOrderLine) => rentalOrderLine.type === 'category')
                .map((rentalOrderLine) => {

                    const currentSelectedQuantity = calculateCurrentAmount(rentalOrderLine, props.categoryMutations)
                    const scannedCount = calculateScannedCount(rentalOrderLine.categoryId, props.rentalItems, props.giveItems, props.takeItems)
                    const isComplete = scannedCount === currentSelectedQuantity
                    const className = isComplete ? 'c-order-handling__list__single is-complete' : 'c-order-handling__list__single'

                    return (
                        <div className={className}
                             key={'rentalItem-' + rentalOrderLine.categoryId}>
                            <div className="c-order-handling__list__single__wrap">
                                <h6>{rentalOrderLine.category.alternativeLabel ? rentalOrderLine.category.alternativeLabel : rentalOrderLine.category.name}</h6>
                            </div>

                            <div className={'c-order-handling-issue-numbers'}>

                                <div className="c-order-handling__list__single__amount">

                                    {!props.disableManualCategoryMutations &&
                                    <button
                                        onClick={() => props.mutateCategoryAmountAction({
                                            categoryId: rentalOrderLine.categoryId,
                                            quantity: currentSelectedQuantity - 1
                                        })}
                                        disabled={!(currentSelectedQuantity > scannedCount)}
                                    >

                                        <i className="icon icon-minus"/>
                                    </button>
                                    }

                                    <span>{currentSelectedQuantity}</span>

                                    {!props.disableManualCategoryMutations &&
                                    <button
                                        onClick={() => props.mutateCategoryAmountAction({
                                            categoryId: rentalOrderLine.categoryId,
                                            quantity: currentSelectedQuantity + 1
                                        })}
                                        disabled={currentSelectedQuantity === rentalOrderLine.quantity}
                                    >
                                        <i className="icon icon-plus"/>
                                    </button>
                                    }

                                </div>

                                {isComplete &&
                                <div
                                    className="c-order-handling__number c-order-handling__number--check c-order-handling__number__small">
                                    <i className="icon icon-check"/>
                                </div>
                                }

                                {!isComplete &&
                                <div className="c-order-handling__number c-order-handling__number__small">
                                    <span>{scannedCount}</span>
                                </div>
                                }
                            </div>

                        </div>
                    )
                })}

        </div>
    )
}