import React, {useEffect} from "react";
import {loadSettings} from "./settingsPageSlice";
import {SettingsOpeningTimes} from "./components/SettingsOpeningTimes";
import {useDispatch, useSelector} from "react-redux";
import Scanner from "../../../../common/components/General/Scanner";
import translate from "../../../common/helpers/utils/translate";

export const SettingsPageContainer = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(loadSettings())

    }, [dispatch])

    const {openingTimeSettings} = useSelector((state) => state.settingsPage)
    const {openingTimes} = openingTimeSettings

    return (
        <div className="o-container o-container--primary">

            <Scanner type={'rental'}/>

            <header className="c-subject-header c-subject-header--split">
                <h1>{translate('settings')}</h1>
            </header>

            {openingTimes && <SettingsOpeningTimes />}

        </div>
    )
}