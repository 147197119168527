import LocationAction from './LocationAction'

export default class LocationReducer {

    static initialState = {
        detail: null,
        clientId: null,
        openingTimes: null,
        submitOpeningTimes: {
            loading: false,
            response: null
        },
        list: null
    };

    static reducer(state = LocationReducer.initialState, action) {


        switch (action.type) {

            case LocationAction.REQUEST_DETAIL:
                return {
                    ...state,
                };
            case LocationAction.REQUEST_DETAIL + '_FINISHED':

                if (action.payload) {
                    return {
                        ...state,
                        clientId: action.payload.client_id,
                        detail: action.payload.location,
                        openingTimes: action.payload.opening_times,

                    };
                } else return state

            case LocationAction.SUBMIT_OPENING_TIMES:
                return {
                    ...state,
                    submitOpeningTimes: {
                        loading: true,
                        response: null
                    }
                };
            case LocationAction.SUBMIT_OPENING_TIMES + '_FINISHED':
                return {
                    ...state,
                    submitOpeningTimes: {
                        ...state.submitOpeningTimes,
                        loading: false,
                        response: action.payload
                    }

                };

            case LocationAction.REQUEST_LIST:
                return {
                    ...state,
                };
            case LocationAction.REQUEST_LIST + '_FINISHED':

                return {
                    ...state,
                    list: action.payload
                };

            default:
                return state;
        }
    }
}
