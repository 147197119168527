const DisplayModuleByEnvironment = (module, environment) => {

    const {info} = environment.data

    if (!info.lite_environment) {
        return true
    }

    switch (module) {
        case 'transactions':
            return !info.hide_transactions
        case 'stock':
            return !info.hide_stock;
        case 'availability':
            return !info.hide_availability;
        case('rentalCreate'):
            return !info.hide_rental_create;
        case 'rentalMutations':
            return !info.hide_rental_mutations;
        case 'financial':
            return !info.hide_financial
        default:
            return true

    }

}

export default DisplayModuleByEnvironment
