import PaymentAction from "./PaymentAction";

export default class PaymentReducer {

    static initialState = {
        methodList: null,
        statusList: null,
    };

    static reducer(state = PaymentReducer.initialState, action) {


        switch (action.type) {


            case PaymentAction.REQUEST_STATUS_LIST:

                return {
                    ...state,
                    statusList: PaymentReducer.initialState.statusList,
                };

            case PaymentAction.REQUEST_STATUS_LIST + '_FINISHED':

                return {
                    ...state,
                    statusList: action.payload.response,
                };

            case PaymentAction.REQUEST_METHOD_LIST:

                return {
                    ...state,
                    methodList: PaymentReducer.initialState.methodList,
                };

            case PaymentAction.REQUEST_METHOD_LIST + '_FINISHED':

                return {
                    ...state,
                    methodList: action.payload.response,
                };


            default:
                return state;
        }


    }
}