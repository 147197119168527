import React from 'react';
import translate from "../../../createSlice/common/helpers/utils/translate";

class QrPopup extends React.Component {

    render() {

        const props = this.props

        return (
            <div className="c-modal c-modal--qr">
                <div className="c-modal__bg"/>
                <div className="c-modal__content c-modal__content--text">
                    <button className="c-modal__close" onClick={props.close}><i className="icon icon-cross"/>
                    </button>
                    <div className="c-modal__header"><h2>Vendit - QR code</h2></div>
                    <div className="c-modal__body"><p>{translate('venditText')}</p>
                    </div>

                    <img src={this.props.qrUrl} alt={'qr code'}/>

                    <button onClick={() => navigator.clipboard.writeText('\\000026' + this.props.qrSourceUrl)}>{translate('copyLink')}</button>

                </div>
            </div>
        )
    }

}

export default QrPopup