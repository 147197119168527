import Loader from "react-loader-spinner";
import translate from "../helpers/utils/translate";

export const RentalActionBar = (props) => {

    let totalCount = 0
    let scannedCount = 0

    props.orderLines.filter((orderLine) => orderLine.type === 'category').forEach((orderLine) => {

        const categoryMutation = props.categoryMutations.find((categoryMutation) => categoryMutation.categoryId === orderLine.categoryId)

        if (categoryMutation) {
            totalCount += categoryMutation.quantity
        } else {
            totalCount += orderLine.quantity
        }

    })

    scannedCount = (props.rentalGiveItems.length + props.rentalItems.length)

    if (props.rentalTakeItems) {
        scannedCount -= props.rentalTakeItems?.length
    }


    return (
        <div className="c-order-handling__action-bar">
            <div className="c-order-handling__action-bar__status"><span>Scan items:</span>
                <div className="c-order-handling__number"><span>{scannedCount}</span>
                    {scannedCount === totalCount &&
                    <div className="c-order-handling__number--check">
                        <i className="icon icon-check"/>
                    </div>
                    }
                    {!!props.rentalNotifications.length &&
                    <div className="c-order-handling__number__warning">
                        <i className="icon icon-info"/>
                    </div>
                    }

                </div>
            </div>


            <div className="c-order-handling__action-bar__buttons">

                {props.showLoader &&
                <>
                    <Loader
                        height="20"
                        width="20"
                        color='#002BD3'
                        ariaLabel='loading'
                    />

                    <p style={{margin: '0 2rem 0 1rem'}}> {translate('processing')}...</p>

                </>
                }

                <button className="btn btn--primary" disabled={!props.submitEnabled} onClick={() => props.onSubmit()}>
                    <span>{props.buttonText}</span>
                </button>
            </div>

        </div>
    )

}