import RentalTotalsAction from "../../stores/rental/totals/RentalTotalsAction";
import RentalIssueItemsAction from "../../stores/rental/issueItems/RentalIssueItemsAction";
import RentalReturnItemsAction from "../../stores/rental/returnItems/RentalReturnItemsAction";
import ItemAction from "../../stores/item/ItemAction";
import InsurancesAction from "../../stores/insurance/list/InsurancesAction";

const ScanDispatcher = (dispatch) => ({

    dispatch: dispatch,

    scan: (type, code, history = false, environment = false, activeDashboardButton = '') => {

        console.log(type)

        switch (type) {

            case('items'):
                dispatch(ItemAction.scanCode(code))
                break;

            case('issue'):
                dispatch(RentalIssueItemsAction.scanCode(code, true)).then(() => {
                    dispatch(RentalIssueItemsAction.validateAndSetItem(true)).then(() => {
                        dispatch(RentalIssueItemsAction.loadStatusFromItems())
                    })
                })
                break;

            case('issueCreate'):
                dispatch(RentalIssueItemsAction.scanCode(code, true)).then(() => {
                    dispatch(RentalIssueItemsAction.validateAndSetItem(true)).then(() => {
                        dispatch(RentalIssueItemsAction.loadStatusFromItems()).then(() => {
                            dispatch(RentalTotalsAction.requestTotals(true))
                            dispatch(InsurancesAction.list())
                        })
                    })
                })
                break;

            case('return'):
                dispatch(RentalReturnItemsAction.scanCode(code, true)).then(() => {
                    dispatch(RentalReturnItemsAction.validateAndSetItem()).then(() => {
                        dispatch(RentalReturnItemsAction.loadStatusFromItems())
                    })
                })
                break;

            case('rentalToReturn'):
                dispatch(RentalReturnItemsAction.scanCode(code, true))
                break;

            case('dashboard'):

                // If is booking
                if (code.includes('/b/')) {

                    code = code.replace(/\\/g, "").replace("000026", '').replace('https://t29.nl/b/', '')

                    if (activeDashboardButton) {

                        console.log(activeDashboardButton)

                        switch (activeDashboardButton) {
                            case('return'):
                                history.push('/' + environment.slug + '/dashboard/rental/return-items/' + code)
                                break;
                            case('issue'):
                                history.push('/' + environment.slug + '/dashboard/rental/issue-items/' + code)
                                break;
                            case('swap'):
                                history.push({
                                    pathname: '/' + environment.slug + '/dashboard/rental/swap-items/' + code,
                                    state: {
                                        code: code
                                    }
                                })
                                break;
                            case('period'):
                                history.push('/' + environment.slug + '/dashboard/rental/period/' + code)
                                break;
                            default:
                                history.push('/' + environment.slug + '/dashboard/rental/detail/' + code)
                                break;
                        }

                    } else {
                        history.push('/' + environment.slug + '/dashboard/rental/detail/' + code)
                    }

                } else {

                    // If is item
                    dispatch(RentalReturnItemsAction.scanCode(code))
                }

                break;

            default:
                code = code.replace(/\\/g, "").replace("000026", '').replace('https://t29.nl/b/', '')
                history.push('/' + environment.slug + '/dashboard/rental/detail/' + code)
                break;

        }

    },


})


export default ScanDispatcher;
