import {SvgQrIcon} from "../../../../common/svg/SvgQrIcon";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {eventScanPageSubmit, setCode, setDisplay} from "../eventScanPageSlice";
import {Link} from "react-router-dom";
import {routeEnum} from "../../../../common/enums/routeEnum";
import BarcodeReader from "react-barcode-reader";
import React from "react";
import {cleanScanCode} from "../../../../common/helpers/utils/cleanScanCode";
import RouteEnum from "../../../../../common/constants/RouteEnum";

export const EventScanDefaultDisplay = () => {

    const dispatch = useAppDispatch()
    const {slug} = useAppSelector(state => state.environment)


    const scanCode = (code) => {
        code = cleanScanCode(code)
        dispatch(setCode(code))
        dispatch(eventScanPageSubmit())
    }

    return (
        <>

            <BarcodeReader
                minLength={1}
                onScan={scanCode}
            />

            <div className="c-scan__qr">
                <SvgQrIcon/>
                <h2>Hold scan button <br/>to start scanning QR-code</h2>
            </div>

            <div className="c-scan__manual">
                <a href="#" onClick={() => dispatch(setDisplay('manual'))}>
                    <h2><u>Manual entry</u></h2>
                </a>
            </div>

            <div className="c-scan__on-demand">
                <Link className="btn btn--small" to={'/' + slug + RouteEnum.EVENT_DEMAND}>On demand entry</Link>
            </div>


        </>
    )

}