import React from 'react'
import {connect} from "react-redux";
import GetContrast from "../../../common/helpers/GetContrast";
import IsCurrentPartnerLocation from "../../../common/helpers/IsCurrentPartnerLocation";
import translate from "../../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    environment: state.environment,
    rentalDetail: state.rentalDetail.data
});

class DifferentLocationAlert extends React.Component {

    render() {

        const colorPrimaryLight = this.props.environment.data.info.primary_color_light

        if (this.props.rentalDetail && this.props.rentalDetail.data) {

            if (IsCurrentPartnerLocation(this.props)) {
                return null
            } else {
                return (
                    <div className={'c-location-alert'} style={{backgroundColor: colorPrimaryLight}}>
                        <p style={{color: GetContrast(colorPrimaryLight)}}>{translate('differentLocationAlert')}</p>
                    </div>
                );
            }
        } else return null

    }

}

export default connect(mapStateToProps)(DifferentLocationAlert);
