import {formatDate} from "../helpers/format/formatDate";
import translate from "../helpers/utils/translate";

export const RentalInfoBar = (props) => {
    const {rentalContainer} = props

    if (rentalContainer) {

        const {customerPrivatePerson, customerCompany} = rentalContainer

        return (
            <article className={'c-rental-info-bar'}>

                <ul className={'c-rental-sidebar__data'}>
                    {rentalContainer.customer?.type === 'private' &&
                    <li>{translate('name')} <span> {customerPrivatePerson?.firstName} {customerPrivatePerson?.insertion} {customerPrivatePerson?.lastName}</span>
                    </li>
                    }
                    {rentalContainer.customer?.type === 'company' &&
                    <li>{translate('company')} <span> {customerCompany?.name}</span></li>
                    }
                    <li>{translate('bookingsNumber')} <span>#{rentalContainer.rental.id}</span></li>
                    <li>{translate('bookingsPeriod')} <span>{formatDate(rentalContainer.rental.dateRentStart)} - {formatDate(rentalContainer.rental.dateRentEnd)}</span>
                    </li>
                    <li>{translate('partner')} <span>{rentalContainer.partnerLocation.name}</span></li>
                    <li>{translate('bookingStatus')} <span>{rentalContainer.bookingPhase.name}</span></li>
                </ul>

            </article>
        )

    } else return null

}
