import {RentalTable} from "../../../common/components/RentalTable";
import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {changePage, setFilters, loadRentalOverviewPage, setOrderBy, toggleDisplay} from "./rentalOverviewPageSlice";
import {RentalFilter} from "../../../common/components/RentalFilter";
import TinyLoader from "../../../../common/components/General/TinyLoader";
import Scanner from "../../../../common/components/General/Scanner";
import translate from "../../../common/helpers/utils/translate";

export const RentalOverviewPageContainer = () => {

    const dispatch = useAppDispatch()
    const {
        rentalList,
        pages,
        activePage,
        filters,
        partnerLocations,
        orderBy,
        sort,
        loading,
        display
    } = useAppSelector((state) => state.rentalOverviewPage)


    const displayButtonClass = display === 'default' ? 'btn btn--small btn--outline' : 'btn btn--small btn--primary'

    useEffect(() => {
        dispatch(loadRentalOverviewPage(true))
    }, [])

    useEffect(() => {
        dispatch(loadRentalOverviewPage(false))
    }, [activePage, filters, orderBy, sort, display])

    return (
        <div className={'o-container o-container--primary s-rental-overview'}>

            <Scanner type={'rental'}/>

            <header className={'c-subject-header  c-subject-header--split'}>
                <h1>{translate('allRentals')}</h1>

                <div className=" c-subject-header__buttons">
                    <button className={displayButtonClass} onClick={() => dispatch(toggleDisplay())}>
                        <span>{translate('differentBookings')}</span>
                    </button>
                </div>

            </header>

            <div className="c-Rental-overview">

                <RentalFilter
                    filters={filters}
                    partnerLocations={partnerLocations}
                    onChange={{
                        phase: value => dispatch(setFilters({
                            ...filters,
                            phase: value
                        })),
                        startDate: value => dispatch(setFilters({
                            ...filters,
                            rentStart: {
                                ...filters.rentStart,
                                from: value
                            }
                        })),
                        endDate: value => dispatch(setFilters({
                            ...filters,
                            rentStart: {
                                ...filters.rentStart,
                                to: value
                            }
                        })),
                        partnerLocation: value => dispatch(setFilters({
                            ...filters,
                            partnerLocationId: value
                        })),
                        openPayment: value => {
                            if (value) {
                                dispatch(setFilters({
                                    ...filters,
                                    openPayment: value
                                }))
                            } else {
                                dispatch(setFilters({
                                    ...filters,
                                    openPayment: ''
                                }))
                            }

                        },
                        items: value => dispatch(setFilters({
                            ...filters,
                            items: value
                        })),
                    }}
                />

                {!loading &&
                <RentalTable
                    rentals={rentalList}
                    pagination={{
                        pages: pages,
                        onChangePage: selected => dispatch(changePage(selected))
                    }}
                    orderBy={orderBy}
                    sort={sort}
                    setOrderBy={(value) => {
                        dispatch(setOrderBy(value))
                    }}
                />
                }

                {loading &&
                <div className={'c-part-loader'}>
                    <TinyLoader/>
                </div>
                }

            </div>

        </div>
    )

}