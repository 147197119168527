import fetchHelper from "../../common/helpers/fetchHelper";

export default class CategoryEffect {

    static async cityList(values, dispatch) {

        const response = await fetchHelper( values, dispatch)

        if (response) {
            return response.items.cities
        } else {
            return null
        }
    }

}


