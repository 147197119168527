// Dependencies
import React from 'react';
import {connect} from "react-redux";

// Actions
import EnvironmentAction from "../../stores/environment/EnvironmentAction";
import RentalPeriodAction from "../../stores/rental/period/RentalPeriodAction";
import RentalTotalsAction from "../../stores/rental/totals/RentalTotalsAction";

// Components
import ToDetailButton from "../../common/components/General/ToDetailButton";
import RentalTotals from "../../common/components/Rental/RentalTotals";
import TinyLoader from "../../common/components/General/TinyLoader";

// Helpers
import IsCurrentPartnerLocation from "../../common/helpers/IsCurrentPartnerLocation";
import ToTransactionCheck from "../../common/helpers/ToTransactionCheck";

// Dispatchers
import RentalDispatcher from "../../common/dispatchers/RentalDispatcher"
import Scanner from "../../common/components/General/Scanner";
import {RentalPeriod} from "../../createSlice/common/components/RentalPeriod";
import translate from "../../createSlice/common/helpers/utils/translate";
import {PinValidationWrapper} from "../../common/wrappers/PinValidationWrapper";


const mapStateToProps = (state) => ({
    rentalDetail: state.rentalDetail.data,
    environment: state.environment,
    rentalTotals: state.rentalTotals,
    ...state.rentalPeriod
});


class RentalPeriodMutation extends React.Component {

    componentDidMount() {
        this._init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.rentalUuid !== this.props.match.params.rentalUuid) {
            this._init()
        }
    }

    _init = () => {
        this.props.resetAll()
        let rentalUuid = this.props.match.params.rentalUuid
        this.props.loadPeriod(rentalUuid)
    }

    render() {


        if (this.props.rentalDetail) {
            const isCurrentPartnerLocation = IsCurrentPartnerLocation(this.props)


            if (isCurrentPartnerLocation) {
                return (
                    <main>
                        <PinValidationWrapper>
                            <Scanner type={'rental'}/>

                            <div className="o-container o-container--primary">
                                <header className="c-subject-header c-subject-header--split">
                                    <h1>{translate('changeDate')}</h1>
                                    <div className="c-subject-header__buttons">
                                        <ToDetailButton history={this.props.history} slug={this.props.environment.slug}
                                                        uuid={this.props.rentalDetail.data.rental.uuid}/>
                                    </div>
                                </header>
                                <div className="s-order-detail">
                                    <div className="c-info-blocks c-form">

                                        <RentalPeriod
                                            rentalContainer={this.props.rentalDetail}
                                            bookingPhase={this.props.rentalDetail.data?.bookingPhase?.code}
                                            setPeriod={(period) => this.props.dispatch(RentalPeriodAction.set(period)).then(() => {
                                                this.props.dispatch(RentalPeriodAction.checkAvailability()).then(() => {
                                                    this.props.dispatch(RentalTotalsAction.requestTotals())
                                                })
                                            })}
                                            partnerEnvironment={this.props.environment}
                                        />

                                        {this.props.period.startDate && this.props.period.endDate && (!!this.props.period.amount || !!this.props.period.days > 0) &&
                                        <>

                                            {this.props.checking &&
                                            <div
                                                className="c-info-blocks__text__highlight c-info-blocks__text__highlight--icon">
                                                <svg version="1.1" id="L9" x="0px" y="0px"
                                                     viewBox="0 0 100 100">
                                                    <path fill="#fff"
                                                          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                        <animateTransform
                                                            attributeName="transform"
                                                            attributeType="XML"
                                                            type="rotate"
                                                            dur="1s"
                                                            from="0 50 50"
                                                            to="360 50 50"
                                                            fill='#fff'
                                                            repeatCount="indefinite"/>
                                                    </path>
                                                </svg>

                                                <div>
                                                    <h4>{translate('checkStockAvailability')}</h4>
                                                </div>

                                            </div>
                                            }

                                            {this.props.availability &&
                                            <div
                                                className="c-info-blocks__text__highlight c-info-blocks__text__highlight--positive c-info-blocks__text__highlight--icon">
                                                <i className="icon icon-check-circle"/>
                                                <div>
                                                    <h4>{translate('stockAvailable')}</h4>
                                                </div>
                                            </div>
                                            }
                                            {this.props.availability === false &&
                                            <div
                                                className="c-info-blocks__text__highlight c-info-blocks__text__highlight--negative c-info-blocks__text__highlight--icon">
                                                <i className="icon icon-cross"/>
                                                <div>
                                                    <h4>{translate('stockUnavailable')}</h4>
                                                    <p>{translate('stockUnavailableText')}</p>
                                                </div>
                                            </div>
                                            }

                                            {this.props.rentalTotals.totals &&
                                            <RentalTotals data={this.props.rentalTotals.totals} hideInput={false}/>
                                            }

                                            {(this.props.availability || !this.props.rentalDetail.RentalItems) &&
                                            <div onClick={() => this._toPayment()} className="c-info-blocks__row">
                                                <div className="c-info-blocks__single">
                                                    <button className="btn btn--primary">
                                                        <span>{translate('agreeAndGoToPayment')}</span>
                                                    </button>
                                                </div>
                                            </div>
                                            }

                                        </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </PinValidationWrapper>
                    </main>
                )
            } else return null
        } else return <TinyLoader/>

    }

    _toPayment = () => {
        this.props.dispatch(EnvironmentAction.setLoader(true)).then(() => {
            this.props.dispatch(RentalPeriodAction.submitMutation()).then(() => {
                if (this.props.response) {
                    this.props.dispatch(RentalTotalsAction.submitDeposit(this.props.rentalDetail.data.rental.uuid)).then(() => {
                        ToTransactionCheck(this.props)
                    })
                }
            })
        })
    }

}

export default connect(mapStateToProps, RentalDispatcher)(RentalPeriodMutation);
