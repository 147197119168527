import CategoryModel from "../../../common/models/CategoryModel";
import fetchHelper from "../../../common/helpers/fetchHelper";

export default class RentalTotalsEffect {

    static async requestTotals(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            const data = {}
            const items = response.items.rentalItems;

            data.RentalItems = Object.values(items).filter((item) => item.type === 'category' || item.type === 'insurance').map((item) => {
                return new CategoryModel(item)
            })

            data.data = response.items

            return data
        } else return null

    }

    static setDiscountPercentage(percentage) {
        return percentage;

    }

    static setDiscountPrice(price) {
        return price;
    }

    static setDiscountType(value) {
        return value;
    }

    static loadDiscountFromInfo(totals) {


        if (!!totals?.data.rental.discount_percentage) {
            return {
                discountPercentage: totals?.data.rental.discount_percentage,
                discountType: 'percentage'
            }
        } else {
            if (!!totals?.data.rental.discount_price) {
                return {
                    discountPrice: totals?.data.rental.discount_price,
                    discountType: 'fixed'
                }
            } else {
                return {
                    discountType: 'percentage'
                }
            }
        }
    }

    static setDeposit(value) {
        return value;
    }

    static async submitDeposit(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response
        } else return null

    }

    static async submitDiscount(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response
        } else return null

    }

    static reset() {
        return true;
    }

}
