import RouteEnum from "../constants/RouteEnum";


const ValidateSlug = (shopSlug) => {


    if (typeof shopSlug !== 'undefined') {
        shopSlug = '' + shopSlug
    } else {
        shopSlug = ''
    }

    // Checking if the slug is not one of the routes. In that case there is simply no slug
    Object.keys(RouteEnum).forEach((key) => {
        const route = RouteEnum[key]

        if (route === shopSlug) {
            shopSlug = '';
        }

    })

    if (shopSlug === '/bedankt' || shopSlug === '/mislukt' || shopSlug === '/geannuleerd' || shopSlug === '/afhandeling') {
        shopSlug = '';
    }

    return shopSlug

}

export default ValidateSlug;
