export const SvgQrIcon = () => {

    return(
        <svg width="63px" height="63px" viewBox="0 0 63 63" version="1.1" xmlns="http://www.w3.org/2000/svg"
             style={{
                 height: 'auto',
                 maxWidth: '8rem',
                 width: '8rem'
             }}
        >
            <g id="Mobian---Parkeerplaatsen" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
               opacity="0.985423224">
                <g id="qr-code" fill="#134C7D" fillRule="nonzero">
                    <g transform="translate(0.000000, -0.000000)">
                        <path
                            d="M0.0435520178,1.79854588 L0.0435520178,24.1894079 C0.0435520178,25.0838933 0.768979875,25.8085526 1.66328776,25.8085526 L24.0628253,25.8085526 C24.9571332,25.8085526 25.6825609,25.0838933 25.6825609,24.1894079 L25.6825609,19.9437512 C25.6825609,19.0492658 24.9571332,18.3246065 24.0628253,18.3246065 C23.1685174,18.3246065 22.4435938,19.0492658 22.4435938,19.9437512 L22.4435938,22.570767 L3.28302338,22.570767 L3.28302338,3.4171867 L22.4435938,3.4171867 L22.4435938,7.31512779 C22.4435938,8.2096132 23.1685174,8.93477641 24.0628253,8.93477641 C24.9571332,8.93477641 25.6825609,8.2096132 25.6825609,7.31512779 L25.6825609,1.79854588 C25.6825609,0.904060345 24.9571332,0.179401166 24.0628253,0.179401166 L1.66328776,0.179401166 C0.768979875,0.179401166 0.0435520178,0.904060345 0.0435520178,1.79854588 Z"
                            id="Path"/>
                        <path
                            d="M9.50335276,8.016606 C8.60854078,8.016606 7.88412123,8.74126518 7.88412123,9.63524669 L7.88412123,16.3527069 C7.88412123,17.2466884 8.60854078,17.9718516 9.50335276,17.9718516 L16.2232644,17.9718516 C17.1175723,17.9718516 17.8430001,17.2466884 17.8430001,16.3527069 L17.8430001,9.63524669 C17.8430001,8.74126518 17.1175723,8.016606 16.2232644,8.016606 L9.50335276,8.016606 Z M14.6040328,14.7340661 L11.1230884,14.7340661 L11.1230884,11.2543915 L14.6040328,11.2543915 L14.6040328,14.7340661 Z"
                            id="Shape"/>
                        <path
                            d="M24.0628253,37.3708486 L1.66328776,37.3708486 C0.768979875,37.3708486 0.0435520178,38.0955079 0.0435520178,38.9894894 L0.0435520178,61.3813593 C0.0435520178,62.2753408 0.768979875,63 1.66328776,63 L24.0628253,63 C24.9571332,63 25.6825609,62.2753408 25.6825609,61.3813593 L25.6825609,38.9894894 C25.6825609,38.0955079 24.9571332,37.3708486 24.0628253,37.3708486 L24.0628253,37.3708486 Z M22.4435938,59.7622145 L3.28302338,59.7622145 L3.28302338,40.6086341 L22.4435938,40.6086341 L22.4435938,59.7622145 Z"
                            id="Shape"/>
                        <path
                            d="M38.9807267,25.8085526 L61.3807683,25.8085526 C62.2745721,25.8085526 63,25.0838933 63,24.1894079 L63,1.79854588 C63,0.904060345 62.2745721,0.179401166 61.3807683,0.179401166 L38.9807267,0.179401166 C38.0864188,0.179401166 37.3609911,0.904060345 37.3609911,1.79854588 L37.3609911,24.1894079 C37.3609911,25.0838933 38.0864188,25.8085526 38.9807267,25.8085526 L38.9807267,25.8085526 Z M40.5999583,3.4171867 L59.7605286,3.4171867 L59.7605286,22.570767 L40.5999583,22.570767 L40.5999583,3.4171867 Z"
                            id="Shape"/>
                        <path
                            d="M63,61.3813593 L63,38.9894894 C63,38.0955079 62.2745721,37.3708486 61.3807683,37.3708486 L38.9807267,37.3708486 C38.0864188,37.3708486 37.3609911,38.0955079 37.3609911,38.9894894 L37.3609911,61.3813593 C37.3609911,62.2753408 38.0864188,63 38.9807267,63 L61.3807683,63 C62.2745721,63 63,62.2753408 63,61.3813593 Z M59.7605286,59.7622145 L40.5999583,59.7622145 L40.5999583,40.6086341 L59.7605286,40.6086341 L59.7605286,59.7622145 Z"
                            id="Shape"/>
                        <path
                            d="M61.3807683,33.3066089 C62.2745721,33.3066089 63,32.5814456 63,31.687464 C63,30.7929786 62.2745721,30.0688233 61.3807683,30.0688233 L32.9847305,30.0688233 L32.9847305,1.61914485 C32.9847305,0.725163211 32.2593027,-7.11672374e-15 31.3654991,-7.11672374e-15 C30.470183,-7.11672374e-15 29.7452592,0.725163211 29.7452592,1.61914485 L29.7452592,30.0688233 L8.24859959,30.0688233 C7.35479579,30.0688233 6.62936793,30.7934825 6.62936793,31.687464 C6.62936793,32.5819495 7.35479579,33.3066089 8.24859959,33.3066089 L29.7452592,33.3066089 L29.7452592,61.3813593 C29.7452592,62.2753408 30.470183,63 31.3654991,63 C32.2593027,63 32.9847305,62.2753408 32.9847305,61.3813593 L32.9847305,33.3066089 L61.3807683,33.3066089 Z"
                            id="Path"/>
                        <path
                            d="M9.50335276,55.2837397 L16.2232644,55.2837397 C17.1175723,55.2837397 17.8430001,54.5585766 17.8430001,53.664595 L17.8430001,46.9471349 C17.8430001,46.0526493 17.1175723,45.328494 16.2232644,45.328494 L9.50335276,45.328494 C8.60854078,45.328494 7.88412123,46.0526493 7.88412123,46.9471349 L7.88412123,53.664595 C7.88412123,54.5585766 8.60854078,55.2837397 9.50335276,55.2837397 L9.50335276,55.2837397 Z M11.1230884,48.5662796 L14.6040328,48.5662796 L14.6040328,52.0459542 L11.1230884,52.0459542 L11.1230884,48.5662796 Z"
                            id="Shape"/>
                        <path
                            d="M53.5401993,8.016606 L46.8202876,8.016606 C45.9259798,8.016606 45.2005519,8.74126518 45.2005519,9.63524669 L45.2005519,16.3527069 C45.2005519,17.2466884 45.9259798,17.9718516 46.8202876,17.9718516 L53.5401993,17.9718516 C54.4350112,17.9718516 55.159935,17.2466884 55.159935,16.3527069 L55.159935,9.63524669 C55.159935,8.74126518 54.4350112,8.016606 53.5401993,8.016606 Z M51.9204636,14.7340661 L48.4405273,14.7340661 L48.4405273,11.2543915 L51.9204636,11.2543915 L51.9204636,14.7340661 Z"
                            id="Shape"/>
                        <path
                            d="M46.8202876,55.2837397 L53.5401993,55.2837397 C54.4350112,55.2837397 55.159935,54.5585766 55.159935,53.664595 L55.159935,46.9471349 C55.159935,46.0526493 54.4350112,45.328494 53.5401993,45.328494 L46.8202876,45.328494 C45.9259798,45.328494 45.2005519,46.0526493 45.2005519,46.9471349 L45.2005519,53.664595 C45.2005519,54.5585766 45.9259798,55.2837397 46.8202876,55.2837397 L46.8202876,55.2837397 Z M48.4405273,48.5662796 L51.9204636,48.5662796 L51.9204636,52.0459542 L48.4405273,52.0459542 L48.4405273,48.5662796 Z"
                            id="Shape"/>
                        <path
                            d="M1.33561009,30.0753744 L1.24839746,30.0955319 C0.377783216,30.3011376 -0.161624484,31.1729459 0.0435520178,32.0432425 C0.219489787,32.7885632 0.884423315,33.2914907 1.61842117,33.2914907 C1.74193039,33.2914907 1.86644779,33.2768766 1.9914694,33.2471444 L2.07817807,33.226987 C2.9492964,33.0218852 3.4887041,32.1500769 3.28302338,31.2797802 C3.07835097,30.4099876 2.20622433,29.8707766 1.33561009,30.0753744 Z"
                            id="Path"/>
                        <path
                            d="M23.73666,12.0138222 L23.6494474,12.0344837 C22.7788331,12.2385775 22.2389213,13.109882 22.4430897,13.9806825 C22.6175149,14.7270109 23.2829525,15.2304425 24.0184628,15.2304425 C24.1404596,15.2304425 24.264473,15.2163323 24.3894947,15.1866001 L24.4762032,15.1664427 C25.3473215,14.9623489 25.8872334,14.0905405 25.6830651,13.2207478 C25.4788967,12.3504513 24.6067702,11.8097284 23.73666,12.0138222 Z"
                            id="Path"/>
                    </g>
                </g>
            </g>
        </svg>
    )

}