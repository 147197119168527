const MapIssuesToCategories = (issueItems) => {

    let categories = []

    issueItems.forEach((item) => {

        const categoryIndex = categories.findIndex(category => category.id === item.availability_category.id)

        item.category_id = item.id

        if (categories[categoryIndex]) {

            const newCount = categories[categoryIndex].quantity + 1
            categories[categoryIndex].quantity = newCount


        } else {

            categories.push({
                ...item.availability_category,
                category_id: item.availability_category.id,
                quantity: 1
            })
        }


    })

    return categories

}

export default MapIssuesToCategories