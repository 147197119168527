import React from 'react'
import moment from "moment";
import {connect} from "react-redux";
import translate from "../../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    rentalDetail: state.rentalDetail.data
});

class RentalInfoBar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            collapsed: false
        }
    }

    render() {

        const rentalData = this.props.rentalDetail.data.rental
        const className = 'c-rental-info-bar'

        if (this.props.rentalDetail) {
            return (
                <article className={className}>

                    <ul className={'c-rental-sidebar__data'}>
                        <li>{translate('name')} <span>{this.props.rentalDetail.renter}</span></li>
                        <li>{translate('bookingsNumber')} <span>{this.props.rentalDetail.RentalNr}</span></li>
                        {!!this.props.rentalDetail.data.time_unit.time_required ? (
                            <li>{translate('bookingsPeriod')} <span>{moment(rentalData.date_rent_start).format('DD/MM/YYYY HH:mm')} - {moment(rentalData.date_rent_end).format('DD/MM/YYYY HH:mm')}</span></li>
                        ):(
                            <li>{translate('bookingsPeriod')} <span>{this.props.rentalDetail.hirePeriod}</span></li>
                        )}
                        <li>Partner <span>{this.props.rentalDetail.partnerLocation}</span></li>
                        <li>{translate('bookingStatus')} <span>{this.props.rentalDetail.status}</span></li>
                    </ul>

                </article>
            )
        } else return null
    }

}

export default connect(mapStateToProps)(RentalInfoBar);
