export const formatCustomerString = (rental) => {

    let string = ''



    if (rental.customer?.type === 'private') {
        string += rental.customerPrivatePerson?.firstName ? rental.customerPrivatePerson?.firstName : ''
        rental.customerPrivatePerson?.insertion ? string += ' ' + rental.customerPrivatePerson.insertion : string += ''
        string += ' ' + rental.customerPrivatePerson?.lastName
    } else {
        string = rental.customerCompany?.name ? rental.customerCompany.name : ''
    }

    return string
}