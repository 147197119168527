import CalculateDaysBetween from "./CalculateDaysBetween";
import moment from 'moment'

const CalculateSwapToCategoryRequest = (rentalCategories, rentalReturnedItems, rentalIssuedItems, availableBicycles, rentalData) => {

    let categories = rentalCategories.map((category) => {
        return {
            category_id: category.category_id,
            quantity: category.quantity,
            mutation_price: category.mutation_price
        }
    })

    let returnedPrice = 0

    let issuedPrice = 0
    let issuedQuantity = 0

    const days = CalculateDaysBetween(rentalData.rental.date_rent_start, rentalData.rental.date_rent_end)
    const daysLeft = CalculateDaysBetween(moment().startOf('day').toDate(), rentalData.rental.date_rent_end)


    rentalReturnedItems.forEach((item) => {

        if (availableBicycles[item.availability_category.id]) {
            returnedPrice += availableBicycles[item.availability_category.id].price / days
        }

    })

    rentalIssuedItems.forEach((item) => {

        if (availableBicycles[item.availability_category.id]) {
            issuedPrice += availableBicycles[item.availability_category.id].price / days
            issuedQuantity = issuedQuantity + 1
        }

    })


    let compensationPricePerItem = (issuedPrice - returnedPrice) / issuedQuantity;

    compensationPricePerItem = compensationPricePerItem * (daysLeft - days)

    rentalIssuedItems.forEach((item) => {

        if (categories.some(category => category.category_id === item.availability_category.id)) {

            categories = categories.map(category => {

                if (category.category_id === item.availability_category.id) {

                    return {
                        ...category,
                        quantity: category.quantity + 1,
                        mutation_price: compensationPricePerItem
                    }
                } else return {
                    ...category
                }
            })

        } else {
            categories.push({
                category_id: item.availability_category.id,
                quantity: 1,
                mutation_price: compensationPricePerItem
            })
        }

    })
    
    return categories


}


export default CalculateSwapToCategoryRequest