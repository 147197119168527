import React from "react";
import {connect} from "react-redux";
import RentalDispatcher from "../../../common/dispatchers/RentalDispatcher";

const mapStateToProps = (state) => ({
    ...state.rentalReturn
});

class RentalReturnNotifications extends React.Component {

    render() {

        if (this.props.status.errors.length) {


            return (
                <div className="c-order-handling__notifications">
                    {this.props.status.errors.map((error) => (
                        <div
                            className="c-info-blocks__text__highlight c-info-blocks__text__highlight--orange c-info-blocks__text__highlight--icon">
                            <i className="icon icon-info"/>
                            <div>
                                <h4>{error}</h4>
                            </div>
                        </div>
                    ))}
                </div>
            )


        } else {
            return null
        }
    }
}

export default connect(mapStateToProps, RentalDispatcher)(RentalReturnNotifications);
