import UserAction from "./UserAction";

export default class UserReducer {

    static initialState = {
        list: false,
        detail: false,
        isLoading: false
    };

    static reducer(state = UserReducer.initialState, action) {


        switch (action.type) {

            case UserAction.REQUEST_LIST:
                return {
                    ...state,
                    list: false,
                    isLoading: true
                };

            case UserAction.REQUEST_LIST + '_FINISHED':

                return {
                    ...state,
                    list: action.payload,
                    detail: false,
                    isLoading: false
                };

            case UserAction.REQUEST_DETAIL:
                return {
                    ...state,
                    list: action.payload,
                    detail: false,
                    isLoading: true
                };

            case UserAction.REQUEST_DETAIL + '_FINISHED':

                return {
                    ...state,
                    list: action.payload,
                    detail: action.payload,
                    isLoading: false
                };


            default:
                return state;
        }


    }
}