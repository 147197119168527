import {connect} from "react-redux";
import AuthenticationAction from "../../../stores/authentication/AuthenticationAction";
import React from "react";
import {withRouter} from "react-router";

const mapStateToProps = (state) => ({
    ...state
});

class TimeTicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reset: 900,
            seconds: parseInt(props.startTimeInSeconds, 10) || 0,
            barcode: null
        };
    }

    tick() {
        this.setState(state => ({
            seconds: state.seconds + 1
        }));

        if (this.state.seconds === this.state.reset) {
            this.props.dispatch(AuthenticationAction.timeOut())
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => this.tick(), 1000);

        document.addEventListener('mousemove', (e) => {
            this.setState({
                reset: 900,
                seconds: parseInt(this.props.startTimeInSeconds, 10) || 0,
                barcode: null
            })
        });

        document.addEventListener('keydown', (e) => {
            this.setState({
                reset: 900,
                seconds: parseInt(this.props.startTimeInSeconds, 10) || 0,
                barcode: null
            })
        });

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.setState({
                reset: 900,
                seconds: parseInt(this.props.startTimeInSeconds, 10) || 0,
                barcode: null
            })
        }
    }

    formatTime = (secs) => {
        let hours = Math.floor(secs / 3600);
        let minutes = Math.floor(secs / 60) % 60;
        let seconds = secs % 60;
        return [hours, minutes, seconds]
            .map(v => ('' + v).padStart(2, '0'))
            .filter((v, i) => v !== '00' || i > 0)
            .join(':');
    }


    render() {
        if (this.state.seconds > 30) {
            return (
                <div className={'c-reset-time'}>
                    {this.formatTime(this.state.seconds)} / {this.formatTime(this.state.reset)}
                </div>
            )
        } else return null
    }


}

export default connect(mapStateToProps)(withRouter(TimeTicker));
