import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import RentalSubjectButtons from "../../../common/constants/RentalSubjectButtons";
import RentalPdf from "../../../common/components/Rental/RentalPdf";
import {PDFDownloadLink} from '@react-pdf/renderer';
import IsCurrentPartnerLocation from "../../../common/helpers/IsCurrentPartnerLocation";
import GetContrast from "../../../common/helpers/GetContrast";
import CheckDisplayModule from "../../../common/helpers/CheckDisplayModule";
import translate from "../../../createSlice/common/helpers/utils/translate";

const RentalDetailHeader = (props) => {

    const rentalSubjectButtons = RentalSubjectButtons(props)
    const isCurrentPartnerLocation = IsCurrentPartnerLocation(props)

    let mailClass = 'btn btn--small btn--outline'

    if (props.mail.loading) {
        mailClass += ' is-mailing'
    } else if (props.mail.response && props.mail.response.status.code === 200) {
        mailClass += ' is-completed'
    }

    let paymentMailClass = 'btn btn--small btn--outline'

    if (props.paymentMail.loading) {
        paymentMailClass += ' is-mailing'
    } else if (props.paymentMail.response && props.paymentMail.response.status.code === 200) {
        paymentMailClass += ' is-completed'
    }

    const fileName = props.environment.slug + '-' + props.rentalDetail.data.rental.id + '.pdf'

    const primaryColorLight = props.environment.data.info.primary_color_light
    const primaryColorDark = props.environment.data.info.primary_color_dark

    const [showMailPopup, setMailPopup] = useState(false)
    const [showPaymentMailPopup, setPaymentMailPopup] = useState(false)

    useEffect(() => {

        if (props.mail?.response?.status.code === 200 || props.paymentMail?.response?.status.code === 200) {

            setMailPopup(true)

            const timeOutMailPopup = window.setTimeout(() => {
                setMailPopup(false)
            }, 5000);

            return () => window.clearTimeout(timeOutMailPopup);

        }

    }, [props.mail?.response])

    useEffect(() => {

        if (props.paymentMail?.response?.status.code === 200) {

            setPaymentMailPopup(true)

            const timeOutPaymentMailPopup = window.setTimeout(() => {
                setPaymentMailPopup(false)
            }, 5000);

            return () => window.clearTimeout(timeOutPaymentMailPopup);

        }

    }, [props.paymentMail?.response])

    const transactions = props.transaction
    const lastTransaction = transactions[transactions.length - 1]

    useEffect(() => {

        if (props.paymentMail?.response?.status.code === 200) {

            setPaymentMailPopup(true)

            const timeOutMailPopup = window.setTimeout(() => {
                setPaymentMailPopup(false)
            }, 5000);

            return () => window.clearTimeout(timeOutMailPopup);

        }

    }, [props.paymentMail?.response])


    return (
        <header className="c-subject-header c-subject-header--split c-subject-header--actions">
            <h1>{translate('booking')} <small>{props.rentalDetail.RentalNr}</small></h1>
            <div className="c-subject-header__buttons">

                {CheckDisplayModule('rentalMutations', props.environment, props.partnerUser) && rentalSubjectButtons.filter(button => !button.hideOnStatus.includes(props.currentPhase)).map(button => {
                    return (
                        <Link
                            key={'header-button ' + button.text}
                            to={button.link}
                            style={{background: button.background}}
                            className="btn btn--small btn--outline"><span
                            style={{color: button.color}}>{button.text}</span>
                        </Link>
                    )
                })}

                {showMailPopup &&
                    <article className="c-succes-popup is-active">
                        <header>{translate('confirmationMailSend')}</header>
                        <p>Mail is verzonden</p>
                    </article>
                }

                {showPaymentMailPopup &&
                    <article className="c-succes-popup is-active">
                        <header>{translate('confirmationMailSend')}</header>
                        <p>{translate('mailSent')}</p>
                    </article>
                }

                {isCurrentPartnerLocation &&
                    <button style={{background: primaryColorDark}} className={mailClass}
                            onClick={() => props.sendMail()}><span style={{color: GetContrast(primaryColorDark)}}>{translate('confirmationMailSendButtonText')}</span>
                    </button>}

                {(isCurrentPartnerLocation && lastTransaction?.status === 'pending') &&
                <button style={{background: primaryColorDark}} className={paymentMailClass} onClick={() => props.sendPaymentMail()}><span style={{color: GetContrast(primaryColorDark)}}>{translate('paymentLinkSendButtonText')}</span>
                </button>}

                {(!props.loading && isCurrentPartnerLocation) &&
                    <PDFDownloadLink className={'btn btn--small btn--outline pdf-download'}
                                     style={{background: primaryColorDark, color: GetContrast(primaryColorDark)}}
                                     document={<RentalPdf {...props}  />} fileName={fileName}>
                        {({blob, url, loading, error}) =>
                            loading ? 'Document ' + translate('beingGenerated') +'...' : 'download PDF'
                        }
                    </PDFDownloadLink>
                }

            </div>
        </header>
    )
}

export default RentalDetailHeader