const FormatDate = (DateTImeString) => {

    if (typeof DateTImeString === 'string' || DateTImeString instanceof String) {
        DateTImeString = DateTImeString.replace(/-/g, "/")
    }


    const date = new Date(DateTImeString)

    return ("0" + date.getDate()).slice(-2) + '/' + ("0" + (parseInt(date.getMonth() + 1))).slice(-2) + '/' + date.getFullYear();

}

export default FormatDate