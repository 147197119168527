import {closeDepotModal} from "./depotModalSlice";
import {submitDepot} from "./depotModalSlice";
import {useDispatch, useSelector} from "react-redux";
import React from 'react'
import translate from "../../createSlice/common/helpers/utils/translate";

const DepotModalContainer = () => {

    const {item, active} = useSelector((state) => state.depotModal)
    const dispatch = useDispatch()

    if (item && active) {
        const content = formatContent(item)
        return (
            <div className="c-modal">
                <div className="c-modal__bg"/>
                <div className="c-modal__content c-modal__content--text">

                    <button className="c-modal__close" onClick={() => dispatch(closeDepotModal())}>
                        <i className="icon icon-cross"/>
                    </button>

                    <div className="c-modal__header">
                        <h2>{content.title}</h2>
                    </div>

                    <div className="c-modal__body">
                        <p>{content.text}</p>
                    </div>

                    <div className="c-modal__action">

                        <button className="btn btn--small btn--primary" onClick={() => dispatch(submitDepot())}>
                            <span>{content.buttonText}</span>
                        </button>

                        <button
                            className="btn btn--small btn--outline"
                            onClick={() => dispatch(closeDepotModal())}
                        >
                            <span>{translate('back')}</span>
                        </button>

                    </div>
                </div>
            </div>
        )
    } else return null

}

const formatContent = (item) => {

    let buttonText = translate('confirm')

    let title = 'Item ' + item.product_code + ' ' + translate('setToDepot')
    let text = translate('setToDepotCheck');

    if (item.rental) {
        text = translate('setToDepotCheckRentalConnected');
        buttonText = translate('disconnectAndConfirm')
    }


    return {
        title: title,
        text: text,
        buttonText: buttonText
    }

}

export default DepotModalContainer