import React from 'react'
import DisplayPrice from "../../../common/helpers/DisplayEuro";
import {connect} from "react-redux";
import IsCurrentPartnerLocation from "../../../common/helpers/IsCurrentPartnerLocation";
import CheckDisplayModule from "../../../common/helpers/CheckDisplayModule";
import translate from "../../../createSlice/common/helpers/utils/translate";


const mapStateToProps = (state) => ({
    environment: state.environment,
    rentalDetail: state.rentalDetail.data,
    mutations: Object.values(state.rentalDetail.mutations),
    partnerUser: state.authentication.partnerUser,
    transaction: state.rentalDetail.transactions
});


class RentalTransactions extends React.Component {

    render() {

        const transactions = this.props.transaction

        const isCurrentPartnerLocation = IsCurrentPartnerLocation(this.props)

        if (CheckDisplayModule('financial', this.props.environment, this.props.partnerUser) && isCurrentPartnerLocation && transactions) {

            return (
                <div className="c-info-blocks__row">

                    <div className="c-info-blocks__single">
                        <header className="c-subject-header c-subject-header--closed">
                            <h2>{translate('transactions')}</h2>
                        </header>
                        <div className="c-info-blocks__single__body c-info-blocks__single__body--scroll">

                            <table className="c-table-standard">
                                <tbody>

                                <tr>
                                    <td>{translate('id')}</td>
                                    <td>{translate('dateCreated')}</td>
                                    <td>{translate('dateEdited')}</td>
                                    <td>{translate('rent')}</td>
                                    <td>{translate('insurance')}</td>
                                    <td>{translate('deposit')}</td>
                                    <td>{translate('method')}</td>
                                </tr>

                                {Array.isArray(transactions) && transactions.filter(transaction => (transaction.status === 'completed' && (transaction.deposit !== 0 || transaction.total !== '0.000' || transaction.insurance !== 0))).sort((a, b) => new Date(b.date_updated) - new Date(a.date_updated)).map((transaction) => {

                                    return (
                                        <tr key={'transaction-' + transaction.id}>
                                            <td>#{transaction.id}</td>
                                            <td>{transaction.date_created}</td>
                                            <td>{transaction.date_updated}</td>
                                            <td>{DisplayPrice(transaction.total)}</td>
                                            <td>{DisplayPrice(transaction.insurance)}</td>
                                            <td>{DisplayPrice(transaction.deposit)}</td>
                                            <td>{transaction.status}</td>
                                            {transaction.paymentMethod && <td>{transaction.paymentMethod.name}</td>}
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        } else return null
    }
}

export default connect(mapStateToProps)(RentalTransactions);
