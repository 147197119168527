import GetContrast from "../helpers/GetContrast";
import RouteEnum from "./RouteEnum";
import translate from "../../createSlice/common/helpers/utils/translate";


const RentalActionButtons = (props) => {

    const primaryColorLight = props.environment.data.info.primary_color_light
    const primaryColorDark = props.environment.data.info.primary_color_dark

    return [
        {
            text: translate('changePeriod'),
            icon: 'icon-plus',
            background: primaryColorLight,
            color: GetContrast(primaryColorLight),
            link: '/' + props.environment.slug + RouteEnum.RentalPeriod + '/' + props.rentalUuid,
            hideOnStatus: ['completed', 'cancelled']
        },
        {
            text: translate('changeCustomerData'),
            background: primaryColorDark,
            color: GetContrast(primaryColorDark),
            link: '/' + props.environment.slug + RouteEnum.RentalCustomerData + '/' + props.rentalUuid,
            hideOnStatus: []
        },
        {
            text: translate('changeItems'),
            background: primaryColorDark,
            color: GetContrast(primaryColorDark),
            link: '/' + props.environment.slug + RouteEnum.RentalItems + '/' + props.rentalUuid,
            hideOnStatus: ['note', 'completed', 'cancelled', 'registration']
        },
        {
            text: translate('accommodationAndOptions'),
            background: primaryColorDark,
            color: GetContrast(primaryColorDark),
            link: '/' + props.environment.slug + RouteEnum.RentalDelivery + '/' + props.rentalUuid,
            hideOnStatus: []
        },
        {
            text: translate('cancel'),
            icon: 'icon-cross',
            background: 'red',
            color: '#fff',
            link: '/' + props.environment.slug + RouteEnum.RentalCancellation + '/' + props.rentalUuid,
            hideOnStatus: ['note', 'completed', 'cancelled']
        },
    ]
}

export default RentalActionButtons
