import {formatItemLabel} from "../helpers/format/formatItemLabel";

export const RentalItemsToReturn = (props) => {

    const {itemsToScan, scannedItems} = props


    return (
        <div className="c-order-handling__list">

            {itemsToScan?.map((item) => {

                const label = formatItemLabel(item)
                const isScanned = scannedItems?.find((scannedItem) => scannedItem.id === item.id)

                return (
                    <div className="c-order-handling__list__single" key={'rental-issue-item-' + item.id}>
                        <div className="c-order-handling__list__single__wrap">
                            <h6>{label}</h6>
                        </div>

                        {isScanned &&
                        <div
                            className="c-order-handling__number c-order-handling__number--check c-order-handling__number__small">
                            <i className="icon icon-check"/>
                        </div>
                        }

                        {!isScanned &&
                        <button
                            onClick={() => props.takeItemAction(item)}
                            className="c-order-handling__number c-order-handling__number__small"
                            style={{background: 'red'}}>
                            <i className="icon icon-cross"/>
                        </button>
                        }


                    </div>
                )
            })}

        </div>
    )

}