export const SvgScanSuccess = () => {

    return (
        <svg width="168px" height="171px" viewBox="0 0 168 171" version="1.1" style={{
            maxWidth: '12rem',
            width: '12rem',
            height: 'auto'
        }}>
            <title>scan-success</title>
            <g id="Mobian---Parkeerplaatsen" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="scan-success">
                    <g id="location-svgrepo-com" transform="translate(0.000000, 21.000000)" fill="#3FA2F7"
                       fillRule="nonzero">
                        <path
                            d="M54.5,0 C24.4488432,0 0,24.8642857 0,55.4271429 C0,85.0166667 49.6847728,146.125238 51.7997457,148.711905 C52.4669665,149.527619 53.4563262,150 54.5,150 C55.5432056,150 56.5330335,149.528095 57.2002543,148.711905 C59.3156954,146.124762 109,85.0166667 109,55.4271429 C109,24.8642857 84.551625,0 54.5,0 Z M54.5,140.760952 C50.0944698,135.16619 40.9411368,123.24619 31.8833217,109.619524 C15.6199902,85.1533333 7.02337689,66.4138095 7.02337689,55.4271429 C7.02337689,28.8028571 28.3215332,7.14285714 54.5,7.14285714 C80.6784668,7.14285714 101.976623,28.8028571 101.976623,55.4271429 C101.976623,66.4133333 93.380478,85.1528571 77.1166783,109.619524 C68.058395,123.24619 58.9055302,135.16619 54.5,140.760952 Z"
                            id="Shape"/>
                        <path
                            d="M72.3660661,101.88381 C70.7591175,100.779524 68.5757837,101.21 67.4904379,102.844286 C62.7515314,109.978571 57.4427949,117.455714 51.7117194,125.068095 C50.5327285,126.634286 50.824901,128.875714 52.3648934,130.074762 C53.0016796,130.570952 53.7522445,130.810952 54.4971906,130.810952 C55.5525701,130.810952 56.5967121,130.328571 57.2878124,129.410476 C63.1031685,121.686667 68.4938443,114.09381 73.310008,106.842857 C74.3958221,105.208571 73.973483,102.988095 72.3660661,101.88381 Z"
                            id="Path"/>
                        <path
                            d="M54.5,27.3809524 C38.6220177,27.3809524 25.7041547,40.5185714 25.7041547,56.6666667 C25.7041547,72.8147619 38.6220177,85.952381 54.5,85.952381 C70.3779823,85.952381 83.2958453,72.8147619 83.2958453,56.6666667 C83.2958453,40.5185714 70.3779823,27.3809524 54.5,27.3809524 Z M54.5,78.8095238 C42.4947078,78.8095238 32.7275316,68.8761905 32.7275316,56.6666667 C32.7275316,44.4571429 42.4947078,34.5238095 54.5,34.5238095 C66.5057605,34.5238095 76.2724684,44.4571429 76.2724684,56.6666667 C76.2724684,68.8761905 66.5057605,78.8095238 54.5,78.8095238 Z"
                            id="Shape"/>
                    </g>
                    <g id="Group-3" transform="translate(67.000000, 0.000000)">
                        <circle id="Oval-Copy" stroke="#14D4AF" strokeWidth="5.6" fill="#FFFFFF" cx="50.5" cy="50.5"
                                r="47.7"/>
                        <g id="Path-2" transform="translate(24.000000, 31.000000)" fill="#14D4AF" fillRule="nonzero">
                            <polygon id="Path"
                                     points="20.0959945 39 0 18.8737547 6.03712746 12.8275395 20.0959945 26.9075695 46.9628725 0 53 6.04621525"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )

}