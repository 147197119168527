


export const actionChangeCategoryMutation = (args) => {

    let categoryMutations = [...args.categoryMutationsState]
    const categoryMutation = categoryMutations?.find((categoryMutation) => categoryMutation.categoryId === args.categoryMutation.categoryId)
    const currentOrderLine = args.rentalContainer?.rentalItems.find((orderLine) => orderLine.categoryId === args.categoryMutation.categoryId)

    if (!categoryMutation) {

        // set new category mutation in case one with this category doesn't exist
        categoryMutations.push(args.categoryMutation)

    } else {

        // remove if quantity is the same as the initial order line
        if (currentOrderLine?.quantity === args.categoryMutation.quantity) {

            categoryMutations = categoryMutations.filter((categoryMutation) => categoryMutation.categoryId !== args.categoryMutation.categoryId)

        } else {

            // Alter the amount
            categoryMutations = categoryMutations.map((categoryMutation) => {
                if (categoryMutation.categoryId === args.categoryMutation.categoryId) {

                    return {
                        ...categoryMutation,
                        quantity: args.categoryMutation.quantity
                    }


                } else return categoryMutation
            })
        }
    }

    return {
        categoryMutations: categoryMutations
    }

}
