import React from 'react'
import {connect} from "react-redux";
import RentalDispatcher from "../../../common/dispatchers/RentalDispatcher";
import RentalReturnAction from "../../../stores/rental/returnItems/RentalReturnItemsAction";
import RentalReturnErrors from "./RentalReturnErrors";
import translate from "../../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    ...state.rentalReturn,
    environment: state.environment,
    rentalDetail: state.rentalDetail.data
});

class RentalReturnPopup extends React.Component {

    render() {

        let text = this.props.status.warning + translate('rentalReturnText')

        if (this.props.late) {
            text = translate('rentalReturnTextLate')
        }

        return (
            <div className="c-modal">
                <div className="c-modal__bg"></div>
                <div className="c-modal__content c-modal__content--text">
                    <button className="c-modal__close" onClick={() => this._handleToggle()}><i
                        className="icon icon-cross"/></button>
                    <div className="c-modal__header"><h2>{translate('returnRental')}</h2></div>
                    <div className="c-modal__body"><p>{text}</p>
                    </div>

                    <RentalReturnErrors/>

                    <div className="c-modal__action">

                        <button className="btn btn--small btn--primary" onClick={() => this._handleSubmit()}><span>{translate('returnRental')}</span>
                        </button>

                        <button className="btn btn--small btn--outline" onClick={() => this._handleToggle()}><span>{translate('backToReturn')}</span>
                        </button>
                    </div>

                </div>
            </div>
        )
    }

    _handleSubmit = () => {
        this.props.submit(true)
    }

    _handleToggle = () => {
        this.props.dispatch(RentalReturnAction.togglePopup())
    }


}

export default connect(mapStateToProps, RentalDispatcher)(RentalReturnPopup);
