import React, {useEffect} from "react";
import {loadProductDetail} from "./productDetailPageSlice";
import {useParams} from "react-router-dom";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {openServiceModal} from "../../../../views/serviceModal/serviceModalSlice";
import {formatItemServiceButtonText} from "../../../../common/helpers/formatItemServiceButtonText";
import {formatCustomerString} from "../../../../common/helpers/formatCustomerString";
import {getPhaseColor} from "../../../../common/helpers/getPhaseColor";
import {useHistory} from "react-router";
import {openSoldModal} from "../../../../views/soldModal/soldModalSlice";
import {RentalTable} from "../../../common/components/RentalTable";
import Scanner from "../../../../common/components/General/Scanner";
import translate from "../../../common/helpers/utils/translate";
import {openStolenModal} from "../../modals/stolenModal/stolenModalSlice";

export const ProductDetailPageContainer = () => {

    const dispatch = useDispatch()
    const {item, rentals} = useSelector((root) => root.productDetailPage)
    const {serviceModal, soldModal, stolenModal} = useSelector((root) => root)
    const {slug, colors} = useSelector((state) => state.environment)
    const history = useHistory()
    let {productId} = useParams();

    useEffect(() => {

        dispatch(loadProductDetail(Number(productId)))

    }, [serviceModal.active, serviceModal.submitted, soldModal, stolenModal])


    if (item) {


        return (
            <div className="o-container o-container--primary">

                <Scanner type={'rental'}/>

                <header className="c-subject-header c-subject-header--split c-subject-header--actions">
                    <h1>{translate('bicycleInformation')} {item.product_code ? item.product_code : item.product_code_prev}</h1>

                    {item.rental_status.code !== 'sold' && item.rental_status.code !== 'stolen' &&
                        <div className="c-subject-header__buttons">

                            <button
                                onClick={() => dispatch(openServiceModal(item))}
                                className="btn btn--small btn--outline"
                                style={{background: 'rgb(2, 45, 107)'}}
                            >
                                <span style={{color: 'white'}}>{formatItemServiceButtonText(item)}</span>
                            </button>

                            <button
                                onClick={() => dispatch(openSoldModal(item))}
                                className="btn btn--small btn--outline"
                                style={{background: 'rgb(2, 45, 107)'}}>
                                <span style={{color: 'white'}}>Verkocht</span>
                            </button>

                            <button
                                onClick={() => dispatch(openStolenModal(item))}
                                className="btn btn--small btn--outline"
                                style={{background: 'rgb(2, 45, 107)'}}>
                                <span style={{color: 'white'}}>Gestolen</span>
                            </button>

                        </div>
                    }
                </header>

                <div className="s-order-detail">
                    <div className="c-info-blocks">
                        <div className="c-info-blocks__row c-info-blocks__row--half">
                            <div className="c-info-blocks__single">
                                <header className="c-subject-header c-subject-header--closed">
                                    <h2>{translate('specificInformation')}</h2>
                                </header>
                                <div className="c-info-blocks__single__body">
                                    {item.model.upload &&
                                        <div className="c-info-blocks__single__body__row" style={{display: 'block'}}>
                                            <span style={{display: 'block'}}>{translate('image')}</span>
                                            <img src={item.model.upload}/>
                                        </div>
                                    }
                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('model')}</span>
                                        <span>{item.model.name}</span>
                                    </div>
                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('size')}</span>
                                        <span>{item.model.size}</span>
                                    </div>
                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('colour')}</span>
                                        <span>{item.model.color}</span>
                                    </div>
                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('height')}</span>
                                        <span>{item.model.height}</span>
                                    </div>
                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('frameNumber')}</span>
                                        <span>{item.frame_number}</span>
                                    </div>
                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('keyNumber')}</span>
                                        <span>{item.key_number}</span>
                                    </div>
                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('stockLocation')}</span>
                                        <span>{item.location.name}</span>
                                    </div>
                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('status')}</span>
                                        <span>{item.rental_status.name}</span>
                                    </div>
                                    {!!item.maintenance &&
                                        <>
                                            <div className="c-info-blocks__single__body__row c-info-blocks__single__body__row--defects">
                                                <span>{translate('malfunction')}</span>
                                                <div className="blocks">
                                                    {item.maintenance.service_defects.map((defect) => (
                                                        <span style={{background: colors.light}}>{defect.name}</span>
                                                    ))}
                                                </div>
                                            </div>
                                            {!!item.maintenance.remarks &&
                                                <div className="c-info-blocks__single__body__row">
                                                    <span>{translate('notes')}</span>
                                                    <span>{item.maintenance.remarks}</span>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="c-info-blocks__single">
                                <header className="c-subject-header c-subject-header--closed">
                                    <h2>{translate('sellData')}</h2>
                                </header>
                                <div className="c-info-blocks__single__body">
                                    <div className="c-info-blocks__single__body__row">
                                        <span>{translate('purchaseData')}</span>
                                        <span>{moment(item.date_purchased).format('L')}</span>
                                    </div>
                                    {item.dateSold &&
                                        <div className="c-info-blocks__single__body__row">
                                            <span>{translate('sellData')}</span>
                                            <span>{moment(item.date_sold).format('L')}</span>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>

                        {!!Object.values(rentals?.data).length && (
                            <RentalTable
                                setOrderBy={() => console.log('no sort set for product detail')}
                                rentals={Object.values(rentals.data).sort(
                                    (a, b) =>
                                        new Date(b.rental.dateRentStart) -
                                        new Date(a.rental.dateRentStart)
                                )}
                            />
                        )}


                    </div>

                </div>

            </div>
        )
    } else return null
}

