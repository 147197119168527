import React from 'react'
import {connect} from "react-redux";
import DisplayPrice from "../../helpers/DisplayEuro";
import ChangeBicycleNumber from "../../dispatchers/ChangeBicycleNumber";

const mapStateToProps = (state) => ({
    ...state.rentalItems,
    rentalDetail: state.rentalDetail?.data,
});


class BicycleItem extends React.Component {

render() {

        const data = this.props.data

        let quantity = 0

        if (data.quantity) {
            quantity = data.quantity
        }

        if(this.props.loadedBicyclesFromInfo) {
            return (
                <div className="c-products__single c-products__single--small">

                    <div className="c-products__single__primary">
                        <h4>{data.alternative_label ? data.alternative_label : data.name}</h4>
                    </div>
                    <div className="c-products__single__secondary">
                        <div className="c-products__single__price">
                            {DisplayPrice(data.price)}
                        </div>
                        <div className="c-products__single__supply">
                            {data.available}
                        </div>

                        {data.available > 0 &&
                        <div className="c-products__single__amount">
                            <button className="minus"

                                    onClick={() => {
                                        ChangeBicycleNumber(data, -1, this.props.dispatch, this.props.rentalDetail?.data?.rental.uuid)
                                    }}

                            ><i className="icon icon-minus"/>
                            </button>
                            <span>{quantity}</span>
                            <button className="plus" onClick={() => {

                                ChangeBicycleNumber(data, 1, this.props.dispatch, this.props.rentalId, this.props.rentalDetail?.data?.rental.uuid)
                            }}><i className="icon icon-plus"

                            />
                            </button>
                        </div>
                        }
                    </div>
                </div>
            )
        }else return null
    }

}

export default connect(mapStateToProps)(BicycleItem);
