import RouteEnum from "../../constants/RouteEnum";
import React from 'react'
import translate from "../../../createSlice/common/helpers/utils/translate";

class ToDetailButton extends React.Component {


    render() {
        return (
            <button onClick={() => this._toDetail()} className="btn btn--small btn--outline">
                <span>{translate('back')}</span>
            </button>
        )
    }

    _toDetail = () => {
        const {history} = this.props
        history.push('/' + this.props.slug + RouteEnum.RentalDetail + '/' + this.props.uuid)
    }


}


export default ToDetailButton