import RentalCustomerDataAction from "./RentalCustomerDataAction";

export default class RentalCustomerDataReducer {

    static initialState = {
        form: {
            id: false,
            completed: false,
            data: {
                newCustomer: 'true',
                type: 'private',
                customer: {
                    id: null,
                    remarks: ''
                },
                contactPersons: null,
                address: {
                    street: '',
                    house_number: '',
                    house_number_addition: '',
                    city: '',
                    country_id: 1,
                    zip_code: ''
                },
                company: {
                    id: false,
                    name: '',
                    coc_number: '',
                    vat_number: '',
                    primary_phone: '',
                    primary_email: '',
                    website: ''
                },
                contactPerson: {
                    id: false,
                    first_name: '',
                    insertion: '',
                    last_name: '',
                    phone: '',
                    email: '',
                    initials: '',
                },
                privatePerson: {
                    id: false,
                    initials: '',
                    first_name: '',
                    last_name: '',
                    gender_id: '',
                    insertion: '',
                    telephone: '',
                    email: ''
                },
                remarks: ''
            }
        },
        customers: null,
        customersLoading: false,
        response: null,
        activeCustomer: 'default'
    };

    static reducer(state = RentalCustomerDataReducer.initialState, action) {

        switch (action.type) {

            case RentalCustomerDataAction.SET_FORM_DATA + '_FINISHED':
                if (action.payload && action.payload.customerData) {
                    return {
                        ...state,
                        form: {
                            ...state.form,
                            data: action.payload.customerData,
                            completed: action.payload.completed ?? state.form.completed,
                            activeCustomer: action.payload.customerId ?? state.form.activeCustomer
                        },
                        response: null
                    };
                } else {
                    return {
                        ...state,
                        form: RentalCustomerDataReducer.initialState.form,
                        response: null
                    }
                }

            case RentalCustomerDataAction.UNSET_FORM_COMPLETION + '_FINISHED':

                return {
                    ...state,
                    form: {
                        ...state.form,
                        completed: false
                    }
                };

            case RentalCustomerDataAction.SUBMIT_MUTATION:

                return {
                    ...state
                };

            case RentalCustomerDataAction.SUBMIT_MUTATION + '_FINISHED':

                if (action.payload) {
                    return {
                        ...state,
                        response: action.payload,
                        form: {
                            ...state.form,
                            completed: false
                        },
                    };
                } else {
                    return {
                        ...state
                    }
                }

            case RentalCustomerDataAction.RESET + '_FINISHED':

                return {
                    ...RentalCustomerDataReducer.initialState,
                };


            case RentalCustomerDataAction.SEARCH_CUSTOMER_BY_EMAIL:
                return {
                    ...state,
                    customersLoading: true,
                    customers: null
                };

            case RentalCustomerDataAction.SEARCH_CUSTOMER_BY_EMAIL + '_FINISHED':

                if (action.payload) {
                    return {
                        ...state,
                        customersLoading: false,
                        customers: action.payload.customerData
                    };
                } else {
                    return {
                        ...state
                    }
                }


            default:

                return state;

        }


    }
}
